import React, {FC} from "react";

import styled from "styled-components";

import {SocialLinkPropsType} from "./types";

export const SocialLink: FC<SocialLinkPropsType> = (props) => {
    const {bgColor, alt, icon, socialName, onClick, className,  disabled = false} = props;

    const handleOnClick = (): void => {
      console.log('handleOnClick', disabled)
      // console.log('handleOnClick', onClick)
        if (!disabled){
            onClick && onClick()
            console.log('social link name: ', socialName)
        }
    }

    return (
        <Container bgColor={bgColor} onClick={handleOnClick} className={className}>
            <Icon>
                <img src={icon} alt={alt}/>
            </Icon>
            <Text>Continue with <Span>{socialName}</Span></Text>
        </Container>

    )
}

const Container = styled.div<{ bgColor: string }>`
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  background-color: ${props => props.bgColor || 'red'};
  cursor: pointer;
`
const Icon = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 20px;  
  display: flex;
  justify-content: center;
`
const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`
const Span = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
`
