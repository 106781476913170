import { AppStateType } from "../store";

export type reducerNameType = keyof AppStateType

export const selectCurrentList = (field: string) => (state: AppStateType) => {
    return state.adminReducer.itemsLists[field]
}
export const selectItemsList = (reducerName?: reducerNameType, field?: string) => (state: AppStateType) => {
    if (reducerName && field) {
        const reducer = state[reducerName]
        if (reducer) {
            //@ts-ignore
            return reducer[field]
        } 
    }
    
    return undefined
}
export const selectItemsListCount = (reducerName: reducerNameType, field: string) => (state: AppStateType) => {
    const reducer = state[reducerName]
    if (reducer) {
        //@ts-ignore
        return reducer[field+'Count']
    } 
    return undefined
}

export const selectCurrentCount = (field: string) => (state: AppStateType) => {
    return state.adminReducer.countList[field]
}

export const selectLastQueryParams = (field: string) => (state: AppStateType) => {
    return state.adminReducer.lastQueryParams[field]
}