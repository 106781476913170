//import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import cn from "classnames";
import styles from "./Layout.module.scss";
import React, { FC } from "react";
import { LayoutProps } from "./Layout.props";
import { Outlet, useLocation } from "react-router-dom";
import { Header } from "./Header/Header";

export const Layout: FC<LayoutProps> = ({children, isWithoutFooter = false}) => {
    const location = useLocation();

    return (
        <div className='wrapper'>
            <Header/>
            <main id='main'
                className={cn(location.pathname !== '/admin' ? styles.main : '', 'main')}
            >
                <Outlet />
                {children}
            </main>
            {!isWithoutFooter && <Footer/>}
        </div>
    )
}