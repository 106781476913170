import React, {RefObject, useEffect, useLayoutEffect, useRef, useState} from "react";


export type UseDragType = {
    onMouseDown: (e: React.MouseEvent) => void;
    onMouseUp: () => void;
    onMouseMove: (e: React.MouseEvent) => void;
    scrollContainer: RefObject<HTMLDivElement | HTMLUListElement>;
    dragging: boolean;
}

export default function useDrag(scrollSpeed: number): UseDragType {
    const scrollContainer = useRef<HTMLDivElement | HTMLUListElement>(null);

    const [ofSetLeft, setOfSetLeft] = useState<number>(0)
    const [leftPosition, setLeftPosition] = useState<number>(0);
    const [coordX, setCoordX] = useState<number>(0);
    const [clicked, setClicked] = useState<boolean>(false);
    const [dragging, setDragging] = useState<boolean>(false);


    const onMouseDown = (e: React.MouseEvent) => {
        setClicked(true);
        setCoordX(e.pageX - ofSetLeft)
    }

    const onMouseUp = () => {
        if (scrollContainer.current !== null) {
            setLeftPosition(scrollContainer.current.scrollLeft)
        }
        window.requestAnimationFrame(() => {
            setDragging(false);
            setClicked(false);
        })
    }

    const onMouseMove = (e: React.MouseEvent) => {
            if (clicked) {
                setLeftPosition(prevState => prevState - (e.pageX - ofSetLeft - coordX) * scrollSpeed)
                setDragging(true)
            }
        }

    useLayoutEffect(() => {
        if (scrollContainer.current !== null) {
            scrollContainer.current.scrollLeft = leftPosition
        }
    }, [leftPosition])

    useEffect(() => {
        if (scrollContainer.current !== null) {
            setOfSetLeft(scrollContainer.current.scrollLeft)
        }
    }, [])

    return {
        onMouseDown,
        onMouseUp,
        onMouseMove,
        scrollContainer,
        dragging,
    };
}
