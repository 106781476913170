import React, {FC} from "react";
import styled from "styled-components";
import copyLinkIcon from '../../../assets/images/icons/sheets/akar-icons_link-out.svg'
import twitter from '../../../assets/images/icons/sheets/twitter.svg'
import facebook from '../../../assets/images/icons/sheets/facebook.svg'
import {DataForShareLinkType} from "../../../Components/MapPage/MapPage";

const linksData = [
    {id: 1, title: 'Copy link', icon: copyLinkIcon},
    {id: 2, title: 'Twitter', icon: twitter},
    {id: 3, title: 'Facebook', icon: facebook},
]

type BottomSheetPropsType = {
    copyLink: DataForShareLinkType | null;
    addSuccess: (value: string) => void;
    addError: (value: string) => void;
    zoomValue: number
}
type ButtonTitleShareLinkPropsType = 'Facebook' | 'Twitter' | 'Copy link'

const baseUrl = process.env.REACT_APP_BASE_URL || 'https://befree.com/'

export const BottomSheet: FC<BottomSheetPropsType> = (props) => {
    const {copyLink, addSuccess, addError} = props;
    const handleOnClickShareLink = (buttonTitle: ButtonTitleShareLinkPropsType): void => {
        if (!copyLink) {
            return addError('Error')
        }
        switch (buttonTitle) {
            case "Copy link":
                navigator.clipboard.writeText(
                    baseUrl + 'map?' +
                    `lat=${copyLink.address.latitude}&` +
                    `lng=${copyLink.address.longitude}`
                    // +`&zoom=18&target=${copyLink.objectId}`
                )
                    .then(() => addSuccess('Data successfully copied to clipboard'))
                    .catch((err) => addError('Error' + err))
                break
            case "Facebook":
                window.open(
                    'https://www.facebook.com/sharer.php?u=' +
                    baseUrl + 'map?' +
                    `lat=${copyLink.address.latitude}%26` +
                    `lng=${copyLink.address.longitude}` +
                    `%26zoom=18%26target=${copyLink.objectId}`
                )
                break

            case "Twitter":
                const url = 'https://twitter.com/intent/tweet?text=' +
                            baseUrl + 'map?' +
                            `lat=${copyLink.address.latitude}%26` +
                            `lng=${copyLink.address.longitude}` +
                            `%26zoom=18%26target=${copyLink.objectId}`
                window.open(url)
                break
        }
    }

    return (
        <Container role='presentation' onClick={e => e.stopPropagation()}>
            <Title>
                Share with
            </Title>
            <Wrapper>
                {
                    linksData.map(({id, icon, title}) =>
                        <SocialLinkButton key={`${id}-${title}`}
                                          onClick={() => handleOnClickShareLink(title as ButtonTitleShareLinkPropsType)}>
                            <SocialIcon src={icon} alt={title}/>
                            <SocialTitle>
                                {title}
                            </SocialTitle>
                        </SocialLinkButton>)
                }
            </Wrapper>
        </Container>

    )
}

const Container = styled.div`
  padding: 22px 20px 100px;
  max-width: 375px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;
  color: #333333;
`

const Title = styled.h5`
  margin-bottom: 34px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`

const SocialLinkButton = styled.button`
  padding: 0;
  outline: none;
  border: none;
  background-color: #ffffff;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`

const SocialTitle = styled.h6`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`
