import React, { FC, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AppStateType, useAppDispatch } from "../Redux/store";
import { getStatusApp, setStatusApp } from "../Redux/appReducer";
import { Spinner } from "../newComponent";
import { routers } from "./root.routes";


const Router: FC = () => {
    const dispatch = useAppDispatch();
    const isInitialized = useSelector<AppStateType, boolean>(state => state.appReducer.isInitialized)

    useEffect(() => {
        const token = localStorage.getItem('apikey')
        console.log('TOKEN', token)
        if(token){
             dispatch(getStatusApp())
        } else {
            dispatch(setStatusApp('success'))
        }

    }, [])

    if ( !isInitialized ) {
        return <Spinner/>
    }

    return <RouterProvider router={routers}/>
}

export default Router

