import { Dispatch } from 'redux'
import { brandItemAPI } from '../Api/brandItemApi'
import { brendObjectAPI, GetBrendObjectsByBoundsParamsType } from '../Api/brendObjectApi'
import { AddressType, BrandObjectType } from './brendObjectReducer'
import { CategoryType } from './categoryReducer'
import { BaseThunkType, InferActionsTypes } from './store'
import { UploadDataType, uplodFilesToObject } from './uploadReducer'
import { actions as authActions } from "./authReducer/authReduser"
import { CurrencyType } from "./currencieReducer"
import { AutocompleteOptionsType } from '../Elements/common/Autocomplete/Autocomplete'

export type BrandFieldType = 'id' | 'name' | 'host'
export type BrandType = {
    id?: number
    name: string
    host?: string
}

export type BrendItemFieldType =
    'latitude'
    | 'longitude'
    | 'title'
    | 'markerFileName'
    | 'logoFileName'
    |
    'fullDescription'
    | 'shortDescription'
    | 'shortTitle'
    | 'images'

export type PhoneType = {
    id: number
    number: string
}

export type BrandItemType = {
    id?: number
    dist?: number
    latitude: number
    longitude: number
    title: string
    slug: string
    markerFileName?: string //TODO поменялся объект - удадить
    logoFileName?: string
    category: {
        id: number
        title: string
        slug: string
        markerFileName?: string
    }
    isActive: boolean
    address: AddressType
    fullDescription: string
    shortDescription: string
    shortTitle: string
    ownerId?: number
    owner?: {
        id: number
        email: string
    }
    images?: string[]
    additionalImages?: string[]
    brendObjectId?: number
    phoneNumbers?: PhoneType[]
    brandObjectId?: number
    brand?: { id: number, title: string }
    isAdvertising: boolean
    startAdvertisingAt?: number
    endAdvertisingAt?: number
    averageGrade?: number
    currencies?: CurrencyType[]
    //не полная типизация
    logoFile: { id: number, filename: string }
    imageFiles: { id: number, filename: string }[]
    additionalImageFiles: { id: number, filename: string }[]
    link: string
}

export type BrendItemFullType = BrandItemType & {
    addressData: string
    phoneNumbers: any[]
    isActive: boolean
    categoryTitle: string
    dist?: number
}

export type BrendItemShortType = {
    category: number
    dist: number
    id: number
    latitude: number
    longitude: number
    markerFileName: string
    title: string
    slug: string
}

export type ClusterFieldsType =
    'id'
    | 'latitudeCenter'
    | 'longitudeCenter'
    | 'brandItemsCount'
    | 'brandItems'
    |
    'latMin'
    | 'latMax'
    | 'lngMin'
    | 'lngMax'

export type ClusterType = {
    id?: number
    latitudeCenter: number
    longitudeCenter: number
    brandItemsCount: number
    brandItems: BrendItemShortType[] | null
    latMin?: number
    latMax?: number
    lngMin?: number
    lngMax?: number
    zoom: number
}

export type InfoWindowDataType = {
    id: number//
    address: AddressType //
    latitude: number //
    longitude: number //
    // phoneNumbers: PhoneType[]
    title: string
    slug: string
    // isActive?: boolean
    brandObject: BrandObjectType[] //
    workingSchedules?: any
    category: CategoryType //
    logoFileName: string
}

export type InitialStateBrandItemType = {
    brandItemList: BrandItemType[]
    clustersList: ClusterType[]
    clustersListOriginal: ClusterType[]
    infoWindowData?: InfoWindowDataType
    isInfoWindowDataLoad: boolean

    brandItemListByFilter?: BrandItemType[]
    totalCount?: number
    brandItemData?: BrandItemType
    brandItemsByQuery: AutocompleteOptionsType[]
}

let initialState: InitialStateBrandItemType = {
    brandItemList: [],
    clustersList: [],
    clustersListOriginal: [],
    isInfoWindowDataLoad: false,
    brandItemsByQuery: []
}

type ActionsType = InferActionsTypes<typeof actions>

const brandItemReducer = (state = initialState, action: ActionsType): InitialStateBrandItemType => {
    switch (action.type) {
        case 'BRAND_ITEM/SET_BRAND_ITEMS_BY_QUERY':
            return {
                ...state,
                brandItemsByQuery: action.payload
            }
        case 'BREND_ITEM/SET_BREND_ITEMS_LIST':
            console.log('clustersList brandItemReducer: ', action.clustersList)
            console.log('brendItemList brandItemReducer: ', action.brandItemList)
            return {
                ...state,
                brandItemList: action.brandItemList,
                // clustersList: action.clustersList.map(i=>{
                //     const clustersList = [...state.clustersList].find( j => j.latitudeCenter === i.latitudeCenter && j.longitudeCenter === i.longitudeCenter)
                //     if (clustersList) return clustersList
                //     return({
                //         latitudeCenter: i.latitudeCenter,
                //         longitudeCenter: i.longitudeCenter,
                //         brandItemsCount: i.brandItemsCount,
                //         brandItems: i.brandItems
                //     })
                // }),
                clustersList: action.clustersList,
                clustersListOriginal: action.clustersList,
            }

        case 'BREND_ITEM/SET_INFO_WINDOW_DATA':
            console.log('SET_INFO_WINDOW_DATA', action)
            return {
                ...state,
                infoWindowData: action.data ? action.data : undefined,
                isInfoWindowDataLoad: action.isLoad
            }

        case 'BREND_ITEM/SET_BREND_ITEMS_LIST_BY_FILTER':
            return {
                ...state,
                brandItemListByFilter: action.brandItemListByFilter,
                totalCount: action.totalCount
            }

        case 'BREND_ITEM/SET_BRAND_ITEM_DATA':
            return {
                ...state,
                brandItemData: action.payload
            }

        default:
            return state;
    }
}

export const actions = {
    setBrendItemsList: (
        brandItemList: BrandItemType[],
        clustersList: ClusterType[]
    ) => ({
        type: 'BREND_ITEM/SET_BREND_ITEMS_LIST',
        brandItemList,
        clustersList
    } as const),
    setInfoWindowData: (isLoad: boolean, data?: InfoWindowDataType) => ({
        type: 'BREND_ITEM/SET_INFO_WINDOW_DATA',
        isLoad,
        data
    } as const),
    setBrendItemsByFilter: (brandItemListByFilter: BrandItemType[], totalCount: number) => ({
        type: 'BREND_ITEM/SET_BREND_ITEMS_LIST_BY_FILTER',
        brandItemListByFilter,
        totalCount
    } as const),
    setBrandItemData: (payload: BrandItemType) => ({
        type: 'BREND_ITEM/SET_BRAND_ITEM_DATA',
        payload
    } as const),
    setBrandItemsByQuery: (payload: AutocompleteOptionsType[]) => ({
        type: 'BRAND_ITEM/SET_BRAND_ITEMS_BY_QUERY',
        payload
    } as const)
}

// export const getBrendItemByFilter = (params: any): ThunkType => {
//     return async (dispatch, getState) => {
//         const response = await brandItemAPI.getBrendItemByFilter(params)
//         if (response?.status === 200) {
//             dispatch( actions.setBrendItemsByFilter(response.data.brandItems, response.data.totalCount))
//         }
//     }
// }

export const getBrendItems = (params: GetBrendObjectsByBoundsParamsType): ThunkType => {
    return async (dispatch, getState) => {
        const categoryFilter = getState().categoryReducer.categoryFilter
        const trustedCatygoryes = getState().categoryReducer.trustedCatygoryes
        const filter = categoryFilter && categoryFilter.length !== 0 ? categoryFilter.filter(item => trustedCatygoryes?.includes(item)) : trustedCatygoryes

        const response = await brendObjectAPI.getBrendObjectsByBoundsNew(params, filter && filter.length !== 0 ? filter : categoryFilter)

        if ( response.status === 200 ) {
            dispatch(actions.setBrendItemsList(response.data.markers, response.data.clusters))
        }

    }
}

export const getItemData = (id: number): ThunkType => {
    return async (dispatch, getState) => {
        dispatch(actions.setInfoWindowData(false))

        // const response = await brendObjectAPI.getBrendItemById(id)
        const response = await brandItemAPI.getBrendItemById(id)
        console.log('getItemData response: ', response)
        dispatch(actions.setInfoWindowData(true, response.data))
    }
}

export const toogleBrendItem = (brandItem: BrandItemType, calback: any): ThunkType => {
    return async (dispatch, getState) => {
        const response = await brandItemAPI.updateBrendItem(brandItem, {})
        if ( response.status === 200 ) {
            dispatch(calback())
        } else {
            dispatch(calback())
        }
    }
}

export const updateBrendItem = (brandItem: BrandItemType, queryParams: any, uploadData?: UploadDataType): ThunkType => {
    if ( uploadData ) {
        return async (dispatch, getState) => {
            dispatch(uplodFilesToObject(
                updateBrendItem,
                brandItem,
                queryParams,
                uploadData
            ))
        }
    } else {
        return async (dispatch, getState) => {
            console.log('updateBrendItem', brandItem)
            const response = await brandItemAPI.updateBrendItem(brandItem, queryParams)
            if ( response.status === 200 ) {
                dispatch(actions.setBrendItemsByFilter(response.data.brandItems, response.data.totalCount))
            }
        }
    }

}

export const createBrendItem = (brandItem: BrandItemType, queryParams: any, uploadData?: UploadDataType): ThunkType => {
    if ( uploadData ) {
        return async (dispatch, getState) => {
            dispatch(uplodFilesToObject(
                updateBrendItem,
                brandItem,
                queryParams,
                uploadData
            ))
        }
    } else {
        return async (dispatch, getState) => {
            console.log('updateBrendItem', brandItem)
            const response = await brandItemAPI.createBrendItem(brandItem, queryParams)
            if ( response.status === 200 ) {
                dispatch(actions.setBrendItemsByFilter(response.data.brandItems, response.data.totalCount))
            }
        }
    }
}

export const deleteBrandItem = (brandItemId: number): ThunkType => {
    return async (dispatch: Dispatch, getState) => {
        try {
            await brandItemAPI.deleteBrandItem(brandItemId)
            dispatch(authActions.deleteUserBrandItem(brandItemId))
        } catch (e) {
            //TODO обработать ошибки
        }
    }
}

export const getBrandItemDataById = ({id}: { id: number }): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await brandItemAPI.getBrandItemById(id)

            dispatch(actions.setBrandItemData(response.data))
        } catch (e) {
            //TODO обработать ошибки
        }
    }
}

export const findBrandItemsByQuery = (query: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await brandItemAPI.findBrandItemsByQuery(query)

            dispatch(actions.setBrandItemsByQuery(response.data.result))
        } catch (e) {

        }
    }
}

export default brandItemReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>