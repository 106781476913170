import React, { useEffect } from 'react'
import {  useSelector } from 'react-redux'
import styled from 'styled-components'
import { CategoryType, getActiveCategoryes } from '../../../Redux/categoryReducer'
import { selectCategoryesList } from '../../../Redux/selectors/categorySelectors'
import { QueryParamsType } from '../../../Utils/UniversalTableView/UniversalTableView'
import { useAppDispatch } from "../../../Redux/store";

interface CatalogTreePropsType {
    setDataToFilter: React.Dispatch<React.SetStateAction<QueryParamsType | undefined>>
    dataToFilter: QueryParamsType
    onSelect: (id?: number) => void
    selectedCategory: number | undefined | string[]
    setSelectedCategory: (categoryId: number | undefined) => void;
    isCategoryAll: boolean;
    setIsCategoryAll: (isSelectedAll: boolean) => void;
    className?: string;
}

const CatalogTree: React.FC<CatalogTreePropsType> = (props) => {

    const dispatch = useAppDispatch()

    const categoryesList = useSelector(selectCategoryesList)

    const onCategorySelect = (id: number) => {
        console.log('onCategorySelect', id)
        if ( props.selectedCategory !== id ) {
            props.setSelectedCategory(id)
            props.setDataToFilter({
                ...props.dataToFilter,
                categories: [id],
                perPage: 12,
                page: 1
            })
            props.onSelect(id)
            props.setIsCategoryAll(false)
        } else {
            props.setSelectedCategory(undefined)
            props.setDataToFilter({
                ...props.dataToFilter,
                categories: [],
                perPage: 12,
                page: 1
            })
            props.onSelect()
            props.setIsCategoryAll(true)
        }
    }

    useEffect(() => {
        !categoryesList && dispatch(getActiveCategoryes())
    }, [categoryesList])

    useEffect(() => {
        if ( props.isCategoryAll && props.selectedCategory ) {
            props.setDataToFilter({
                ...props.dataToFilter,
                categories: []
            })
            props.setSelectedCategory(undefined)
        }
    }, [props.isCategoryAll])

    return (
        <CatalogTreeContainer className={props.className}>
            <CategoryRow onClick={() => props.setIsCategoryAll(true)}
                         isActive={props.isCategoryAll}>All categories</CategoryRow>
            {categoryesList && categoryesList.map((i: CategoryType, index: number) => {
                console.log('!!!???!!! ', i.id, '=',props.selectedCategory ,' ', i.id === props.selectedCategory)
                console.log('!!!???!!! ', typeof props.selectedCategory)
                return (
                    <CategoryRow
                        onClick={() => {
                            onCategorySelect(i.id as number)
                        }}
                        isActive={ typeof props.selectedCategory === 'object' ? i.id === +props.selectedCategory[0] : i.id === props.selectedCategory}
                        style={i.id === props.selectedCategory ? {pointerEvents: "none", cursor: 'default'} : {}}
                        key={index}
                    >
                        {i.title}
                    </CategoryRow>
                )
            })}
        </CatalogTreeContainer>
    )
}

export default CatalogTree

const CatalogTreeContainer = styled.ul({
    // backgroundColor: 'yellowgreen',
})

const CategoryRow = styled.li<{ isActive: boolean }>`
  background-color: ${({isActive}) => isActive ? '#E9E9E9' : '#FFFFFF'};
  padding: 10px 10px 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover{
    color: rgb(64, 65, 251);;
  }
`
