import { CurrencyType } from "../Redux/currencieReducer";


export const getCurrencyNamesAsString = (names?: CurrencyType[] ): string => {
    if(!Array.isArray(names)) return ''
    return names.reduce((acc, item, i) => {
        if(!names[i + 1]){
            return acc + item.title + '.'
        }
        return acc + item.title + ', '
    }, '').toLowerCase()
}