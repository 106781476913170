import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {  useSelector } from 'react-redux'
import styled from 'styled-components'
import { Input } from '../../../Elements/common/Input/Input'
import { setSearchIsFocus } from '../../../Redux/searchReducer'
import {
    getParamsMapForTable,
    QueryParamsType,
    setParamsMapForTable
} from '../../../Utils/UniversalTableView/UniversalTableView'
import { Button } from "../../../Elements/common/Button/Button";
import {
    selectCountriesList,
    selectSerchIsFocus,
    selectStatesList
} from '../../../Redux/selectors/searchSelector'
import { Select } from '../../../Elements/common/Select/Select'
import {
    actions,
    getAllCountries,
    getStatesListByCountry
} from '../../../Redux/addressReducer'
import { selectCurrencyList } from '../../../Redux/selectors/currencieSelector'
import { selectCategoryesList } from '../../../Redux/selectors/categorySelectors'
import { getActiveCurrencies } from '../../../Redux/currencieReducer'
import { getActiveCategoryes } from '../../../Redux/categoryReducer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from "../../../Redux/store";
import Autocomplete from '../../../Elements/common/Autocomplete/Autocomplete'
import { GetBrendObjectByFilrterFieldsType } from '../../../Api/brendObjectApi'


type MainPageFilterRefactorPropsType = {
    onSearch: () => void
    dataToFilter?: QueryParamsType
    setDataToFilter: React.Dispatch<React.SetStateAction<QueryParamsType | undefined>>
    setValue: (value: string) => void
    value: string
    selectedCategory?: number | undefined
    setSelectedCategory?: (categoryId: number | undefined) => void;
    className?: any
    isCategoryAll?: boolean;
    setIsCategoryAll?: (isSelectedAll: boolean) => void;
}

export const MainPageFilterRefactor: React.FC<MainPageFilterRefactorPropsType> = (props) => {

    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const searchIsFocus = useSelector(selectSerchIsFocus)
    const countriesList = useSelector(selectCountriesList)
    const currencyList = useSelector(selectCurrencyList)
    const statesList = useSelector(selectStatesList)
    const categoryesList = useSelector(selectCategoryesList)

    useEffect(() => {
        !countriesList && dispatch(getAllCountries({
            perPage: 1000,
            page: 1
        }))
        !currencyList && dispatch(getActiveCurrencies())
        !categoryesList && dispatch(getActiveCategoryes())
        countries && !statesList && dispatch(getStatesListByCountry(countries, {
            perPage: 1000,
            page: 1
        }))
    }, [])

    useEffect(() => {
        props.isCategoryAll && handleChangeValue('categories', [''])
    }, [props.isCategoryAll]);

    useEffect(() => {
        setValue('categories', props.dataToFilter?.categories)
        const params = getParamsMapForTable(location)
        params.categories = props.dataToFilter?.categories

        // params.categories 
        //     && setParamsMapForTable(params, navigate, location, 'MainPageFilterRefactor useEffect props.dataToFilter?.categories')
    }, [props.dataToFilter?.categories]);

    const [isFocus, setIsFocus] = useState<boolean>(false)
    const [isSubmit, setIsSubmit] = useState<boolean>(false)

    const {handleSubmit, reset, setValue, watch} = useForm<QueryParamsType>({
        defaultValues: {
            value: props.dataToFilter && props.dataToFilter.value ? props.dataToFilter.value : undefined,
            countries: props.dataToFilter && props.dataToFilter.countries ? props.dataToFilter.countries : undefined,
            states: props.dataToFilter && props.dataToFilter.states ? props.dataToFilter.states : undefined,
            categories: props.dataToFilter && props.dataToFilter.categories ? props.dataToFilter.categories : undefined,
            currencies: props.dataToFilter && props.dataToFilter.currencies ? props.dataToFilter.currencies : undefined
        }
    })

    const value = watch('value')
    const countries = watch('countries')
    const states = watch('states')
    const categories = watch('categories')
    const currencies = watch('currencies')

    const onSubmit: SubmitHandler<QueryParamsType> = (data: QueryParamsType) => {
        if ( isSubmit ) {
            setIsSubmit(false)

            const params = getParamsMapForTable(location)
            for (const key in data) {
                if ( Object.prototype.hasOwnProperty.call(data, key) ) {
                    const element = data[key];
                    if ( element ) {
                        params[key] = element
                    } else {
                        delete (params[key])
                    }
                }
            }
            setParamsMapForTable(params, navigate, location, 'onSubmit MainPageFilterRefactor')
        } else {
            const params = getParamsMapForTable(location)
            console.log(' NONONO onSubmit MainPageFilterRefactor data', data)
        }
    }

    const onFocus = () => {
        setIsFocus(true);
        //dispatch(setSearchIsFocus(true))
    }

    const onSearch = () => {
        setIsSubmit(true)
        dispatch(setSearchIsFocus(false))
    }

    const handleResetForm = () => {
        reset({states: '', countries: '', categories: '', currencies: '', value: ''})
        setIsSubmit(true)
        props.setSelectedCategory && props.setSelectedCategory(undefined)
        props.setIsCategoryAll && props.setIsCategoryAll(true)
        // props.setValue && props.setValue('')
        // props.dataToFilter && props.setDataToFilter({
        //     ...props.dataToFilter,
        //     categories: undefined, currencies: undefined, countries: undefined, states: undefined, value: undefined
        // })
    }

    const handleChangeValue = (field: string, value: any) => {
        setValue(field, value)
        if (field === 'countries') {
            const countryId = parseInt(value[0])
            countryId ? dispatch(getStatesListByCountry(countryId, {
                perPage: 1000,
                page: 1
            }))
            :
            dispatch(actions.setStatesListByCountry([], 0))
        }
        if (field === 'categories') {
            props.setSelectedCategory && props.setSelectedCategory(value as number)
            if (value[0] === '') {
                props.setIsCategoryAll && props.setIsCategoryAll(true)
            } else {
                props.setIsCategoryAll && props.setIsCategoryAll(false)
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={props.className}>
            <SearchContainer>
                {/* <Input
                    placeholder={!isFocus ? "Name of product, store, product category, brand..." : '...'}
                    value={value}
                    isActive={isFocus}
                    name='value'
                    onBlur={() => setIsFocus(false)}
                    onFocus={onFocus}
                    onChange={(e) => {
                        handleChangeValue('value', e.currentTarget.value)
                    }}
                /> */}
                <Autocomplete
                    placeholder={!isFocus ? "Name of product, store, product category, brand..." : '...'}
                    value={value || ''}
                    isActive={isFocus}
                    onBlur={() => setIsFocus(false)}
                    onSubmit={(value) => {
                        setIsSubmit(true)
                        console.log('!!!!! 123', value)
                        onSubmit(value)
                        onSearch()
                    }}
                    handleChangeValue={(value: string, name: GetBrendObjectByFilrterFieldsType) => setValue(name, value)}
                    setIsFocus={setIsFocus}
                    isFocus={isFocus}
                    // onChange={(e) => {
                    //     handleChangeValue(e, 'value')
                    // }}
                    onChange={(e) => {
                        handleChangeValue('value', e.currentTarget.value)
                    }}
                />
                <SearchContainerButtons>
                    <Button appearance='white'
                            onClick={onSearch}
                            disabled={!value && !countries && !currencies && !categories}
                            isActive={isFocus}>
                        search
                    </Button>
                    <Button 
                        appearance='white'
                        onClick={() => {
                            //searchIsFocus && handleResetForm()
                            dispatch(setSearchIsFocus(!searchIsFocus))
                        }}
                    >
                        {!searchIsFocus ? 'show filters' : 'hide filters'}
                    </Button>
                </SearchContainerButtons>
            </SearchContainer>

            {searchIsFocus &&
                <SelectsBlock>
                    <SelectsWrapper>
                        <SelectLabel>
                            <div>Country</div>
                            <SelectItem
                                placeholder='All'
                                options={countriesList}
                                onChange={(e) => {
                                    handleChangeValue('countries', [e.currentTarget.value])
                                }}
                                value={countries}
                                disabled={countriesList?.length === 0}
                            />
                        </SelectLabel>
                        <SelectLabel>
                            <div>State/Region</div>
                            <SelectItem
                                placeholder='All'
                                options={statesList}
                                onChange={(e) => {
                                    handleChangeValue('states', [e.currentTarget.value])
                                }}
                                value={states as any}
                                disabled={statesList?.length === 0 ? true : !statesList}
                            />
                        </SelectLabel>
                        <SelectLabel>
                            <div>Cryptocurrency</div>
                            <SelectItem
                                placeholder='All'
                                options={currencyList}
                                onChange={(e) => {
                                    handleChangeValue('currencies', [e.currentTarget.value])
                                }}
                                disabled={currencyList?.length === 0}
                                value={currencies}
                            />
                        </SelectLabel>
                        <SelectLabel>
                            <div>Category</div>
                            <SelectItem
                                placeholder='All'
                                options={categoryesList}
                                onChange={(e) => {
                                    handleChangeValue('categories', e.currentTarget.value !== 'All' ? [e.currentTarget.value] : undefined)
                                }}
                                value={categories}/>
                        </SelectLabel>
                    </SelectsWrapper>
                    <ClearButton appearance='white' onClick={handleResetForm}>
                        clear
                    </ClearButton>
                </SelectsBlock>
            }
        </form>
    )
}


const SearchContainer = styled.div`
  max-width: 955px;
  display: grid;
  grid-template-columns: minmax(100px, 645px) auto;
  gap: 10px;
  margin: 0 auto 16px;
  @media(max-width: 660px){
    grid-template-columns: minmax(100px, 645px);
  }
`

const SearchContainerButtons = styled.div`
    display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`

const SelectsBlock = styled.div`
  max-width: 955px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  margin: 0 auto;
  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`

const SelectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(50px, 180px));
  gap: 20px;

  @media (max-width: 660px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }
`

const SelectLabel = styled.div`
  display: inline-block;
  width: 100%;
`

const SelectItem = styled(Select)`
  width: 100%;
`


const ClearButton = styled(Button)`
  height: 40px;
  align-self: flex-end;
`
