import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

type NotContent = {
    title?: string;
    responsive?: number;
}

export const NotContent: FC<PropsWithChildren<NotContent>> = ({children, title, responsive }) => {
    const isSmallWindow = useMediaQuery(`(max-width:${responsive}px)`);

    return <NotContentBlock>
        {title && responsive ? isSmallWindow && <Title>{title}</Title> : <Title>{title}</Title>}
        <span>{children}</span>
    </NotContentBlock>
}

const NotContentBlock = styled.div`
  padding-top: 80px;
  text-align: center;
`

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
`