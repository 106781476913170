import { instance } from './API'
import * as queryString from 'query-string'
import { BrandItemType } from '../Redux/brandItemReducer'

export type GetBrendItemByFilrterParams = {
    categories?: number[]// | number
    value?: string
    currencies?: number[]
    countries?: number[]
    states?: number[]
    limit: number
    offset: number
    sortBy?: {
        [key: string]: 'asc' | 'desc'
    }
}

export const brandItemAPI = {

    updateBrendItem(brandItem: BrandItemType, queryParams: any) {
        let stringified: string = queryString.stringify(queryParams);
        stringified = stringified.replaceAll('categories=', 'categories[]=')

        return instance.patch('/api/brandItem?' + stringified, brandItem)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    createBrendItem(brandItem: BrandItemType, queryParams: any) {
        let stringified: string = queryString.stringify(queryParams);
        stringified = stringified.replaceAll('categories=', 'categories[]=')

        return instance.post('/api/brandItem?' + stringified, brandItem)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    getBrendItemById(id: number) {
        // return instance.get('/api/brandItem/getById/'+ id)
        return instance.get('/api/brand_items/' + id)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    getBrandItemById(id: number) {
        return instance.get<BrandItemType>('/api/brand_items/' + id)
    },

    createUserBrandItem(payload: any) {
        return instance.post<BrandItemType>('/api/brand_items', payload
        )

    },

    updateUserBrandItem(payload: any) {
        return instance.patch<BrandItemType>('/api/brand_items/' + payload.brandItemId, payload
        )

    },

    deleteBrandItem(brandItemId: number) {
        return instance.delete('/api/brand_items/' + brandItemId)
    },

    findBrandItemsByQuery(query: string) {
        return instance.get(`/api/search-by-name/search?target=${query}`)
    }
}