import { Dispatch } from 'redux'
import { tagAPI } from '../Api/tagApi'
import { CategoryType } from './categoryReducer'
import { BaseThunkType, InferActionsTypes } from './store'

export type TagFieldType = 'id' | 'name'

export type TagType = {
    id?: number
    name: string,
    categories?: CategoryType[]
}
export type InitialStateType ={
    tagList?: TagType[]
    totalCount?: number
}

let initialState:InitialStateType = {

}

type ActionsType = InferActionsTypes<typeof actions>

const tagReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'TAG/SET_TAG_LIST':
            return {
                    ...state,
                    tagList: action.tagList,
                    totalCount: action.totalCount
            }
        default:
            return state;
    }
}

export const actions = {
    setTagList: (tagList: TagType[], totalCount: number) => ({type: 'TAG/SET_TAG_LIST', tagList, totalCount} as const),
}

export const getTagList = (queryParams: any): ThunkType => {
    return async (dispatch, getState) => {
        const response = await tagAPI.findTagsByParams(queryParams)
        if (response.status === 200) {
            dispatch( actions.setTagList(response.data.items, response.data.totalCount) )
        }
    }
}

export default tagReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>