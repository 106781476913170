import React from "react";
import {connect} from 'react-redux'
import { AppStateType } from '../../../Redux/store'
import { getActiveCategoryes } from '../../../Redux/categoryReducer'
import { getNearestBrendObjects, getBrendObjectsByBounds } from '../../../Redux/brendObjectReducer'
import { setMyCoordinates } from '../../../Redux/mapReducer'
import MapMenuNew from './MapMenuNew'
import { GetBrendObjectsByBoundsParamsType, GetNearestBrendObjectsParamsType } from '../../../Api/brendObjectApi'

type MapPropsType = ReturnType<typeof mapStateToProps>

type MapDispatchPropsType = {
    getActiveCategoryes: () => void
    getNearestBrendObjects: (params: GetNearestBrendObjectsParamsType) => void
    setMyCoordinates: (coordinates: google.maps.LatLngLiteral) => void
    getBrendObjectsByBounds: (params?: GetBrendObjectsByBoundsParamsType) => void
}

type OwnMapMenuNewPropsType = {
    selectedCategory: number[]
    setSetselectedCategory: React.Dispatch<React.SetStateAction<number[]>>
    setZoomValue: (isNextZoomValue: boolean) => void;
    isHidden: boolean
    changeMapType: (type: google.maps.MapTypeId) => void
    setLocations: () => void
}

export type MapMenuNewPropsType = MapPropsType & MapDispatchPropsType & OwnMapMenuNewPropsType

let mapStateToProps = (state: AppStateType) => {
    return {
        categoryesList: state.categoryReducer.categoryesList,
        myCoordinates: state.mapReducer.myCoordinates,
        brendObjectMenuList: state.brendObjectReducer.brendObjectMenuList
    }
}



export default connect<MapPropsType, MapDispatchPropsType, OwnMapMenuNewPropsType, AppStateType>(mapStateToProps,
    { getActiveCategoryes, getNearestBrendObjects, setMyCoordinates ,getBrendObjectsByBounds }
)
    (MapMenuNew)
