import { Dispatch } from 'redux'
import { BaseThunkType, InferActionsTypes } from './store'

export type ModalOpenDataType = {
    [key: string]: boolean | number
}

export type InitialStateType = {
    maodalOpenData: ModalOpenDataType
}

let initialState:InitialStateType = {
    maodalOpenData: {}
}

type ActionsType = InferActionsTypes<typeof modalActions>

const modalReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'MODAL_REDUCER/SET_MODAL_OPEN':
            const maodalOpenData = {...state.maodalOpenData}
            maodalOpenData[action.key] = action.value
            return {
                ...state,
                maodalOpenData: maodalOpenData
            }
        default:
            return state;
    }
}

export const modalActions = {
    setModalOpen: (key: string, value: boolean | number) => ({type: 'MODAL_REDUCER/SET_MODAL_OPEN', key, value} as const),
}

// export const setIsOpen = (key: string, value: boolean | number ): ThunkType => {
//     return async (dispatch, getState) => {
//         dispatch( modalActions.setModalOpen(key, value) )
//     }
// }

export default modalReducer

type ActionsTypes = InferActionsTypes<typeof modalActions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>