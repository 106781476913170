import { FC, useEffect } from "react";
import styles from './CreateBrandObjectPage.module.scss'
import { FormBrandItem } from "../../FormBrandItem/FormBrandItem";
import { useSelector } from "react-redux";
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import { AuthStateType } from "../../../Redux/authReducer/types";
import { Navigate } from "react-router-dom";
import { actions } from "../../../Redux/authReducer/authReduser";

export const CreateBrandObjectPage: FC = () => {
    const dispatch = useAppDispatch()
    const {status} = useSelector<AppStateType, AuthStateType>(state => state.authReducer)

    useEffect(() => {
        return () => {
            dispatch(actions.setStatus('idle'))
        }
    }, [])

    if ( status === 'success' ) {
        return <Navigate to='/account/my-objects'/>
    }

    return (<div>
        <div className={styles.title}>
            Creating an object
        </div>
        <FormBrandItem/>
    </div>)
}