import { instance, url } from './API'
import * as queryString from 'query-string'
import { QueryParamsInterface } from '../Redux/adminReducer';

export const addressAPI = {

    getStatesList(queryParams: any) {
        let stringified: string = queryString.stringify(queryParams);
        stringified = stringified.replaceAll('categories=', 'categories[]=')

        return instance.get('/api/state?' + stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getAllCountries(queryParams: any) {
        console.log('getAllCountries queryParams', queryParams)
        let stringified: string = queryString.stringify(queryParams);
        stringified = stringified.replaceAll('categories=', 'categories[]=')
        console.log('getAllCountries stringified', stringified)

        return instance.get('/api/countries?' + stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getStatesListByCountry(countryId: number, queryParams: QueryParamsInterface) {
        let stringified: string = queryString.stringify(queryParams);

        return instance.get('/api/countries/'+countryId+'/states?'+stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getCitiesListByCountry(countryId: number, queryParams: QueryParamsInterface) {
        let stringified: string = queryString.stringify(queryParams);
        return instance.get('/api/countries/'+countryId+'/cities?'+stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    addCountry(value: string) {
        return instance.post('/api/countries/add', {
            title: value
        })
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    }, 

    addCity(value: string, countryId: number) {
        return instance.post<{id: number}>('/api/countries/addCity/'+countryId, {
            title: value,
        })
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    addState(value: string, countryId: number, addressId?: number) {
        return instance.post('/api/countries/addState/'+countryId, {
            title: value,
        })
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    addAddress(payload: AddressRequestData){
        return instance.post('api/addresses', payload)
    },

    updateAddress(payload: UpdateAddressRequestData){
        return instance.patch('api/addresses/' + payload.id, payload)
    },

}

export interface AddressRequestData {
    country: number;
    cityName: string;
    street: string;
    building?: string;
    apartment?: string;
    latitude?: number;
    longitude?: number;
    isViewMap?: boolean;
    state?: number | null;
}

export interface UpdateAddressRequestData extends AddressRequestData{
    id: number
}