import React, { FC, useEffect } from "react";
import styles from "../AuthPage.module.scss";
import user from "../../../../assets/images/icons/IconUser.svg";
import cn from "classnames";
import password from "../../../../assets/images/icons/IconPassword.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Color, SocialLink } from "../../../../newComponent";
import iconFacebook from "../../../../assets/images/icons/IconFB.svg";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline
} from "react-google-login";
import iconGoogle from "../../../../assets/images/icons/IconGoogle.svg";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { SocialLoginDataType } from "../AuthPage.props";
import { actions, socialAuth } from "../../../../Redux/authReducer/authReduser";
import { useAppDispatch } from "../../../../Redux/store";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { DefaultAuthFieldsValue } from "../AuthPage";



interface LoginProps {
    register: UseFormRegister<Omit<DefaultAuthFieldsValue, 'email'>>;
    setPageName: (pageName: 'login' | 'register' | 'forgot') => void;
    errors: FieldErrors<Omit<DefaultAuthFieldsValue, 'email'>>;
}

export const Login: FC<LoginProps> = (props) => {
    const {register, setPageName, errors} = props;
    const dispatch = useAppDispatch()

    const responseFacebook = (response: ReactFacebookLoginInfo): void => {
        const {email, name, accessToken} = response;
        if ( accessToken ) {
            const userData: SocialLoginDataType = {
                username: email ? email : response.userID + '@gmail.com',
                password: accessToken,
                type: "Facebook",
                fullName: name,
                token: accessToken
            }
            dispatch(socialAuth(userData))
        }
    }


    const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline): void => {
        if ( 'accessToken' in response ) {
            const {profileObj, accessToken} = response;
            const userData: SocialLoginDataType = {
                username: profileObj.email,
                password: accessToken,
                type: "Google",
                fullName: profileObj.name,
                token: accessToken
            }
            dispatch(socialAuth(userData))
        }
    }

    return <>
        <div className={styles.title}>Login</div>
        <div className={styles['input-wrapper']}>
            <input
                style={{backgroundImage: `url(${user})`}}
                type="email"
                autoComplete='on'
                {...register("username", {required: true})}
                className={cn(styles.input)}/>
            {errors.username && <span className={styles['error-field']}>{errors.username.message}</span>}
        </div>
        <div className={styles['input-wrapper']}>
            <input
                style={{backgroundImage: `url(${password})`}}
                type="password"
                autoComplete='on'
                {...register("password", {required: true, minLength: 8})}
                className={cn(styles.input)}/>
            {errors.password && <span className={styles['error-field']}>{errors.password.message}</span>}
        </div>
        <div className={styles['buttons-block']} >
            <button
                type='submit'
                className={cn(styles.btn, styles['btn-submit'])}>
                Login
            </button>
            <button type='button'
                    className={cn(styles.btn,styles['btn-link'], styles['btn-register'])}
                    onClick={() => setPageName('register')}>
                Register
            </button>
            <button type='button'
                    className={cn(styles.btn, styles['btn-link'] ,styles['btn-forgot'])}
                    onClick={() => setPageName('forgot')}>
                Remind password
            </button>
        </div>


        <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID!}
            autoLoad={false}
            callback={responseFacebook}

            render={({onClick, isDisabled}) => (
                <SocialLink bgColor={Color.blue}
                            className={styles.facebook}
                            alt='facebook'
                            icon={iconFacebook}
                            socialName='Facebook'
                            disabled={isDisabled}
                            onClick={onClick}/>
            )}
        />
        <GoogleLogin
            clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            render={({onClick, disabled}) =>
                <SocialLink bgColor={Color.red}
                            disabled={disabled}
                            alt='google'
                            icon={iconGoogle}
                            socialName='Google'
                            onClick={onClick}/>
            }
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
        />
    </>
}