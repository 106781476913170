import React, { useEffect, useState } from "react";
import { ButtonCloseMenu } from "../../../newComponent";
import styles from './AuthPage.module.scss';
import cn from "classnames";
import password from "../../../assets/images/icons/IconPassword.svg";
import { useForm } from "react-hook-form";
import { actions, loginCheck, register } from "../../../Redux/authReducer/authReduser";
import { useAppDispatch } from "../../../Redux/store";
import { AuthPageProps } from "./AuthPage.props";
import { Login } from "./Login/Login";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import { Register } from "./Register/Register";
import { authAPI } from "../../../Api/authApi";
import { StatusType } from "../../../Redux/appReducer";
import { FeedBack } from "./FeedBack/FeedBack";

export interface DefaultAuthFieldsValue {
    username?: string;
    password?: string;
    email?: string;
}

export interface AuthStatus {
    status: StatusType;
    message: string;
}

export const AuthPage = React.forwardRef<HTMLFormElement, AuthPageProps>((props, ref) => {
    const {pageName, close, setPageName, responseStatus} = props;
    const [status, setStatus] = useState<AuthStatus>({status: 'idle', message: ''})

    const dispatch = useAppDispatch()
    const {
        handleSubmit,
        register: registerForm,
        setError,
        reset,
        clearErrors,
        formState: {errors}
    } = useForm<DefaultAuthFieldsValue>()


    const onSubmit = async (data: any) => {
        switch (pageName) {
            case "login":
                dispatch(loginCheck(data))
                break
            case "register":
                dispatch(register(data))
                break
            case "forgot":
                try {
                    await authAPI.forgotPassword(data)
                    setStatus({status: 'success', message: 'A password recovery link has been sent to your email address'})
                    setPageName('feedBack')

                } catch (e) {
                    setStatus({status: 'failed', message: 'No such email was found'})
                    setPageName('feedBack')
                }
                break
            default:
                return
        }
    }


    const handleCloseAuthPage = () => {
        close();
        reset();
    }

    useEffect(() => {
        reset()
    }, [pageName])


    useEffect(() => {

        if(responseStatus === 'failed'){
            setError('username', {message: 'Check the email'})
            setError('password', {message: 'Check the password'})
            dispatch(actions.setStatus('idle'))
        }

    }, [responseStatus])



    return (
        <form
            ref={ref}
            tabIndex={-1}
            className={cn(styles.form, {
                [styles.success]: status.status === 'success',
                [styles.failed]: status.status === 'failed',
            })}
            onSubmit={handleSubmit(onSubmit)}>
            {pageName !== 'feedBack' &&  <ButtonCloseMenu
                onClick={handleCloseAuthPage}
                className={cn(styles['close-btn'])}/>}
            {pageName === 'login' && <Login
                setPageName={setPageName}
                errors={errors}
                register={registerForm}/>}
            {pageName === 'register' && <Register
                setPageName={setPageName}
                errors={errors}
                register={registerForm}/>}
            {pageName === 'forgot' && <ForgotPassword
                setPageName={setPageName}
                errors={errors}
                register={registerForm}/>}
            {pageName === 'feedBack' && <FeedBack
                close={close}
                status={status}
                setStatus={setStatus}
                />}
        </form>)
})
