import React, {FC, useState} from "react";

import styled from "styled-components";

import {ButtonSelectPropsType} from "./types";

export const ButtonSelect: FC<ButtonSelectPropsType> = (props) => {
    const {title, onClick, disabled = false} = props;
    const [active, setActive] = useState<boolean>(false);

    const handleOnClick = (): void => {
        onClick && onClick()
    };

    const handleOnMouseDown = (): void => setActive(true);

    const handleOnMouseUp = (): void => setActive(false);

    return (
        <Button active={active}
                isSelected={disabled}
                disabled={disabled}
                onClick={handleOnClick}
                onMouseDown={handleOnMouseDown}
                onMouseUp={handleOnMouseUp}>
            {title}
        </Button>
    )

}

const Button = styled.button<{ active: boolean, isSelected: boolean }>`
  //padding: 8px 17px;
  padding: 0 17px;
  height: 40px;
  outline: none;
  border: 1px solid #797DF9;
  background-color: ${({isSelected}) => isSelected ? '#ffffff' : '#797DF9'};
  border-radius: 10px;
  box-shadow: ${({active}) => active ? 'none' : '0 4px 6px rgba(89, 18, 159, 0.25)'};
  transition: all .3s;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({isSelected}) => isSelected ? '#797DF9' : '#ffffff'};
  cursor: ${({isSelected}) => isSelected ? 'inherit' : 'pointer'};


  &:hover {
    background-color: ${({isSelected}) => isSelected ? '#ffffff' : '#0057FF'};
    border: ${({isSelected}) => isSelected ? '1px solid #797DF9' : '1px solid #0057FF'};
  }
`
