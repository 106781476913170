import { FC, PropsWithChildren } from "react";
import { HTagProps } from "./HTag.props";
import styles from './HTag.module.scss'
import cn from "classnames";

export const HTag: FC<PropsWithChildren<HTagProps>> = (props) => {
  const {tag, className, children, ...restProps} = props;

    switch (tag) {
        case "h1":
            return <h1 className={cn(styles.h1, className)} {...restProps}>{children}</h1>;
        case "h2":
            return <h2 className={cn(styles.h2, className)} {...restProps}>{children}</h2>;
        case "h3":
            return <h3 className={cn(styles.h3, className)} {...restProps}>{children}</h3>;
        case "h4":
            return <h4 className={cn(styles.h4, className)} {...restProps}>{children}</h4>;
        case "h5":
            return <h5 className={cn(styles.h5, className)} {...restProps}>{children}</h5>;
        case "h6":
            return <h6 className={cn(styles.h6, className)} {...restProps}>{children}</h6>;
        default:
            return <></>;
    }
}