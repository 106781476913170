import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from './EditBrandObjectPage.module.scss'
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import {
    getBrandItemDataById,
    InitialStateBrandItemType
} from "../../../Redux/brandItemReducer";
import { useSelector } from "react-redux";
import { FormBrandItem } from "../../FormBrandItem/FormBrandItem";

export const EditBrandObjectPage: FC = () => {
    const {brandObjectId} = useParams<{ brandObjectId: string }>()
    const dispatch = useAppDispatch()

    const {brandItemData} = useSelector<AppStateType, InitialStateBrandItemType>(state => state.brandItemReducer)

    useEffect(() => {
        if ( brandObjectId ) {
            dispatch(getBrandItemDataById({id: +brandObjectId}))
        }
    }, [])

    return (<div>
        <div className={styles.title}>Editing a brand object</div>
        <FormBrandItem brandItemData={brandItemData} isEditForm/>
    </div>)
}