import React, { FC, useEffect } from "react";
import styles from './NewsPage.module.scss'
import { useSelector } from "react-redux";
import { AppStateType, useAppDispatch } from "../../Redux/store";
import { getBlogList, InitialStateBlogType } from "../../Redux/blogReducer";
import { SectionTitle } from "../../Elements/SectionTitle/SectionTitle";
import { LargePost } from "../Main/BlogBlock/LargePost/LargePost";
import { SmallPost } from "../Main/BlogBlock/SmallPost/SmallPost";
import { Helmet } from "react-helmet";

  const NewsPage: FC = () => {
    const dispatch = useAppDispatch()
    const {blogList} = useSelector<AppStateType, InitialStateBlogType>(state => state.blogReducer)

    useEffect(() => {
        dispatch(getBlogList())
    }, []);

    const mainNews = blogList && blogList[0]

    const buildPostList = () => {
        return blogList && blogList.slice(1).map((post) => {
            return <SmallPost post={post} key={post.id}/>
        })
    }


    return (<section className={styles.blog}>
        <Helmet>
            <title>News</title>
            <meta name="description" content='List of news'/>
        </Helmet>
        <div className='container'>
            <SectionTitle tag='h6' >
                Blog
            </SectionTitle>
            <div className={styles['blog-content']}>
                {mainNews && <LargePost post={mainNews} className={styles['main-news']}/>}
                {buildPostList()}
                {/*{buildPostList()}*/}
            </div>
        </div>
    </section>)
}

export default NewsPage