import { FC, PropsWithChildren } from "react";
import { ButtonProps } from "./Button.props";
import styles from './Button.module.scss'
import cn from "classnames";

export const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {
    const {children, className, disabled = false, isActive = false, appearance, ...restProps} = props;

    return(
        <button className={cn(styles.button, {
            [styles.white]: appearance === 'white',
            [styles.violette]: appearance === 'violette',
            [styles.transparent]: appearance === 'transparent',
            [styles.disabled]: disabled,
        },
            className)}
                disabled={disabled}
                {...restProps}>
            {children}
        </button>
    )
}