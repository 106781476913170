import React from 'react'
import { HTag } from "../../Elements/common/HTag/HTag";
import aboutImage1 from '../../assets/images/aboutPage/about_1.png';
import aboutImage2 from './../../assets/images/aboutPage/about_2.png';
import aboutImage3 from './../../assets/images/aboutPage/about_3.png';
import cn from "classnames";
import styles from './About.module.scss'
import { Helmet } from "react-helmet";

const About: React.FC = (): JSX.Element => {

    return (
        <div className={cn(styles.about, 'container')}>
            <Helmet>
                <title>About us – beFree.com</title>
                <meta name="description" content='Welcome to beFree.com! We are glad to offer you a new way of spending cryptocurrency. If you have a significant amount in cryptocurrency but don’t know how and where to spend it, our service is what you exactly need.' />
            </Helmet>
            <HTag tag='h1' className={styles.title}>
                About us.
            </HTag>
            <div className={styles.gridContainer}>
                <p className={styles.p_1}>
                    Since the number of cryptocurrencies has increased over the last few
                    years, users need to know how to operate them. If you’ve already
                    earned a significant amount in cryptocurrency but don’t know how and
                    where to spend it, our service is what you exactly need.
                </p>
                <div className={cn(styles.image, styles.img_1)}>
                    <img src={aboutImage1} alt=""/>
                </div>
                <div className={cn(styles.image, styles.img_2)}>
                    <img src={aboutImage2} alt=""/>
                </div>
                <p className={styles.p_2}>
                    Here you will find all the essential information about spending
                    cryptocurrencies. We’ve listed the rating of top-valued currencies and
                    we will be glad to share it with our users. Also, you are introduced
                    to the latest crypto exchange rates. Corresponding to that, you will
                    easily find reliable merchants to spend cryptocurrencies.
                </p>
                <p className={styles.p_3}>
                    We offer hand picked list of the most trustworthy companies accepting
                    cryptocurrencies as well. If you are a happy owner of Bitcoin, you now
                    know the places to pay with it. Just rely on our services and offers
                    to find all the cryptocurrency merchants near you. The latest updates
                    are also available here. Look through our blog and discover everything
                    you want to know about the crypto market.
                </p>
                <div className={cn(styles.image, styles.img_3)}>
                    <img src={aboutImage3} alt=""/>
                </div>

            </div>
        </div>
    )
}

//export default WithLayout(About)
export default About