import React, { FC } from "react";
import { CoinCardProps } from "./CoinCard.props";
import  Card  from "antd/lib/card";
import Avatar from "antd/lib/avatar/avatar";
import { url } from "../../../Api/API";
import styles from './CoinCard.module.scss'
import cn from "classnames";


export const CoinCard: FC<CoinCardProps> = (props): JSX.Element =>{
    const {className, coin: {title, urlSite, symbol, price, logoFileName}} = props;

    const Title = () => {
        return (
            <div className={styles.cardTop}>
                <Avatar src={url + logoFileName}/>
                <div className={styles.shortTitle}>{symbol}</div>
            </div>
        )
    }

    return (
        <Card
            className={cn(className)}
            title={<Title/>}
        >
            <div className={styles.itemTitle}>
                {title}
            </div>
            <div className={styles.itemExchange}>
                1 {symbol} = {price}{' '} USD
            </div>
            <a className={styles.cardLink} href={urlSite}
                          target='_blank'>
                {urlSite && new URL(urlSite).host.replace(/^www\./i, '')}
            </a>
        </Card>
    )
}