import {Action, combineReducers} from "redux"
import  {ThunkAction} from "redux-thunk"
import addressReducer from "./addressReducer"
import adminReducer from "./adminReducer"
import advertisingReducer from "./advertisingReducer"
import authReducer from "./authReducer/authReduser"
import blogReducer from "./blogReducer"
import brandItemReducer from "./brandItemReducer"
import brendObjectReducer from "./brendObjectReducer"

import categoryReducer from "./categoryReducer"
import clusterReducer from "./clusterReducer"
import currencieReducer from "./currencieReducer"
import mapReducer from "./mapReducer"
import messageReducer from "./messageReducer"
import modalReducer from "./modalReducer"
import searchReducer from "./searchReducer"
import tagReducer from "./tagReducer"
import uploadReducer from "./uploadReducer"
import userReducer from "./UserReducer"
import { appReducer } from "./appReducer";
//import { favoriteObjectsReducer } from "./favoriteObjectReducer";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";


let rootReducer = combineReducers({
    appReducer: appReducer,
    //favoriteObjectsReducer,
    addressReducer,
    mapReducer,
    categoryReducer,
    brendObjectReducer,
    messageReducer,
    authReducer,
    currencieReducer,
    brandItemReducer,
    searchReducer,
    blogReducer,
    uploadReducer,
    clusterReducer,
    userReducer,
    tagReducer,
    adminReducer,
    advertisingReducer,
    modalReducer
})

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppStateType = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

///old


export type InferActionsTypes<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never
export type BaseThunkType<A extends Action = Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

//let store = createStore(rootReducer, applyMiddleware(thunkMiddleware))



export default store