import { Empty, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { actions } from '../../Redux/brandItemReducer'
import {
    selectBrendItemListByFilter,
    selectTotalCount
} from '../../Redux/selectors/brandItemSelector'
import {
    MainPageFilterRefactor as MainPageFilterForm
} from '../Main/MainPageFilter/MainPageFilterRefactor'
import CatalogTree from './CatalogTree/CatalogTree'
import { Card } from "../Main/SearchBlock/RowResults/Card/Card";
import { CardLarge } from "../Main/SearchBlock/RowResults/CardLarge/CardLarge";
import { useLocation, useNavigate } from 'react-router-dom'
import {
    getParamsMapForTable,
    QueryParamsType,
    setParamsMapForTable
} from '../../Utils/UniversalTableView/UniversalTableView'
import { getCurrentItemList } from '../../Redux/adminReducer'
import { AppStateType, useAppDispatch } from "../../Redux/store";
import { useWindowSizeOld } from "../../hooks/useWindowWidthOld";
import {Helmet} from "react-helmet";


const linksData = [
    { id: 1, title: 'All', isSelected: true },
    { id: 2, title: 'Recommended', isSelected: false },
    { id: 3, title: 'Rate', isSelected: false },
    { id: 4, title: 'New', isSelected: false },
]

type CatalogPropsType = {}

const CatalogNew: React.FC<CatalogPropsType> = (props) => {
    const isAuth = useSelector<AppStateType, boolean>(state => state.authReducer.isAuth)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [width] = useWindowSizeOld()


    const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined)
    console.log('selectedCategoryselectedCategory', selectedCategory)
    const brandItemsList = useSelector(selectBrendItemListByFilter)
    const totalCount = useSelector(selectTotalCount)
    const [isTile, setIsTile] = useState<boolean>(true)
    const [value, setValue] = useState<string>('')
    const [isCategoryAll, setIsCategoryAll] = useState<boolean>(!getParamsMapForTable(location, undefined, 12).categories)
    // const [dataToFilter, setDataToFilter] = useState<QueryParamsType>({
    //     perPage: 12,
    //     page: 1
    // })
    const [dataToFilter, setDataToFilter] = useState<QueryParamsType>()


    const getPaginationParams = () => {
        const params = getParamsMapForTable(location, undefined, 12)
        return {
            perPage: params.perPage ? params.perPage : 12,
            page: params.page ? params.page : 1
        }
    }

    const [paginationParams, setPaginationParams] = useState<{
        perPage: number,
        page: number
    }>(getPaginationParams())

    const getLinksData = () => {
        const params = getParamsMapForTable(location, undefined, 12)

        if (params.type) {
            return linksData.map(i => {
                if (i.title.toLocaleLowerCase() === params.type) {
                    return { ...i, isSelected: true }
                } else {
                    return { ...i, isSelected: false }
                }
            })
        }
        return linksData
    }

    //Заглушка - если оставим компоненту с подтягиванием All/New/Rate/recommended = то сделать по нормальному
    const [links, setLinks] = useState<any[]>(getLinksData())

    useEffect(() => {
        const params = getParamsMapForTable(location, undefined, 12)
        console.log('useEffect links', params)
        const target = links.find(i => i.isSelected)
        console.log('useEffect target', target)

        if (target) {

            const currentTitle = target.title.toLowerCase()

            console.log('useEffect currentTitle', currentTitle)

            if (params.type !== currentTitle) {
                const newParams = checkType(params, currentTitle)
                newParams.type = currentTitle

                setParamsMapForTable(newParams, navigate, location, 'Links changed')
            }
        }

    }, [links])

    useEffect(() => {
        const params = getParamsMapForTable(location, undefined, 12)
        console.log('init CatalogNew useEffect', params)

        if (params.type) {
            const newParams = checkType(params, params.type)
            setParamsMapForTable(newParams, navigate, location, 'INIT useEffect CATALOG 1')
        } else {
            setParamsMapForTable(params, navigate, location, 'INIT useEffect CATALOG 2')
        }
    }, [])

    useEffect(() => {
        const params = getParamsMapForTable(location, undefined, 12)
        console.log('location.search', { ...params })
        console.log('location.search', !!params['categories'])

        if (params.type) {
            const newLinks = links.map((i) => {
                if (i.title.toLowerCase() === params.type) {
                    return {
                        ...i,
                        isSelected: true
                    }
                } else {
                    return {
                        ...i,
                        isSelected: false
                    }
                }
            })
            setLinks(newLinks)
        }


        if (params['categories']) {
            setSelectedCategory(parseInt(params['categories']))
            console.log('categories', params['categories'])
            console.log('parseInt', parseInt(params['categories']))
            if (!isNaN(parseInt(params['categories']))) {
                setIsCategoryAll(false)
            }
            params['categories'][0] === '' && setIsCategoryAll(true)
        } else {
            setIsCategoryAll(true)
        }

        console.log('dispatch!!!location.search', params)
        const newParams = params.type ? checkType(params, params.type) : params

        dispatch(getCurrentItemList(
            'brand_items',
            newParams,
            actions.setBrendItemsByFilter
        ))

        setDataToFilter(params)
        setPaginationParams({
            page: parseInt(params.page.toString()),
            perPage: parseInt(params.perPage.toString())
        })

    }, [location.search]);

    useEffect(() => {
        console.log('isCategoryAll useEffect', isCategoryAll)
        if (isCategoryAll) {
            const params = getParamsMapForTable(location, undefined, 12)
            console.log('isCategoryAll params', params)
            delete (params['categories[]'])
            delete (params['categories'])
            params.page = 1
            if (params.type) {
                const newParams = checkType(params, params.type)
                setParamsMapForTable(newParams, navigate, location, 'isCategoryAll')
            } else {
                setParamsMapForTable(params, navigate, location, 'isCategoryAll')
            }

            // setParamsMapForTable(params, navigate, location, 'isCategoryAll')
        }
    }, [isCategoryAll]);


    const buildLinksForTest = () => {
        return links.map(({ isSelected, title, id }) =>
            <span key={id + '-link'}
                className={isSelected ? 'active' : undefined}
                onClick={
                    () => {
                        setLinks(prevState => prevState.map((item) => item.id === id
                            ?
                            { ...item, isSelected: true }
                            :
                            { ...item, isSelected: false }))
                    }
                }
            >{title}</span>)
    }

    const onSearch = (categoryId?: number) => {
        console.log('onSearch', dataToFilter)
        dataToFilter && console.log('onSearch categories', dataToFilter.categories ? dataToFilter.categories : [categoryId])
    }

    const onSelect = (id?: number) => {
        console.log('onSelect', id)
        const params = getParamsMapForTable(location, undefined, paginationParams.perPage ? paginationParams.perPage : 12)
        console.log('params', params)

        params.page = 1

        if(id) {
            setIsCategoryAll(false)
            params.categories = [id]
        } else {
            setIsCategoryAll(true)
            delete(params.categories)
            params.page = 1 
        }      
        
        setParamsMapForTable(params, navigate, location, 'onSelect')
        // setDataToFilter(params)
    }

    const onPaginationChange = (page: number, perPage: number) => {
        const params = getParamsMapForTable(location, undefined, perPage)
        params.page = page
        params.perPage = perPage
        setParamsMapForTable(params, navigate, location, 'onPaginationChange')

        if (paginationParams.perPage !== perPage) {
            setPaginationParams({
                page: 1,
                perPage: perPage
            })
        } else {
            setPaginationParams({
                page: page,
                perPage: perPage
            })
        }
        if(width > 660){
            window.scrollTo({top: 150, behavior: "smooth"})
        } else {
            window.scrollTo({top: 195, behavior: "smooth"})
        }

    }

    useEffect(() => {
        const noFollow = Array.from(document.querySelectorAll("[rel='nofollow']"))
        noFollow.forEach( item => item.removeAttribute('rel'))
    })

    if (!dataToFilter) return <>WAIT</>

    console.log('Pagination paginationParams', paginationParams)
    return (
            <CatalogContainer className='container'>
                <Helmet>
                    <title>List of places that accept cryptocurrency | beFree.com</title>
                    <meta name="description" content="Never worry about where you can spend your cryptocurrency! With this list of companies that accept cryptocurrency payment, you can pay with Bitcoin, Ethereum, and more." />
                </Helmet>
                    <CatalogMenu
                        isCategoryAll={isCategoryAll}
                        setIsCategoryAll={setIsCategoryAll}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setDataToFilter={setDataToFilter}
                        dataToFilter={dataToFilter}
                        onSelect={onSelect}
                    />


                    <ContentContainer>
                        <MainPageFilterForm
                            isCategoryAll={isCategoryAll}
                            setIsCategoryAll={setIsCategoryAll}
                            onSearch={onSearch}
                            dataToFilter={dataToFilter}
                            setDataToFilter={setDataToFilter}
                            value={value}
                            setValue={setValue}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />
                        <div>
                            <LinksWrapper className='LinksWrapper' >
                                <LinksContainer>
                                    {buildLinksForTest()}
                                </LinksContainer>
                                {/* <IconButton isActive={!isTile}
                                    onClick={() => {
                                        setIsTile(false);
                                        setDataToFilter({ ...dataToFilter, perPage: 6 })
                                    }}>
                            <ListIcon />
                        </IconButton>
                        <IconButton isActive={isTile}
                                    onClick={() => {
                                        setIsTile(true);
                                        setDataToFilter({ ...dataToFilter, perPage: 12 })
                                    }}>
                            <TileIcon />
                        </IconButton> */}
                            </LinksWrapper>


                                <CardsContainer className='CardsContainer' isTile={isTile}>
                                    {isTile && brandItemsList && brandItemsList
                                        // .slice(0, 4)
                                        .map((brandObject, index) => {
                                        return <Card brandObject={brandObject} key={index + '-card'} isAuth={isAuth} />
                                    })}
                                    {!isTile && brandItemsList && brandItemsList.map((brandObject, index) => {
                                        return <CardLarge brandObject={brandObject} key={index} />
                                    })}
                                </CardsContainer>

                                {brandItemsList?.length === 0 && <Empty 
                                    imageStyle={{margin: 'auto', width: '100%'}}
                                />}

                        </div>
                        
                        <Pagination
                            onChange={onPaginationChange}
                            defaultCurrent={1}
                            current={paginationParams.page}
                            pageSize={paginationParams.perPage}
                            pageSizeOptions={isTile ? [12, 24, 48] : [6, 12, 18]}
                            total={totalCount}
                            style={{textAlign: "center", marginBottom: 30}}
                            responsive
                        />

                    </ContentContainer>

            </CatalogContainer>


    )
}

export default CatalogNew

const CatalogContainer = styled.div`
  padding: 40px 30px;
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 30px;
  @media (max-width: 810px) {
    grid-template-columns: 1fr;
    padding-right: 20px;
    padding-left: 20px;
  }

`

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 20px;
`

const CatalogMenu = styled(CatalogTree)`
  @media (max-width: 810px) {
    display: none;
  }
`

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-columns: 1fr auto auto;
  gap: 10px;
  margin-bottom: 10px;
`

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);


  span {
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    justify-items: center;
    border-bottom: 3px solid #c4c4c4;
    cursor: pointer;

    &:hover {
      border-color: #4041fb;
    }
  }

  .active {
    border-color: #4041fb;
  }
`

const CardsContainer = styled.div<{ isTile: boolean }>`
  padding: 30px 0 30px;
  display: grid;
  grid-template-columns: ${props => props.isTile ? 'repeat(4, 1fr)' : '1fr'};
  gap: 15px;

  @media (max-width: 1400px) {
    grid-template-columns: ${props => props.isTile ? 'repeat(3, 1fr)' : '1fr'};
  }

  @media (max-width: 1100px) {
    grid-template-columns: ${props => props.isTile ? 'repeat(2, 1fr)' : '1fr'};
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 810px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`


const IconButton = styled.button<{ isActive: boolean }>`
  width: 30px;
  height: 30px;
  outline: none;
  padding: 0;
  border: none;
  background: white;
  cursor: pointer;

  svg {
    fill: ${props => props.isActive && "#4041FB"};
  }

  &:hover {
    svg {
      fill: #4041FB;
    }
  }
`


const checkType = (params: QueryParamsType, type: string) => {
    switch (type) {
        case 'all':
            delete (params.isAdvertising)
            delete (params['sortBy[created]'])
            delete (params['sortBy[popularity]'])
            delete (params['isRandom'])
            break

        case 'new':
            params.sortBy = { created: 'desc' }
            delete (params.isAdvertising)
            delete (params['sortBy[popularity]'])
            break

        case 'recommended':
            params.isAdvertising = true
            params.isRandom = true
            delete (params['sortBy[created]'])
            delete (params['sortBy[popularity]'])
            break

        case 'rate':
            params.sortBy = { popularity: 'desc' }
            delete (params.isAdvertising)
            delete (params['sortBy[created]'])
            break

        default:
            break;
    }
    return params
}