import { instance } from './API'
import { SocialLoginDataType } from "../layout/Header/AuthPage/AuthPage.props";

export type LoginDataType = {
    username: string
    password: string
    plainPassword?: string
    email?: string
}

export interface IRecoveryPassword {
    password: string;
    confirmationToken: string
}

export interface IChangeUserData {
    id: number;
    username?: string;
    phone?: string;
    photoFile?: string | number | null
}

export const authAPI = {
    loginCheck(loginData: LoginDataType) {
        return instance.post('/api/login_check', loginData)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    register(loginData: LoginDataType) {
        loginData.plainPassword = loginData.password
        loginData.email = loginData.username
        return instance.post('/api/registration', loginData)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    forgotPassword(payload: {username: string}) {
        return instance.post('/api/reset_password', payload)
    },

    changeUserData(payload: IChangeUserData) {
        const {id, ...data} = payload;
        return instance.patch('/api/users/' + id, data)
    },

    recoveryPassword(payload: IRecoveryPassword) {
        return instance.post('/api/recovery_password', payload)
    },

    changePassword(payload: Omit<IRecoveryPassword, 'confirmationToken'>) {
        return instance.post('/api/users/change_password', payload)
    },

    checkAuth() {
        return instance.get('/api/check_auth',{
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('apikey'),
                'Content-Type': 'application/json',
                'Accept': "application/json"
            }
        })
        .then(response => {
            // console.log('checkAuth', response)
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    socialAuth(loginData: SocialLoginDataType) {
        return instance.post('/api/social_auth', loginData)
        .then(response => {
            console.log('socialAuth', response)
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    }
}
