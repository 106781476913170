import React, { FC, forwardRef } from "react";

import {Link} from "react-router-dom";
import logo from './../../assets/images/icons/Logo.svg'
import logoSmall from './../../assets/images/icons/logo-small.svg'
import {LogoPropsType} from "./types";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";


export const Logo: FC<LogoPropsType> = (props) => {
    const {navigateTo, className, size = 'default', ...restProps} = props;
    const isSmallWindow = useMediaQuery('(max-width:375px)');

    return(
        <LogoLink to={navigateTo}
              className={className}
              {...restProps}
        >
            <Icon src={ isSmallWindow && size === 'responsive' ? logoSmall : logo}
                  alt="logo"/>
        </LogoLink>
    )
}

const LogoLink = styled(Link)`
  display: inline-block;
`

const Icon = styled.img`
  max-width: 129px;
  max-height: 32px;
`
