import React, { FC, useEffect, useState } from "react";
import styles from './FormBrandItem.module.scss'
import { Controller, useForm } from "react-hook-form";
import {
    Chip,
    InputAdornment,
    Switch,
    TextField
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import { AppStateType, useAppDispatch } from "../../Redux/store";
import {
    CategoryInitialStateType,
    getAllCategories
} from "../../Redux/categoryReducer";
import {
    CurrencyInitialStateType,
    getAllActiveCurrencies
} from "../../Redux/currencieReducer";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    AddressStateType,
    getAllCountries,
    getStatesListByCountry
} from "../../Redux/addressReducer";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { AuthStateType } from "../../Redux/authReducer/types";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import {
    createUserBrandItem
} from "../../Redux/authReducer/thunks/createUserBrandItem";
import { BrandItemType } from "../../Redux/brandItemReducer";
import { url } from "../../Api/API";
import {
    updateUserBrandItem
} from "../../Redux/authReducer/thunks/updateUserBrandItem";
import { addError } from "../../Redux/messageReducer";



const createFileList = (data: { id: number, filename: string }[]): { uid: string, name: string, url: string }[] => {
    const newFileList: { uid: string, name: string, url: string }[] = []
    if ( data.length === 0 ) {
        return newFileList
    }

    for (let i = 0; i < data.length; i++) {
        let {id, filename} = data[i]
        newFileList.push({uid: `${id}`, name: filename, url: url + filename})
    }
    return newFileList
}

interface FormBrandItemProps {
    brandItemData?: BrandItemType;
    // onSubmit?: (data: any) => void;
    isEditForm?: boolean;
}

export const FormBrandItem: FC<FormBrandItemProps> = (props) => {
    const dispatch = useAppDispatch()

    const {
        handleSubmit,
        setValue,
        watch,
        reset,
        control,
        formState: {errors}
    } = useForm({
        defaultValues: {
            title: '',
            link: '',
            phone: '',
            latitude: '',
            longitude: '',
            category: '',
            shortDescription: '',
            fullDescription: '',
            address: {
                state: '',
                country: '',
                city: '',
                street: '',
                building: '',
                apartment: '',
            }
        }
    })

    const {brandItemData, isEditForm = false} = props
    const {userData} = useSelector<AppStateType, AuthStateType>(state => state.authReducer)
    const {allCategoryesList} = useSelector<AppStateType, CategoryInitialStateType>(state => state.categoryReducer)
    const {currencyList} = useSelector<AppStateType, CurrencyInitialStateType>(state => state.currencieReducer)
    const {
        stateList,
        countryList,
    } = useSelector<AppStateType, AddressStateType>(state => state.addressReducer)
    const [country, phone] = watch(['address.country', "phone"])

    const [isOwner, setIsOwner] = useState<boolean | undefined>(false)
    // currencies menu
    const [isHidden, setIsHidden] = useState<boolean>(true)
    // upload data
    const [fileListLogo, setFileListLogo] = useState<UploadFile[]>([]);
    const [fileListMainImg, setFileListMainImg] = useState<UploadFile[]>([]);
    const [fileListAdvertising, setFileListAdvertising] = useState<UploadFile[]>([]);
    // array currency id
    const [currenciesId, setCurrenciesId] = useState<number[]>([]);

    const onSubmit = (data: any) => {
        if ( fileListLogo.length === 0 ) {
            return  dispatch(addError('Logo File is required'))
        }
        if(!isEditForm){
            if ( isOwner ) {
                const formData = {
                    ...data,
                    currencies: currenciesId,
                    phone: `+${phone}`,
                    fileListLogo,
                    fileListMainImg,
                    fileListAdvertising,
                    owner: userData?.id
                }
                return  dispatch(createUserBrandItem(formData))
            }
            const formData = {
                ...data,
                currencies: currenciesId,
                phone: `+${phone}`,
                fileListLogo,
                fileListMainImg,
                fileListAdvertising
            }

            return  dispatch(createUserBrandItem(formData))
        } else {
            const formData = {
                ...data,
                addressId: brandItemData?.address.id,
                currencies: currenciesId,
                phone: `+${phone}`,
                phoneId: brandItemData?.phoneNumbers?.[0].id,
                fileListLogo,
                fileListMainImg,
                fileListAdvertising,
                brandItemId: brandItemData?.id,
            }
            dispatch(updateUserBrandItem(formData))
        }

    }

    const resetForm = () => {
        reset()
        setCurrenciesId([])
        setFileListLogo([])
        setFileListMainImg([])
        setFileListAdvertising([])
    }
//handle upload data
    const onChangeLogo: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileListLogo(newFileList);
    };

    const onChangeMainImg: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileListMainImg(newFileList);
    };

    const onChangeAdvertising: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileListAdvertising(newFileList);
    };

    //handle onClick currency item (add or remove currency id)
    const handleOnClickCurrency = (itemId: number) => {
        if ( !currenciesId.some(el => el === itemId) ) {
            setCurrenciesId(
                prevState => [...prevState, itemId]
            )
        } else {
            setCurrenciesId(prevState => prevState.filter(id => itemId !== id))
        }
    }

    // rendering elements for select
    const buildCategoryList = () => {
        return allCategoryesList?.map(({id, title}) => {
            return <MenuItem value={id} key={id}>{title}</MenuItem>
        })
    }

    const buildCurrenciesList = () => {
        return currencyList?.map(({id, title}) => {
            return <Chip key={id}
                         label={title}
                         variant={currenciesId.some(el => el === id) ? 'filled' : 'outlined'}
                         onClick={() => handleOnClickCurrency(id!)}
            />
        })
    }

    const buildCountriesList = () => {
        return countryList?.map(({id, title}) => {
            return <MenuItem
                value={id}
                key={id}>{title}
            </MenuItem>
        })
    }

    const buildStatesList = () => {
        return stateList?.map(({id, title}) => {
            return <MenuItem value={id} key={id}>{title}</MenuItem>
        })
    }

    useEffect(() => {
        dispatch(getAllCountries({}))
        dispatch(getAllCategories())
        dispatch(getAllActiveCurrencies())
    }, [])

    useEffect(() => {
        if ( country ) {
            dispatch(getStatesListByCountry(parseInt(country), {page: 1, perPage: 1000}));
        }
    }, [country])

    useEffect(() => {
        if(stateList?.length === 0){
            setValue('address.state', '')
        }
    }, [stateList])


    useEffect(() => {
        if ( brandItemData ) {
            const {
                logoFile,
                imageFiles,
                additionalImageFiles,
                title,
                link,
                address,
                longitude,
                category,
                latitude,
                phoneNumbers,
                shortDescription,
                fullDescription,
                currencies,
            } = brandItemData
            setFileListLogo(createFileList([logoFile]))
            setFileListMainImg(createFileList(imageFiles))
            setFileListAdvertising(createFileList(additionalImageFiles))
            currencies && setCurrenciesId(currencies.map(({id}) => id))

            reset({
                title,
                link: link.replace(/^https?:\/\//i, ""),
                longitude: `${longitude}`,
                latitude: `${latitude}`,
                category: `${category.id}`,
                shortDescription,
                fullDescription,
                phone: phoneNumbers?.[0]?.number,
                address: {
                    country: `${address.country.id}`,
                    state: address.state ? `${address.state.id}` : '',
                    city: address.cityName || '',
                    street: address.street || '',
                    building: address.building || '',
                    apartment: address.apartment || '',
                }
            })
        }
    }, [brandItemData])

    // const beforeUpload = (file: RcFile ) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //         setFileListLogo(prev => [...prev, {url: reader.result}]);
    //     }
    // }

    return (
        <form className={styles['form-wrapper']}
              onSubmit={handleSubmit(onSubmit)}
        >
            <fieldset className={styles.fieldset}>
                <legend>Info</legend>
                <Controller
                    name="title"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        required
                        label="Brand Name"
                        {...field} />}
                />

                <Controller
                    name="link"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        InputProps={{
                            startAdornment: <InputAdornment
                                position="start">https://</InputAdornment>,
                        }}
                        label="Site"
                        {...field} />}
                />

                <PhoneInput
                    inputProps={{
                        required: true,
                        autoFocus: true,
                        style: {maxWidth: 300, width: '100%'}
                    }}
                    country={'us'}
                    prefix='+'
                    value={phone}
                    onChange={phone => setValue('phone', phone)}
                />
                <ImgCrop grid quality={0.5} >
                    <Upload
                        listType="picture-card"
                        fileList={fileListLogo}
                        onChange={onChangeLogo}
                        maxCount={1}
                        beforeUpload={() => false}
                        // onPreview={onPreview}
                        // onRemove={handleRemoveFile}
                    >
                       + Upload
                    </Upload>
                </ImgCrop>
            </fieldset>
            <fieldset className={styles.fieldset}>
                <legend>Address</legend>
                <Controller
                    name="address.country"
                    control={control}
                    render={({field}) => <FormControl sx={{minWidth: 200}} size='small'>
                        <InputLabel id="country">Select Country</InputLabel>
                        <Select
                            labelId="country"
                            label="Select Country"
                            required
                            MenuProps={{style: {maxHeight: 300}}}
                            {...field}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {buildCountriesList()}
                        </Select>
                    </FormControl>}
                />
                <Controller
                    name="address.state"
                    control={control}
                    render={({field}) => <FormControl sx={{minWidth: 200}} size='small'>
                        <InputLabel id="state">Select State</InputLabel>
                        <Select
                            labelId="state"
                            required

                            disabled={!stateList || stateList.length === 0}
                            label="Select State"
                            MenuProps={{style: {maxHeight: 300}}}
                            {...field}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {buildStatesList()}
                        </Select>
                    </FormControl>}
                />
                <Controller
                    name="address.city"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        required
                        label="City"
                        sx={{width: 100}}
                        {...field} />}
                />
                <Controller
                    name="address.street"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        label="Street"
                        required
                        sx={{width: 100}}
                        {...field} />}
                />
                <Controller
                    name="address.building"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        label="Building"
                        sx={{width: 100}}
                        {...field} />}
                />
                <Controller
                    name="address.apartment"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        label="Apartment"
                        sx={{width: 150}}
                        {...field} />}
                />
                <Controller
                    name="latitude"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        sx={{width: 100}}
                        required
                        inputProps={{inputMode: 'numeric', pattern: '[0-9,.-]*'}}
                        label="Latitude"
                        {...field} />}
                />
                <Controller
                    name="longitude"
                    control={control}
                    render={({field}) => <TextField
                        size='small'
                        sx={{width: 120}}
                        required
                        inputProps={{inputMode: 'numeric', pattern: '[0-9,.-]*'}}
                        label="Latitude"
                        {...field} />}
                />
            </fieldset>
            <fieldset className={styles.fieldset}>
                <legend>Category</legend>
                <Controller
                    name="category"
                    control={control}
                    render={({field}) => <FormControl sx={{minWidth: 200}} size='small'>
                        <InputLabel id="category">SelectCategory</InputLabel>
                        <Select
                            required
                            labelId="category"
                            label="Select Category"
                            MenuProps={{style: {maxHeight: 300}}}
                            {...field}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {buildCategoryList()}
                        </Select>
                    </FormControl>}
                />
            </fieldset>
            <fieldset className={styles.fieldset}
                      style={{
                          overflow: 'hidden',
                          height: isHidden ? 80 : '100%',
                          transition: 'all 0.3s'
                      }}>
                <legend>
                    Currencies
                    <span onClick={() => setIsHidden(!isHidden)}
                          className={styles['collapse-btn']}>
                        {isHidden ? ' show all >' : ' collapse'}
                    </span>
                </legend>
                {buildCurrenciesList()}
            </fieldset>
            <fieldset className={styles.fieldset}>
                <legend>Description</legend>
                <Controller
                    name="shortDescription"
                    control={control}
                    render={({field}) => <TextField
                        multiline
                        fullWidth
                        rows={2}
                        label="Short Description"
                        {...field}
                    />}
                />
                <Controller
                    name="fullDescription"
                    control={control}
                    render={({field}) => <TextField
                        multiline
                        fullWidth
                        rows={5}
                        label="Full Description"
                        {...field}
                    />}
                />
            </fieldset>
            <fieldset className={styles.fieldset}>
                <legend>Main Images</legend>
                <ImgCrop grid>
                    <Upload
                        listType="picture-card"
                        fileList={fileListMainImg}
                        onChange={onChangeMainImg}
                        maxCount={5}
                        beforeUpload={() => {
                            return false;
                        }}
                        // onPreview={onPreview}
                        // onRemove={handleRemoveFile}
                    >
                        {fileListMainImg.length < 5 && '+ Upload'}
                    </Upload>
                </ImgCrop>
            </fieldset>
            <fieldset className={styles.fieldset}>
                <legend>Advertising Images</legend>
                <ImgCrop grid>
                    <Upload
                        listType="picture-card"
                        fileList={fileListAdvertising}
                        onChange={onChangeAdvertising}
                        maxCount={5}
                        beforeUpload={() => {
                            return false;
                        }}
                        // onPreview={onPreview}
                        // onRemove={handleRemoveFile}
                    >
                        {fileListAdvertising.length < 5 && '+ Upload'}
                    </Upload>
                </ImgCrop>
            </fieldset>
            {!brandItemData && <>
                <div>
                    I confirm that I am the owner of this object
                </div>
                <Switch value={isOwner}
                        onChange={(e, checked) => setIsOwner(checked)}
                        size="small"/>
            </>
            }

            <div className={styles.buttons}>
                <Button type='submit'
                        variant='contained'
                        fullWidth> Save </Button>
                <Button type='button'
                        variant='outlined'
                        onClick={resetForm}
                        fullWidth> Clear </Button>
            </div>
        </form>
    )
}