import { Dispatch } from 'redux'
import { addressAPI } from '../Api/addressApi'
import { QueryParamsInterface } from './adminReducer'
import { BaseThunkType, InferActionsTypes } from './store'
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CountryType = {
    title: string
    id: number
    code?: string
}

export type AddressStateType ={
    countryList?: CountryType[]
    totalCountCountry?: number

    targetCountryId?: number
    citiesListByCountry?: CityType[]

    stateList?: any[]
    totalCountSatae?: number

    cityList?: CityType[]
    totalCountCity?: number
}

export type CityFieldsType = 'title' | 'id' | 'country'
export type CityType = {
    title: string
    id: number
    country?: CountryType
}

export type StateType = {
    title: string,
    id: number
    country?: CountryType
    isActive: boolean
}

let initialState:AddressStateType = {

}

type ActionsType = InferActionsTypes<typeof actions>

const slice = createSlice({
    name: 'address',
    initialState: initialState,
    reducers:{
        setCountryList: (state, {payload: {totalCount, countryList}}: PayloadAction<{countryList: CountryType[], totalCount: number}>) => {
            state.countryList = countryList;
            state.totalCountCountry = totalCount;
        },
        setCitiesListByCountry: (state, {payload: {cityList}}: PayloadAction<{cityList: any[]}>) => {
            state.cityList = cityList;
        },
        setCitiesList: (state, {payload: {cityList, totalCount}}: PayloadAction<{cityList: any[], totalCount: number}>) => {
            state.cityList = cityList;
            state.totalCountCity = totalCount;
        },
        setStatesListByCountry: (state, {payload: {stateList, totalCountState}}: PayloadAction<{stateList: any[], totalCountState: number}>) => {
            state.stateList = stateList;
            state.totalCountSatae = totalCountState;
        }
    }
})

const addressReducer = (state = initialState, action: ActionsType): AddressStateType => {
    switch (action.type) {
        case 'ADDRESS/SET_COUNTRY_LIST':
            return {
                    ...state,
                    countryList: action.countryList,
                    totalCountCountry: action.totalCount
            }

        case 'ADDRESS/SET_SITIES_LIST_BY_COUNTRY':
            console.log('SET_SITIES_LIST_BY_COUNTRY', action.cityList)
            return {
                ...state,
                citiesListByCountry: action.cityList
            }

        case 'ADDRESS/SET_SITIES_LIST':
            return {
                ...state,
                cityList: action.cityList,
                totalCountCity: action.totalCount
            }

        case 'ADDRESS/SET_STATES_LIST_BY_COUNTRY':
            console.log('SET_STATES_LIST_BY_COUNTRY', action.stateList)
            return{
                ...state,
                stateList: action.stateList,
                totalCountSatae: action.totalCountSatae
            }

        default:
            return state;
    }
}

export const actions = {
    setCountryList: (countryList: CountryType[], totalCount: number) => ({type: 'ADDRESS/SET_COUNTRY_LIST', countryList, totalCount} as const),

    setCitiesListByCountry: (cityList: any[]) => ({type: 'ADDRESS/SET_SITIES_LIST_BY_COUNTRY', cityList} as const),
    setCitiesList: (cityList: any[], totalCount: number) => ({type: 'ADDRESS/SET_SITIES_LIST', cityList, totalCount} as const),

    setStatesListByCountry: (stateList: any[], totalCountSatae: number) => ({type: 'ADDRESS/SET_STATES_LIST_BY_COUNTRY', stateList, totalCountSatae} as const),
}

export const addCity = (value: string, countryId: number): ThunkType => {
    return async (dispatch) => {
        const response = await addressAPI.addCity(value, countryId)
        if (response.status === 200) {
            dispatch( actions.setCitiesListByCountry(response.data.cities) )
        } else {
            alert(response.data.error)
        }
    }
}

export const addState = (value: string, countryId: number): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.addState(value, countryId)
        if (response.status === 200) {
            dispatch( actions.setStatesListByCountry(response.data.items, response.data.totalCount) )
        } else {
            alert(response.data.error)
        }
    }
}

export const addCountry = (value: string): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.addCountry(value)
        if (response.status === 200) {
            dispatch( actions.setCountryList(response.data.items, response.data.totalCount ) )
        } else {
            alert(response.data.error)
        }
    }
}

export const getAllCountries = (params: any): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.getAllCountries(params)
        if (response?.status === 200) {
            dispatch( actions.setCountryList(response.data.items, response.data.totalCount) )
        }
    }
}

export const getStatesListByCountry= (countryId: number, queryParams: QueryParamsInterface): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.getStatesListByCountry(countryId, queryParams)
        console.log('getStatesListByCountry response', response)
        if (response?.status === 200) {
            dispatch( actions.setStatesListByCountry(response.data.items, response.data.totalCount) )
        }
    }
}

export const getStatesList= (params: any): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.getStatesList(params)
        if (response.status === 200) {
            dispatch( actions.setStatesListByCountry(response.data.items, response.data.totalCount) )
        }
    }
}

export const getCitiesListByCountry= (countryId: number, queryParams: QueryParamsInterface): ThunkType => {
    return async (dispatch, getState) => {
        const response = await addressAPI.getCitiesListByCountry(countryId, queryParams)
        console.log('getCitiesListByCountry response', response)
        if (response.status === 200) {
            dispatch( actions.setCitiesListByCountry(response.data.items) )
        }
    }
}


export default addressReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>