import { FC, useEffect, useRef, useState } from "react";
import { RatingProps } from "./Rating.props";
import styles from './Rating.module.scss'
import {
    ReactComponent as StarIcon
} from "./star.svg";
import cn from "classnames";

export const Rating: FC<RatingProps> = (props) => {
  const {className, rating} = props;

    const [ratingArray, setRatingArray] = useState<JSX.Element[]>(new Array(5).fill(<></>));

    const constructorRating = (currentRating: number): void => {
        const updatedArray = ratingArray.map((ratingStar, i) => {
            return <span key={i}
                         className={cn(styles.star, {
                             [styles.filled]: i < currentRating,
                         })}
            >
                <StarIcon />
            </span>;
        } );
        setRatingArray(updatedArray);
    };

    useEffect(() => {
        constructorRating(rating);
    }, [rating]);

  return(
      <div className={cn(className)}>
          {ratingArray.map((ratingStar, i) => <span key={i}>{ratingStar}</span>)}
      </div>
  )
}