import { InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import styles from './Exchange.module.scss'
import cn from "classnames";
import { exchangeDataType, ExchangePropsType } from "./Exchange.props";
const { Option } = Select;



const Exchange: React.FC<ExchangePropsType> = (props) => {
    const {className, currencyList, ...restProps} = props;
    const [exchangeData, setExchangeData] = useState<exchangeDataType>({
        currencyCount: 0,
        usdCount: 1000,
        course: 0,
    })
    const [isCanChangeCurrency, setIsCanChangeCurrency] = useState<boolean>(false)

    useEffect(() => {
        if(exchangeData.course > 0){
            setExchangeData( prevState => ({...prevState, currencyCount: prevState.usdCount/prevState.course}))
        }

    }, [exchangeData.course]);

    useEffect(() => {
        if(exchangeData.lastTarget === 'currency'){
            setExchangeData( prevState => ({...prevState, usdCount: prevState.currencyCount * prevState.course}))
        }
        if(exchangeData.lastTarget === 'usd' && exchangeData.course !==0){
            setExchangeData( prevState => ({...prevState, currencyCount: prevState.usdCount/prevState.course}))
        }

    }, [exchangeData.lastTarget, exchangeData.currencyCount, exchangeData.usdCount]);


    const onChange = (type: 'currency' | 'usd', value: string | number | null) => {
        if ( type === 'usd' && value !== null ) {
            setExchangeData({
                ...exchangeData,
                usdCount: +value,
                lastTarget: type
            })
        }
        if ( type === 'currency' && value !== null ) {
            setExchangeData({
                ...exchangeData,
                currencyCount: +value,
                lastTarget: type
            })
        }
    }

    const onCoinChange = (value: string) => {
        const course = currencyList?.find(i => i.symbol === value)?.fullPrice
        if(course){
            setExchangeData({
                ...exchangeData,
                course: course,
                coin: value
            })
        }
        if (value !== '') {
            setIsCanChangeCurrency(true)
        }
    }


    useEffect(() => {
        onCoinChange('BTC')
    }, [currencyList])

    console.log('CURR', currencyList)

    const afterSelect = () => {
        return (
            <Select onChange={onCoinChange} defaultValue='BTC' style={{width: "120px"}}>
                {/*<Option disabled value='select'>Select</Option>*/}
                {currencyList?.map(i => {
                    return (
                        <Option value={i.symbol} key={i.symbol}>{i.title}</Option>
                    )
                })}
            </Select>
        )
    }

    let currencyCount = exchangeData.currencyCount.toFixed(8)
    let usdCount = exchangeData.usdCount.toFixed(2)

    return (
        <div className={cn(styles.exchange, className)} {...restProps}>
            <div className={styles.input}>
                <div className={styles.title}>From:</div>
                <FromInput addonAfter="USD"
                           value={usdCount}
                           min={0}
                           onChange={value => onChange('usd', value)}/>
            </div>
            <div className={styles.input}>
                <div className={styles.title}>To:</div>
                <ToInput value={currencyCount}
                         min={0}
                         disabled={exchangeData.currencyCount === 0 && !isCanChangeCurrency}
                         onChange={value => onChange('currency', value)}
                         addonAfter={afterSelect()}/>
            </div>
        </div>
    )
}

export default Exchange

const FromInput = styled(InputNumber)`
  width: 230px;
  font-size: 16px;
`

const ToInput = styled(InputNumber)`
  max-width: 350px;
  font-size: 16px;
`