import { RolesType } from "../Redux/authReducer/types"

export const checkPermissions = (roles?: RolesType[], target?: keyof PermissionsMapForRouter): boolean => {
    if (target) {
        if (roles) {
            let isTrue = false
            permissionsMapForRouter[target].some( role => {
                const item = roles?.includes(role as RolesType)
                if (item) isTrue = item
            })
            return isTrue
        } else {
            return false
        }
    }
    return true
}

export type PermissionsMapForRouter = {
    admin: RolesType[]
    account: RolesType[]
    myObjects: RolesType[]
    addNewObject: RolesType[]
}

export const permissionsMapForRouter = {
    admin: ['ROLE_SUPER_ADMIN'],
    account: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_PREMIUM_USER', 'ROLE_CLIENT', 'ROLE_DEFAULT'],
    myObjects: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_PREMIUM_USER', 'ROLE_CLIENT'],
    addNewObject: ['ROLE_DEFAULT']
}