import { instance, url } from './API'
import * as queryString from 'query-string'
import { AddressType, BrandInfoType } from '../Redux/brendObjectReducer'
import { GetBrendItemByFilrterParams } from './brandItemApi'
import { PhoneType } from '../Redux/brandItemReducer'

export type GetBrendObjectByFilrterFieldsType = 
    'categories' |
    'value' |
    'currencies' |
    'countries' |
    'states' |
    'limit' |
    'offset'

export type CreateBrendObjectParamsType = {
    additionalInformation?: string
    address: AddressType
    // brandInfo: BrandInfoType
    brandId: number
    hasDelivery: boolean
    isActive: boolean
    phoneNumbers: PhoneType[]
    // routeUser: null
    tags: {}
    tasks: {}
    typeObject: string
    // workingSchedules: {}
}

export type GetBrendObjectsByBoundsParamsType = {
    lngMin: number
    lngMax: number
    latMin: number
    latMax: number
    category?: number[]
    zoom?: number
}

export type GetNearestBrendObjectsParamsType = {
    lng: number
    lat: number
    limit?: number
    distanse?: number
    'categories[]'?: number[]
    isAtm?: boolean
}

export const brendObjectAPI = {

    getBrendObjectsByBoundsNew(params: GetBrendObjectsByBoundsParamsType, categoryFilter: number[] | null) {
        let stringified: string = queryString.stringify(params);
        console.log('stringified', stringified)
        // categoryFilter && categoryFilter.map(item=> {
        //     stringified = stringified + '&category[]=' + item
        // })
        stringified = stringified.replaceAll('category=', 'category[]=')

        return instance.get('/api/map/get_by_bounds?'+ stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getBrendObjectById(id: number) {
        // return instance.get('/api/brands/getById/'+ id)
        return instance.get('/api/brandObjects/getById/'+ id)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getBrendObjectsByBounds(params: GetBrendObjectsByBoundsParamsType, categoryFilter: number[] | null) {
        // console.log('getBrendObjectsByBounds categoryFilter: ', categoryFilter);
        let stringified: string = queryString.stringify(params);
        const categoryFilterStr = categoryFilter ? queryString.stringify(categoryFilter) : '';


        // console.log(categoryFilterStr);
        categoryFilter && categoryFilter.map(item=> {
            stringified = stringified + '&category[]=' + item
        })

        // console.log(stringified);

        // return instance.get('/api/brands/getByBounds?'+ stringified)
        return instance.get('/api/brandObjects/getByBounds?'+ stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getNearestBrendObjects(params: GetNearestBrendObjectsParamsType) {
        // console.log(params);
        const stringified = queryString.stringify(params);
        const searchRegExp = /category/g;
        const query = stringified.replace(searchRegExp, 'category[]')

        // return instance.get('/api/brands/getNearest?'+ query)
        return instance.get('/api/map/get_nearest?'+ query)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },
    
    createBrendObject(params: CreateBrendObjectParamsType) {
        // return instance.post('/api/brands/getNearest?', params)
        return instance.post('/api/brandObjects/getNearest?', params)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getBrendObjectByFilrter(params: GetBrendItemByFilrterParams) {
        const stringified = queryString.stringify(params);
        const searchRegExp = /category/g;
        const query = stringified.replaceAll(searchRegExp, 'category[]')

        // return instance.get('/api/brands/getByFilrter?'+ query)
        return instance.get('/api/brandObjects/getByFilrter?'+ query)
        // return instance.get('/api/brandItem/getByFilter?'+ query)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    }
}
