import React, {FC, useEffect, useState} from "react";
import iconWatch from '../../../assets/images/icons/popup/watch.svg'
import iconBus from '../../../assets/images/icons/popup/bus.svg'
import iconBike from '../../../assets/images/icons/popup/bike.svg'
import iconCar from '../../../assets/images/icons/popup/car.svg'
import iconHuman from '../../../assets/images/icons/popup/human2.svg'
import iconNav from '../../../assets/images/icons/popup/nav.svg'
import iconCancel from '../../../assets/images/icons/popup/cancel-svgrepo-com.svg'
import iconStop from '../../../assets/images/icons/popup/stop-svgrepo-com.svg'
import styled from "styled-components";
import {ReactComponent as IconGo} from "./../../../assets/images/icons/go_green.svg";
import { useDispatch } from "react-redux";
import { actions } from "../../../Redux/mapReducer";

type RouteComponentPropsType = {
    directions?: google.maps.DirectionsResult
    setRoutes: React.Dispatch<any>
    setCenter: (coordinates: google.maps.LatLngLiteral) => void
    setZoom: React.Dispatch<React.SetStateAction<number>>
    changeTravelMode: (travelMode: google.maps.TravelMode, start?: google.maps.LatLngLiteral, stop?: google.maps.LatLngLiteral) => void
    onHideMenu: () => void
    lastZoom: number
    lastCenter: google.maps.LatLngLiteral
    isRerout: boolean
    setIsNavigateMode: React.Dispatch<React.SetStateAction<boolean>>
    isNavigateMode: boolean
}

export const RouteComponent: FC<RouteComponentPropsType> = React.memo((props) => {

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [travelMode, setTravelMode] = useState<google.maps.TravelMode>()
    const [isModeSelected, setIsModeSelected] = useState<boolean>(false)
    const [isGo, setIsGo] = useState<boolean>(false)

    useEffect(() => {
        return () => {
            dispatch(actions.setRoutes(undefined))
        }
    }, [])

    useEffect(() => {
        setIsOpen(!props.isRerout)
    }, [props.isRerout]);

    useEffect(() => {
        props.directions && props.directions.routes && props.directions.routes.length > 0 && setIsOpen(!!props.directions.routes[0])
        console.log('useEffect NavigatePanel directions1', props.directions)
        props.directions && console.log(!!props.directions.routes[0])
        // console.log('NavigatePanel directions2', props.directions.routes.length > 0)
    }, [props.directions, props.directions?.routes]);

    useEffect(() => {
        console.log('useEffect RouteComponent', props.directions?.routes[0].legs[0])
    }, [props.directions?.routes[0].legs[0]]);

    const choiseTravelMode = (mode: google.maps.TravelMode) => {

        console.log('choiseTravelMode origin', {
            // @ts-ignore
            lat: props.directions.request.origin.location.lat(),
            // @ts-ignore
            lng: props.directions.request.origin.location.lng(),
        })

        console.log('choiseTravelMode destination', {
            // @ts-ignore
            lat: props.directions.request.destination.location.lat(),
            // @ts-ignore
            lng: props.directions.request.destination.location.lng(),
        })

        if (mode !== travelMode) {
            setTravelMode(mode)
            setIsModeSelected(true)
            props.changeTravelMode(mode,
                {
                    // @ts-ignore
                    lat: props.directions.request.origin.location.lat(),
                    // @ts-ignore
                    lng: props.directions.request.origin.location.lng(),
                },
                {
                    // @ts-ignore
                    lat: props.directions.request.destination.location.lat(),
                    // @ts-ignore
                    lng: props.directions.request.destination.location.lng(),
                }
            )
        } else {
            setTravelMode(undefined)
            setIsModeSelected(false)
        }

    }

    const onRouteComponentClosed = () => {
        // alert('onRouteComponentClosed')
        setIsOpen(false)
        setTravelMode(undefined)
        props.setRoutes(undefined)
    }

    const onStop = () => {
        isGo && props.onHideMenu()
        setIsGo(false)
        setTravelMode(undefined)
        setIsModeSelected(false)

        props.setCenter(props.lastCenter)
        props.setZoom(props.lastZoom)

        // setTimeout(() => {
        //     props.setZoom(props.lastZoom)
        // }, 100);

        props.setIsNavigateMode(false)
    }

    const onGo = () => {
        console.log('onGo', props)
        // @ts-ignore
        props.setCenter(props.directions?.routes[0].legs[0].start_location)
        props.setZoom(16)
        setIsGo(true)
        props.onHideMenu()

        props.setIsNavigateMode(true)
    }

    const getIcon = () => {
        switch (travelMode) {
            case google.maps.TravelMode.BICYCLING:
                return iconBike

            case google.maps.TravelMode.WALKING:
                return iconHuman

            default:
                return iconCar
        }
    }

    if (!isOpen || !props.directions) {
        return <></>
    }
    if (props.isRerout) {
        console.log('!props.isRerout', props.isRerout)
        return <></>
    }

    return (
        <div style={{display: isOpen ? 'block' : 'none'}}>
            <Container>
                <IconsInner>
                    <IconItem>
                        <IconItemImg src={iconWatch} alt="watch"/>
                        <IconItemSpan>
                            {/* 10 min */}
                            {props.directions && props.directions?.routes[0] && props.directions.routes[0]?.legs[0]?.duration?.text.split('.')[0]}
                        </IconItemSpan>
                    </IconItem>
                    <IconItem>
                        <IconItemImg src={iconNav} alt="watch"/>
                        <IconItemSpan>
                            {/* 1 km */}
                            {props.directions && props.directions?.routes[0] && props.directions.routes[0]?.legs[0]?.distance?.text}
                        </IconItemSpan>
                    </IconItem>
                </IconsInner>

                <div style={{display: "flex", gap: 10}}>
                    {isModeSelected  && !isGo && <BtnGo onClick={onGo}><IconGo/></BtnGo> }
                    {!isModeSelected ?
                        <ButtonsInner>
                            <Button isChecked={travelMode === google.maps.TravelMode.DRIVING} onClick={() => {
                                choiseTravelMode(google.maps.TravelMode.DRIVING)
                            }}>
                                <ButtonIcon src={iconCar} alt="car"/>
                            </Button>
                            <Button isChecked={travelMode === google.maps.TravelMode.BICYCLING} onClick={() => {
                                choiseTravelMode(google.maps.TravelMode.BICYCLING)
                            }}>
                                <ButtonIcon src={iconBike} alt="bike"/>
                            </Button>
                            <Button isChecked={travelMode === google.maps.TravelMode.WALKING} onClick={() => {
                                choiseTravelMode(google.maps.TravelMode.WALKING)
                            }}>
                                <ButtonIcon src={iconHuman} alt="human"/>
                            </Button>
                            <Button isChecked={false} onClick={onRouteComponentClosed}>
                                <ButtonIcon src={iconCancel} alt="cancel"/>
                            </Button>
                        </ButtonsInner>
                        :
                        <ButtonsInner>
                            <Button isChecked={isGo}>
                                <ButtonIcon src={getIcon()} alt="go"/>
                            </Button>
                            <Button isChecked={false} onClick={onStop}>
                                <ButtonIcon src={iconStop} alt="stop"/>
                            </Button>
                        </ButtonsInner>
                    }

                </div>
            </Container>
        </div>
    )
})

const BtnGo = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  svg{
    width: 32px;
    height: 32px;
  }
`

const Container = styled.div`
  max-width: 335px;
  height: 55px;
  border-radius: 55px;
  background: #FFFFFF;
  box-shadow: 0 4px 6px rgba(89, 18, 159, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;  
  top: 140px;
`
const Title = styled.h6`
  max-width: 70px;
  height: 32px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #333333;
  margin: 0 8px;
`
const IconsInner = styled.div`
  max-width: 80px;
  width: 100%;
  margin-right: 5px;
  margin-left: 25px;
`
const IconItem = styled.div`
  display: flex;
`
const IconItemImg = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 10px;
`
const IconItemSpan = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
`
const ButtonsInner = styled.div`
  display: flex;
  border-left: 1px solid #797DF9;
  border-radius: 25px;
  padding: 7px;
`
const Button = styled.button<{ isChecked: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: ${({isChecked}) => isChecked ? '#797DF9' : '#ffffff'};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
`

const ButtonIcon = styled.img`
  width: 26px;
  height: 26px;

`
