import React, { FC, useEffect } from "react";
import styles from "../AuthPage.module.scss";
import user from "../../../../assets/images/icons/IconUser.svg";
import cn from "classnames";
import password from "../../../../assets/images/icons/IconPassword.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Color, SocialLink } from "../../../../newComponent";
import iconFacebook from "../../../../assets/images/icons/IconFB.svg";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline
} from "react-google-login";
import iconGoogle from "../../../../assets/images/icons/IconGoogle.svg";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { SocialLoginDataType } from "../AuthPage.props";
import { socialAuth } from "../../../../Redux/authReducer/authReduser";
import { useAppDispatch } from "../../../../Redux/store";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { AuthStatus, DefaultAuthFieldsValue } from "../AuthPage";
import { StatusType } from "../../../../Redux/appReducer";



interface FeedBackProps {
    close: () => void;
    status: AuthStatus;
    setStatus: (status: AuthStatus) => void
}

export const FeedBack: FC<FeedBackProps> = (props) => {
    const {close, status, setStatus} = props;

    useEffect(() => {
        const timerId = setTimeout(() => {
            setStatus({status: 'idle', message: ''})
            close()
        }, 3000)
        return () => clearTimeout(timerId)
    }, [])

    return <div className={cn(styles.message, {
        [styles.failed]: status.status === 'failed',
        [styles.success]: status.status === 'success'
    } )}>
        {status.message}
    </div>
}