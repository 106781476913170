import { Dispatch } from 'redux'
import { brandItemAPI } from '../Api/brandItemApi'
import { adminAPI } from '../Api/adminApi'
import { QueryParamsType } from '../Utils/UniversalTableView/UniversalTableView'
import { BrandItemType } from './brandItemReducer'
import { fakeBrendItemList } from './fakeData/BrandItemList'
import { BaseThunkType, InferActionsTypes } from './store'

export type InitialStateType = {
    advertisingBrendItemList?: BrandItemType[]
    advertisingBrendItemListCount?: number

    newestBrendItemList?: BrandItemType[]
    newestBrendItemListCount?: number

    populatedBrendItemList?: BrandItemType[]
    populatedBrendItemListCount?: number
}

let initialState: InitialStateType = {

}

type ActionsType = InferActionsTypes<typeof actions>

const advertisingReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'ADVERTISING/SET_ADVERTISING_BREND_ITEM_LIST':
            return {
                ...state,
                advertisingBrendItemList: action.advertisingBrendItemList,
                advertisingBrendItemListCount: action.advertisingBrendItemListCount
            }

        case 'ADVERTISING/SET_NEWEST_BREND_ITEM_LIST':
            return {
                ...state,
                newestBrendItemList: action.newestBrendItemList,
                newestBrendItemListCount: action.newestBrendItemListCount
            }

        case 'ADVERTISING/SET_POPYLATED_BREND_ITEM_LIST':
            console.log('SET_POPYLATED_BREND_ITEM_LIST',action)
            return {
                ...state,
                populatedBrendItemList: action.populatedBrendItemList,
                populatedBrendItemListCount: action.populatedBrendItemListCount
            }

        default:
            return state;
    }
}

export const actions = {
    setAdvertisingBrendItemList: (
        advertisingBrendItemList: BrandItemType[],
        advertisingBrendItemListCount: number
    ) => ({
        type: 'ADVERTISING/SET_ADVERTISING_BREND_ITEM_LIST',
        advertisingBrendItemList,
        advertisingBrendItemListCount
    } as const),

    setNewestBrendItemList: (
        newestBrendItemList: BrandItemType[],
        newestBrendItemListCount: number
    ) => ({
        type: 'ADVERTISING/SET_NEWEST_BREND_ITEM_LIST',
        newestBrendItemList,
        newestBrendItemListCount
    } as const),

    setPopylatedBrendItemList: (
        populatedBrendItemList: BrandItemType[],
        populatedBrendItemListCount: number
    ) => ({ 
        type: 'ADVERTISING/SET_POPYLATED_BREND_ITEM_LIST', 
        populatedBrendItemList,
        populatedBrendItemListCount
    } as const),
}

export const getAdvertisingBrendItemList = (params: QueryParamsType): ThunkType => {
    return async (dispatch, getState) => {
        // const response = await adminAPI.getBrendItemByFilter({
        const response = await adminAPI.getAllItemsByQuery(
            'brand_items',
            {
                ...params,
                isAdvertising: true,
                isRandom: true
            }
        )

        console.log('getAdvertisingBrendItemList: ', response)
        if (response.status === 200) {
            dispatch(actions.setAdvertisingBrendItemList(response.data.items, response.data.totalCount))
        } else {
            alert('ВЫВЕСТИ ОШИБКУ')
        }
    }
}

export const getNewestBrendItemList = (): ThunkType => {
    return async (dispatch, getState) => {
        // const response = await brandItemAPI.getBrendItemByFilter({ sortBy: 'created' })
        const response = await adminAPI.getAllItemsByQuery(
            'brand_items',
            { 
                page: 1,
                perPage: 10,
                sortBy: {created: 'desc'} 
            }
        )
        if (response.status === 200) {
            dispatch(actions.setNewestBrendItemList(response.data.items, response.data.totalCount))
        }
    }
}

export const getPopylatedBrendItemList = (): ThunkType => {
    return async (dispatch, getState) => {
        // const response = await brandItemAPI.getBrendItemByFilter({ sortBy: 'popularity' })
        const response = await adminAPI.getAllItemsByQuery(
            'brand_items',
            { 
                page: 1,
                perPage: 10,
                sortBy: {popularity: 'desc'} 
            }
        )
        if (response.status === 200) {
            dispatch(actions.setPopylatedBrendItemList(response.data.items, response.data.totalCount))
        }
    }
}

export default advertisingReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>