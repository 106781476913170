import React, { FC } from 'react'
import styled from 'styled-components'
import { Logo } from '../../newComponent'
import { ReactComponent as MailIcon } from '../../assets/images/Vector.svg'
import { ReactComponent as FacebookIcon } from '../../assets/images/facebookIcon.svg'
import { ReactComponent as GmailIcon } from '../../assets/images/gmailIcon.svg'
import { ReactComponent as InstagramIcon } from '../../assets/images/instaIcon.svg'
import { Link } from "react-router-dom";
import cn from "classnames";


const Footer: FC = (props) => {
    return (
        <FooterContainer >
            <Wrapper className={cn('container')}>
                <Logo navigateTo='/'/>
                <GridContainer>
                    <EmailContainer href="mailto:info@befree.com">
                        <MailIcon/>
                        <span>info@befree.com</span>
                    </EmailContainer>
                    <LinkItem href="mailto:support@befree.com">Contact support</LinkItem>
                    <LinkTermsOfUse to="/terms_of_use">Terms of Use</LinkTermsOfUse>
                </GridContainer>
                <IconsContainer>
                    <IconLink href='https://facebook.com' target='_blank'>
                        <FacebookIcon/>
                    </IconLink>
                    <IconLink href='https://gmail.com' target='_blank'>
                        <GmailIcon/>
                    </IconLink>
                    <IconLink href='https://instagram.com' target='_blank'>
                        <InstagramIcon/>
                    </IconLink>
                </IconsContainer>
            </Wrapper>
        </FooterContainer>
    )
}

export default Footer


const FooterContainer = styled.footer`
  box-shadow: -1px -4px 36px rgba(155, 175, 243, 0.25);
`

const Wrapper = styled.div`
  padding: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  @media (max-width: 570px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 140px repeat(2, 130px);
  align-items: center;
  gap: 30px;
  @media (max-width: 900px) {
    grid-template-columns: 140px;
    gap: 10px;
    justify-self: center;
  }
  @media (max-width: 570px) {
    justify-self: flex-start;
  }
`


const EmailContainer = styled.a({
    color: '#CFD7EC;',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "19px",
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    gap: 10
})

const LinkItem = styled.a({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
    color: "#6B6B6B",
    ":hover": {
        color: 'blue',
    }
})

const LinkTermsOfUse = styled(Link)({
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
    color: "#6B6B6B",
    ":hover": {
        color: 'blue',
    }
})

const IconsContainer = styled.div`
  justify-self: flex-end;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 150px;
  @media (max-width: 570px) {
    justify-self: flex-start;
  }
`

const IconLink = styled.a`
  svg {
    path {
      transition: all 0.3s;
    }

    rect {
      transition: all 0.3s;
    }
  ;
  }

  &:hover {
    svg {
      path {
        fill: #4041FB;
      }

      rect {
        stroke: #4041FB;
      }
    }
  }
`


