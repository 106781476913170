import { Dispatch } from 'redux'
import { authAPI } from '../Api/authApi'
// import { authAPI } from '../Api/authAPI'
import { BaseThunkType, InferActionsTypes } from './store'

export type CountryType = {
    title: string,
    id: number
}

export type InitialStateType ={
    isFocus: boolean
    countriesList?: CountryType[]
}

let initialState:InitialStateType = {
    isFocus: false
}

type ActionsType = InferActionsTypes<typeof actions>

const searchReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'SEARCH_REDUCER/SET_IS_FOCUS':
            return {
                    ...state,
                    isFocus: action.isFocus
            }
        default:
            return state;
    }
}

export const actions = {
    setIsFocus: (isFocus: boolean) => ({type: 'SEARCH_REDUCER/SET_IS_FOCUS', isFocus} as const),
}

export const setSearchIsFocus = (isFocus: boolean): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setIsFocus(isFocus) )
    }
}

export default searchReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>