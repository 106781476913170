import { Dispatch } from 'redux'
import {  addError } from './messageReducer'
import { BaseThunkType, InferActionsTypes } from './store'


export type MapInitialStateType ={
    myCoordinates: google.maps.LatLngLiteral | null // ?
    routes: google.maps.DirectionsResult | undefined
    travelMode?: google.maps.TravelMode
    lastStart?: google.maps.LatLngLiteral
    lastStop?: google.maps.LatLngLiteral
}

let initialState:MapInitialStateType = {
    myCoordinates: null,
    routes: undefined,
}

type ActionsType = InferActionsTypes<typeof actions>

const mapReducer = (state = initialState, action: ActionsType): MapInitialStateType => {
    switch (action.type) {
        case 'MAP/SET_MY_COORDINATES':
            return {
                    ...state,
                    myCoordinates: action.coordinates
            }
        case 'MAP/SET_ROUTES':
            return {
                ...state,
                routes: action.routes,
                lastStart: action.lastStart ? action.lastStart : state.lastStart,
                lastStop: action.lastStop ? action.lastStop : state.lastStop,
            }


        case 'MAP/SET_TRAVEL_MODE':
            return {
                ...state,
                travelMode: action.travelMode
            }

        default:
            return state;
    }
}

export const actions = {
    setMyCoordinates: (coordinates: google.maps.LatLngLiteral) => ({type: 'MAP/SET_MY_COORDINATES', coordinates} as const),
    setRoutes: (
        routes: google.maps.DirectionsResult| undefined, 
        lastStart?: google.maps.LatLngLiteral, 
        lastStop?: google.maps.LatLngLiteral
    ) => ({type: 'MAP/SET_ROUTES', routes, lastStart, lastStop} as const),
    setTravelMode: (travelMode: google.maps.TravelMode) => ({type: 'MAP/SET_TRAVEL_MODE', travelMode} as const),
}

export const changeTravelMode = (travelMode: google.maps.TravelMode, start?: google.maps.LatLngLiteral, stop?: google.maps.LatLngLiteral ): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setTravelMode(travelMode) )
        const state = getState().mapReducer
        if (start && stop) {
            console.log('changeTravelMode start', start)
            console.log('changeTravelMode stop', stop)
            dispatch(calculateRoute(start, stop))
        } else if (state.lastStart && state.lastStop) {
            console.log('changeTravelMode  state.lastStart', state.lastStart)
            console.log('changeTravelMode state.lastStop', state.lastStop)
            dispatch(calculateRoute(state.lastStart, state.lastStop))
        }
    }
}

export const setMyCoordinates = (coordinates: google.maps.LatLngLiteral): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setMyCoordinates(coordinates) )
    }
}



export const calculateRoute = (start: google.maps.LatLngLiteral, stop: google.maps.LatLngLiteral, routeTargetName?: string): ThunkType => {
    return async (dispatch, getState) => {
            
        const travelMode = getState().mapReducer.travelMode
        const DirectionsService = new google.maps.DirectionsService()   


       await DirectionsService.route({
            origin: start,
            destination: stop,
            travelMode: travelMode ? travelMode : google.maps.TravelMode.DRIVING,
        }, (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus)=>{
            console.log('calculateRoute status', status)
            if (status === 'OK' && result !== null) {
                dispatch( actions.setRoutes(result, start, stop) )
            } else {
                dispatch( addError('Can\'t build this route!') )
            }
        })

    }
}

export default mapReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>   