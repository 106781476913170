import { UploadFile } from 'antd/lib/upload/interface'
import { Dispatch } from 'redux'
import { uploadAPI } from '../Api/uploadApi'
import { BaseThunkType, InferActionsTypes } from './store'

export type UploadFilesType = {
    // [key in CategoryFieldsPropsType | BlogFieldsPropsType]: {
    [key: string]: {
        fieldName: string,
        file: UploadFile
    }
}

export type FileType = {
    fileName: string
    originalName: string
    size: number
    uid?: string
}

export type InitialStateType ={
    filesList: FileType[]
}

let initialState:InitialStateType = {
    filesList: []
}

type ActionsType = InferActionsTypes<typeof actions>

const uploadReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'UPLOAD/ADD_FILE_FROM_SERVER':
            const newFilesList = [...state.filesList]
            newFilesList.push({
                ...action.data,
                uid: action.uid
            })
            return {
                ...state,
                filesList: newFilesList
            }
        default:
            return state;
    }
}

export const actions = {
    addFileFromServer: (uid: string, data: FileType) => ({type: 'UPLOAD/ADD_FILE_FROM_SERVER', uid, data} as const),
}

export const uploadFile = (file: any): ThunkType => {
    return async (dispatch, getState) => {
        const response = await uploadAPI.uploadFile(file)
        if (response.status === 200) {
            dispatch( actions.addFileFromServer(file.uid, response.data) )
        }
    }
}

export type UploadDataType = {
    [key: string]: {
        file: UploadFile[],
        type: 'string' | 'array'
    }
}

export const uplodFilesToObject = (
    callBack: (object: any, queryParams: any)=>ThunkType,
    object: any,
    queryParams: any,
    uploadData: UploadDataType
): ThunkType => {
    return async (dispatch, getState) => {
        console.log('uplodFilesToObject', uploadData)
        console.log('object', object)
        for (const key in uploadData) {
            const files = uploadData[key].file;
            console.log(key, files)

            for (let i = 0; i < files.length; i++) {
                if (files[i].originFileObj) {
                    const response = await uploadAPI.uploadFile(files[i].originFileObj)
                    if (response.status === 200 ) {
                        console.log(response.data.fileName)
                        if (i === 0) {
                            if(uploadData[key].type === 'array') {
                                object[key] = [response.data.fileName]
                            } else {
                                object[key] = response.data.fileName
                            }
                        } else {
                            console.log(key, object[key])
                            object[key].push(response.data.fileName)
                        }
                    }
                }
            }
        }

        console.log('final object',object)
        dispatch(callBack(object, queryParams))
    }
}

export default uploadReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>