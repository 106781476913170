import { instance, url } from './API'

export interface UploadRequestData {
    file: any,
    path: string
}

export const uploadAPI = {
    uploadFile(file: any) {
        console.log('uploadFile', file)
        var formData = new FormData();
        formData.append("file", file);
        formData.append("path", 'brand_items');

        return instance.post('/api/files', formData, 
        {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('apikey'),
                'Content-Type': 'multipart/form-data',
                'Accept': "application/json"
            }
        }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },
    uploadFileNew(formData: UploadRequestData) {
        return instance.post('/api/files', formData,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('apikey'),
                    'Content-Type': 'multipart/form-data',
                    'Accept': "application/json"
                }
            }
        )
    }
}