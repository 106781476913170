import UserContacts from "../Components/AccountPage/UserContacts/UserContacts";
import MyObjects from "../Components/AccountPage/MyObjects/MyObjects";
import { LikedOrPinned } from "../Components/AccountPage/LikedOrPinned/LikedOrPinned";
import React from "react";
import { NotContent } from "../Components/NotContent/NotContent";
import { UserInfo } from "../Components/AccountPage/UserContacts/UserInfo";
import {
    EditBrandObjectPage
} from "../Components/AccountPage/EditBrandObjectPage/EditBrandObjectPage";
import {
    CreateBrandObjectPage
} from "../Components/AccountPage/CreateBrandObjectPage/CreateBrandObjectPage";

export const accountRoutes = [
    {
        path: "profile",
        element: <UserContacts/>
    },
    {
        path: "profile-test",
        element: <UserInfo />
    },
    {
        path: "payment",
        element: <NotContent title='Payment' responsive={810}>empty</NotContent>
    },
    {
        path: "my-objects",
        element: <MyObjects/>,
    },
    {
        path: "my-object/edit/:brandObjectId",
        element: <EditBrandObjectPage/>,
    },
    {
        path: "new-object",
        element: <CreateBrandObjectPage />
    },
    {
        path: "liked",
        element: <LikedOrPinned dataType='likes'/>
    },
    {
        path: "note",
        element: <LikedOrPinned dataType='pins'/>
    },
    {
        path: "history",
        element: <NotContent title='History' responsive={810} >You have no history</NotContent>
    }
]