import { createBrowserRouter, Navigate } from "react-router-dom";
import React from "react";
import { accountRoutes } from "./account.routes";
import CatalogItemBI from "../Components/Catalog/CatalogItem/CatalogItemBI";
import Currencies from "../Components/Currencies/Currencies";
import About from "../Components/Contacts/About";
import { Layout } from "../layout/Layout";
import { ErrorPage } from "../Components/ErrorPage/ErrorPage";
// import MainPage from "../Components/Main/MainPage";
import MapPage from "../Components/MapPage/MapPage";
import Catalog from "../Components/Catalog/CatalogNew";
import NewsPage from "../Components/NewsPage/NewsPage";
import NewsLandingPage from "../Components/NewsLandingPage/NewsLandingPage";

const TermsOfUse = React.lazy(() => import('../Components/TermsOfUsePage/TermsOfUse'))
const AdminContainer = React.lazy(() => import('../Components/Admin/AdminContainer'))
const MainPage = React.lazy(() => import('../Components/Main/MainPage'))
const AccountPage = React.lazy(() => import('../Components/AccountPage/AccountPage'))
const ChangePassword = React.lazy(() => import('../layout/Header/AuthPage/ChangePassword/ChangePassword'))


export const routers = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                element: <MainPage/>,
            },
            {
                path: "blog",
                element: <NewsPage/>,
            },
            {
                path: "blog/:blogSlug",
                element: <NewsLandingPage/>,
            },
            {
                path: "catalog",
                element: <Catalog/>,
            },
            {
                path: "catalog/:categorySlug/:id",
                element: <CatalogItemBI/>,
            },
            {
                path: "currencies",
                element: <Currencies/>,
            },
            {
                path: "about-us",
                element: <About/>,
            },
            {
                path: "account",
                element: <AccountPage/>,
                children: accountRoutes
            },
            {
                path: "admin",
                element: <AdminContainer/>,
            },
            {
                path: "terms_of_use",
                element: <TermsOfUse/>,
            },
            {
                path: "recovery-password/:token",
                element: <ChangePassword/>,
            },
            // {
            //     path: "*",
            //     element: <NotFound/>,
            // },
            {
                path: "*",
                element: <Navigate to="/" replace/>
            }
        ]
    },
    {
        path: "map",
        element: <Layout isWithoutFooter={true}/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: '',
                element: <MapPage/>
            }
        ]
    },

]);