import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React from 'react'
import { ScaleLoader } from 'react-spinners'
import styled from 'styled-components'
import { url } from '../../../../Api/API'
import { BrandItemType } from '../../../../Redux/brandItemReducer'
import { mapTypeStyle } from '../../../MapPage/GoogleMap/mapStyles'
import { Spinner } from "../../../../newComponent";

const containerStyle = {
    maxWidth: '1060px',
    margin: '0 auto',
    // height: `calc(100vh - 50px)`,
    height: `500px`,
}

type CatalogItemLocationPropsType = {
    target: BrandItemType
}

const CatalogItemLocation: React.FC<CatalogItemLocationPropsType> = (props) => {

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAfnLiXnH7ZdYNuuD6hLBAZHtiXEklnPio"
    })

    const pastUrl = props.target.category.markerFileName ? props.target.category.markerFileName : '/ATM_marker.svg'

    if ( !isLoaded ) {
        return (
            <Spinner/>
        )
    }

    return (
        <Container>
            <GoogleMap
                mapContainerStyle={containerStyle}
                options={{
                    streetViewControl: false,
                    zoomControl: false,
                    keyboardShortcuts: false,
                    mapTypeControl: false,
                    styles: mapTypeStyle,
                    gestureHandling: 'greedy',
                    // minZoom: 10,
                    fullscreenControl: false,
                    restriction: {
                        latLngBounds: {
                            north: 85,
                            south: -50,
                            east: 180,
                            west: -180,
                        }
                    },
                }}
                zoom={18}
                center={{
                    lat: props.target.latitude,
                    lng: props.target.longitude
                }}
            >
                <Marker
                    onClick={() => {
                    }}
                    // label={{ text: 'You\'re here' }}
                    icon={{
                        url: url + pastUrl,
                        scaledSize: new google.maps.Size(45, 50)
                    }}
                    position={{
                        lat: props.target.latitude,
                        lng: props.target.longitude
                    }}
                />
            </GoogleMap>
        </Container>
    )
}

export default CatalogItemLocation

const Container = styled.div({
    minHeight: 500
})