import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './UserProfile.module.scss'
import noImage from './no_image.png'
import { Avatar, Badge } from "@mui/material";
import { EditableDiv } from "../../../newComponent/EditableDiv/EditableDiv";
import { useForm } from "react-hook-form";
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import {  changeUserData } from "../../../Redux/authReducer/authReduser";
import { AuthStateType } from "../../../Redux/authReducer/types";
import { StatusType } from "../../../Redux/appReducer";
import { authAPI } from "../../../Api/authApi";
import { addError, addSuccess } from "../../../Redux/messageReducer";
import { Upload } from "antd";
import ImgCrop from 'antd-img-crop';
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { uploadAPI } from "../../../Api/uploadApi";
import { url as baseUrl } from "../../../Api/API";
import { AxiosResponse } from "axios";
import EditIcon from "@mui/icons-material/Edit";
import {
    ReactComponent as IconClose
} from "../../../assets/images/icons/button-close.svg";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface UserContactFields {
    username: string;
    password: string;
    phone: string;
}

const getSrcFromFile = (file: any) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
    });
};

const UserContacts: React.FC = () => {
    const dispatch = useAppDispatch()

    const {
        status,
        userData
    } = useSelector<AppStateType, AuthStateType>(state => state.authReducer)
    const {watch, register, setValue} = useForm<UserContactFields>()
    const [username, password, phone] = watch(['username', 'password', 'phone'])
    const [requestStatus, setRequestStatus] = useState<StatusType>('idle')
    const [editPhoto, setEditPhoto] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onSubmit = async (name: string) => {
        if ( !userData ) {
            return dispatch(addError('Something went wrong, try again'))
        }

        const id = userData.id
        if ( name === 'password' ) {
            if ( password.length < 8 ) {
                setValue('password', '')
                return dispatch(addError('The password must be at least 8 characters long'))
            }
            try {
                await authAPI.changePassword({password})
                setRequestStatus('success')
                dispatch(addSuccess('The data was successfully updated'))
            } catch {
                setRequestStatus('failed')
                dispatch(addError('Something went wrong, try again'))
            }
            setValue('password', '')
        }

        if ( name === 'username' ) {
            dispatch(changeUserData({id, username}))
        }

        if ( name === 'phone' ) {
            dispatch(changeUserData({id, phone}))
        }
    }

    const onChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileList(newFileList);
    };

    const onPreview = async (file: any) => {
        const src = file.url || (await getSrcFromFile(file));
        const imgWindow = window.open(src);

        if ( imgWindow ) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };

    const handleRemoveFile = () => {
        dispatch(changeUserData({id: userData?.id!, photoFile: null}))
    }

    const handleCloseEditPhoto = () => {
        setEditPhoto(false)
        setFileList([])
    }

    const customRequest = async ({onSuccess, onError, file}: any) => {
        const res: AxiosResponse<{
            "id": number,
            "userUpload": string,
            "filename": string,
            "clientOriginalName": string,
            "mimeType": string,
            "size": number,
            "uploadedAt": Date
        }> = await uploadAPI.uploadFile(file)

        if ( res.status < 400 ) {
            onSuccess()
            dispatch(changeUserData({id: userData?.id!, photoFile: res.data.id}))
        } else {
            onError()
        }
    }

    useEffect(() => {
        if ( userData ) {
            setValue('username', userData.username)
            setValue('phone', userData.phone)
            setValue('password', '')
        }

    }, [userData])
    console.log(userData?.photoFile && baseUrl + 'media/150x150' + userData.photoFile.filename + '.webp')

    const [value, setPhone] = useState<{ phone: string } | undefined>({phone: ''})

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                Profile Info
            </div>

            {
                editPhoto ? <div className={styles.editableImage}>
                        <ImgCrop grid>
                            <Upload
                                customRequest={customRequest}
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                onRemove={handleRemoveFile}
                            >
                                {fileList.length < 1 && '+ Upload'}
                            </Upload>
                        </ImgCrop>
                        <button className={styles['btn-close']}
                                onClick={handleCloseEditPhoto}>
                            <IconClose/>
                        </button>
                    </div>
                    :
                    <Badge
                        className={styles.image}
                        overlap="circular"
                        sx={{width: 150, height: 150}}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={
                            <button className={styles['btn-edit']}
                                    onClick={() => setEditPhoto(true)}>
                                <EditIcon style={{width: 17, height: 17}}/>
                            </button>
                        }
                    >
                        <Avatar
                            srcSet={baseUrl + 'media/150x150/' + userData?.photoFile?.filename + '.webp'}
                            alt={userData?.username || 'no photo'}
                            src={userData?.photoFile ? baseUrl + userData.photoFile.filename : noImage}
                            sx={{width: 150, height: 150}}
                        />
                    </Badge>
            }


            <div className={styles.properties}>
                <div className={styles['property-item']}>
                    Name
                    <EditableDiv value={username}
                                 valueIfEmpty='not added'
                                 isLoading={status === 'loading'}
                                 onSave={onSubmit}
                                 name='username'
                                 inputProps={{...register('username')}}/>
                </div>
                <div className={styles['property-item']}>
                    Email
                    <EditableDiv value={userData?.email}
                                 onSave={onSubmit}
                                 name='email'
                                 isEditable={false}/>
                </div>
                <div className={styles['property-item']}>
                    Phone number
                    <EditableDiv
                        value={phone === null ? '' : phone}
                        name='phone'
                        type='tel'
                        valueIfEmpty='not added'
                        isLoading={status === 'loading'}
                        onSave={onSubmit}
                        inputProps={{...register('phone')}}/>
                </div>
                <div className={styles['property-item']}>
                    Password
                    <EditableDiv value={password}
                                 name='password'
                                 valueIfEmpty='********'
                                 isLoading={requestStatus === 'loading'}
                                 onSave={onSubmit}
                                 inputProps={{...register('password')}}/>
                </div>
                {/*<PhoneInput*/}
                {/*    inputProps={{*/}
                {/*        name: 'phone',*/}
                {/*        required: true,*/}
                {/*        autoFocus: true*/}
                {/*    }}*/}
                {/*    country={'us'}*/}
                {/*    value={value?.phone}*/}
                {/*    //disabled*/}
                {/*    onChange={phone => setPhone({phone})}*/}
                {/*/>*/}
            </div>
        </div>
    )
}

export default UserContacts
