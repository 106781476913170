import { Dispatch } from 'redux'
import { adminAPI } from '../Api/adminApi';
import { uploadAPI } from '../Api/uploadApi';
import { FormDataType, FormUploadDataType } from '../Utils/UniversalForm/UniversalForm';
import { QueryParamsType } from '../Utils/UniversalTableView/UniversalTableView';
import { addSuccess } from './messageReducer';
import { BaseThunkType, InferActionsTypes } from './store'
import { checkAuth } from "./authReducer/authReduser";
import { addressAPI } from "../Api/addressApi";
import { brandItemAPI } from "../Api/brandItemApi";
import { BrandItemType } from "./brandItemReducer";

export type QueryParamsInterface = QueryParamsType

export type ObjectType = {
    id?: number
}

export type ItemListType = {
    [key: string]: ObjectType[]
}

export type CountListType = {
    [key: string]: number
}

export type LastQueryParamsListType = {
    [key: string]: QueryParamsInterface
}

export type InitialStateType = {
    itemsLists: ItemListType
    countList: CountListType
    lastQueryParams: LastQueryParamsListType
}

let initialState: InitialStateType = {
    itemsLists: {},
    countList: {},
    lastQueryParams: {}
}

type ActionsType = InferActionsTypes<typeof actions>

const adminReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'ADMIN/SET_ITEM_TO_LIST':
            const itemsLists = {...state.itemsLists}
            itemsLists[action.field] = action.itemsLists
            const countList = {...state.countList}
            countList[action.field] = action.count

            return {
                ...state,
                itemsLists: itemsLists,
                countList: countList
            }

        case 'ADMIN/SET_LAST_QUERY_PARAM':

            return {
                ...state,
                lastQueryParams: {
                    ...state.lastQueryParams, [action.field]: {
                        ...state.lastQueryParams[action.field], ...action.queryParams
                    }
                }
            }

        default:
            return state;
    }
}

export const actions = {
    setCurrentItem: (field: string, itemsLists: ObjectType[], count: number) =>
        ({type: 'ADMIN/SET_ITEM_TO_LIST', itemsLists, field, count} as const),
    setLastQueryParam: (field: string, queryParams: QueryParamsInterface) =>
        ({type: 'ADMIN/SET_LAST_QUERY_PARAM', field, queryParams} as const),
}

export const getCurrentItemList = (
    field: string,
    queryParams: QueryParamsType,
    action?: (itemsLists: any[], count: number) => any,
    isItem?: boolean
): ThunkType => {
    return async (dispatch, getState) => {
        dispatch(actions.setLastQueryParam(field, queryParams))
        const response = await adminAPI.getAllItemsByQuery(field, queryParams)
        if ( response.status === 200 ) {
            if ( action ) {
                !isItem ?
                    dispatch(action(
                        response.data.items ? response.data.items : [response.data],
                        response.data.totalCount ? response.data.totalCount : 1
                    ))
                    :
                    dispatch(action([response.data], 1))
            } else {
                dispatch(actions.setCurrentItem(field, response.data.items, response.data.totalCount))
            }

        }
    }
}

export const deleteCurrentItem = (field: string, id: number, queryParams: QueryParamsInterface, callback?: any): ThunkType => {
    return async (dispatch, getState) => {
        dispatch(actions.setLastQueryParam(field, queryParams))
        const response = await adminAPI.deleteItem(field, id)
        if ( response.status === 204 ) {
            console.log('deleteCurrentItem for ' + field, response)
            console.log('queryParams', queryParams)
            dispatch(getCurrentItemList(field, queryParams))
            if ( callback ) {
                dispatch(callback())
            }
        }
    }
}

export const createCurrentItem = (
    field: string,
    item: any,
    queryParams?: any,
    uploadData?: FormUploadDataType,
    dependedObjects?: FormDataType,
    callback?: any,
    callbackParam?: any
): ThunkType => {
    // console.group('createCurrentItem')
    //     console.log('field1111111', field)
    //     console.log('item111', item)
    //     console.log('queryParams', queryParams)
    //     console.log('uploadData', uploadData)
    //     console.log('dependedObjects', dependedObjects)
    //     console.log('callback', callback)
    if ( dependedObjects ) {
        return async (dispatch, getState) => {
            for (const key in dependedObjects) {
                if ( Object.prototype.hasOwnProperty.call(dependedObjects, key) ) {
                    const element = dependedObjects[key];
                    if ( element.id ) {
                        const response = await adminAPI.updateItem(key, element.id, element)
                        if ( response.status === 201 ) {
                            item[dependedObjects[key].singleFieldName] = response.data.id
                        }
                    } else {
                        const response = await adminAPI.createItem(key, element)
                        if ( response.status === 201 ) {
                            item[dependedObjects[key].singleFieldName] = response.data.id
                        }
                    }
                }
            }
            dispatch(createCurrentItem(field, item, queryParams, uploadData))
        }
    } else if ( uploadData ) {
        return async (dispatch, getState) => {
            dispatch(uplodFilesToObject(
                field,
                createCurrentItem,
                item,
                queryParams,
                uploadData
            ))
        }
    } else {
        return async (dispatch, getState) => {
            queryParams && dispatch(actions.setLastQueryParam(field, queryParams))
            const response = await adminAPI.createItem(field, item)
            if ( response.status === 200 || response.status === 201 ) {
                queryParams && dispatch(getCurrentItemList(field, queryParams))
                if ( callback ) {
                    if ( !callbackParam ) {
                        dispatch(callback())
                    } else {
                        callback(callbackParam)
                    }
                }
            } else {
                if ( callback ) {
                    dispatch(callback(callbackParam))
                }
            }
            // console.groupEnd()

            dispatch(addSuccess('Object created'))
        }
    }
}

export const updateCurrentItem = (
    field: string,
    item: any,
    queryParams?: any,
    uploadData?: FormUploadDataType,
    dependedObjects?: FormDataType
): ThunkType => {
    console.log('updateCurrentItem uploadData', uploadData)
    if ( dependedObjects ) {
        console.log('case 1')
        return async (dispatch, getState) => {
            for (const key in dependedObjects) {
                if ( Object.prototype.hasOwnProperty.call(dependedObjects, key) ) {
                    const element = dependedObjects[key];
                    if ( element.id ) {
                        const response = await adminAPI.updateItem(key, element.id, element)
                        if ( response.status === 200 ) {
                            item[key] = response.data.id
                        }
                    } else {
                        const response = await adminAPI.createItem(key, element)
                        if ( response.status === 200 ) {
                            item[key] = response.data.id
                        }
                    }
                }
            }
            dispatch(updateCurrentItem(field, item, queryParams, uploadData))

        }
    } else if ( uploadData ) {
        console.log('case 2')
        return async (dispatch, getState) => {
            dispatch(uplodFilesToObject(
                field,
                updateCurrentItem,
                item,
                queryParams,
                uploadData
            ))
        }
    } else {
        console.group('case 3')
        console.groupEnd()
        return async (dispatch, getState) => {
            // console.log('queryParams', !!queryParams)
            queryParams && dispatch(actions.setLastQueryParam(field, queryParams))
            console.group('case 3 inner')
            console.log('data', field, item.id, {...item})
            console.groupEnd()

            // console.log('queryParams', !!queryParams)
            const response = await adminAPI.updateItem(field, item.id, item)
            if ( response.status === 200 ) {
                queryParams && dispatch(getCurrentItemList(field, queryParams))
                dispatch(addSuccess('Object updated'))
                dispatch(checkAuth())
            }
        }
    }
}

const uplodFilesToObject = (
    field: string,
    callBack: (field: string, object: any, queryParams: any) => ThunkType,
    object: any,
    queryParams: any,
    uploadData: FormUploadDataType
): ThunkType => {
    return async (dispatch, getState) => {
        let objectIds: number[] = []
        let objectId: number = 0
        for (const uploadDataName in uploadData) {
            if ( Object.prototype.hasOwnProperty.call(object, uploadDataName) ) {
                const uploadDataItem = object[uploadDataName]
                if ( uploadDataItem ) {
                    if ( Array.isArray(uploadDataItem) ) {
                        for (let index = 0; index < uploadDataItem.length; index++) {
                            const file = uploadDataItem[index];
                            if ( !file.id ) {
                                console.log('file to upload', file)
                                const response = await uploadAPI.uploadFile(file.originFileObj)
                                if ( response.status < 400 ) {
                                    objectIds.push(response.data.id)
                                }
                            } else {
                                objectIds.push(file.id)
                            }

                        }
                        object[uploadDataName] = objectIds
                    } else {
                        if ( !uploadDataItem.id ) {
                            const response = await uploadAPI.uploadFile(uploadDataItem.originFileObj)
                            if ( response.status < 400 ) {
                                objectId = response.data.id
                            }
                        } else {
                            objectId = uploadDataItem.id
                        }
                        object[uploadDataName] = objectId
                    }
                }
            }
        }
        dispatch(callBack(field, object, queryParams))
    }
}



export default adminReducer

export type AdminActionsType = ReturnType<typeof actions.setCurrentItem>

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>