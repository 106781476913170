import React, {FC, useState} from "react";

import styled from "styled-components";

import {ButtonForZoomingPropsType} from "./types";



export const ButtonForZooming: FC<ButtonForZoomingPropsType> = (props) => {
    const {onClick, view, type = 'button', location = 'top'} = props;

    const [active, setActive] = useState<boolean>(false)

    const handleOnClick = (): void => {
        onClick && onClick()
    }

    const handleOnMouseDown = (): void => setActive(true)

    const handleOnMouseUp = (): void => setActive(false)

    return (
        <Button onClick={handleOnClick}
                type={type}
                onMouseDown={handleOnMouseDown}
                onMouseUp={handleOnMouseUp}
                active={active}
                location={location}>

            <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {view === 'minus' ?
                    <path
                        d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"/>
                    :
                    <path
                        d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"/>

                }
            </Icon>
        </Button>
    )
}

const Button = styled.button<{ active: boolean, location: 'top' | 'bottom' }>`
  display: block;
  width: 40px;
  height: 38px;
  border: none;
  border-radius: ${props => props.location === 'top' ? ' 10px 10px 0 0' : '0 0 10px 10px'};
  box-shadow: ${props => props.active ? 'none' : '0 6.66667px 6.66667px rgba(0, 0, 0, 0.25)'};
  outline: none;
  background-color: #ffffff;
  cursor: pointer;
  transition: all .1s;

  &:hover {
    background-color: #797DF9;
    svg {
      fill: #333333
    }
  }
`


const Icon = styled.svg`
  fill: #666666
`
