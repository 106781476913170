import React, { FC, useState } from "react";
import { InputProps } from "./Input.props";
import styles from './Input.module.scss';
import cn from 'classnames';
import {ReactComponent as IconEye} from "../../../assets/images/icons/icon-eye.svg";
import {ReactComponent as IconEyeOpen} from "../../../assets/images/icons/icon-eye-open.svg";


export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {className, appearance = 'default', type,  isActive = false, ...restProps} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
        <>
            {
                type !== "password" && <input
                ref={ref}
                className={cn(styles.input, className, {
                    [styles.active]: isActive,
                    [styles.transparent]: appearance === 'transparent',
                    [styles.default]: appearance === 'default'
                })}
                type={type}
                {...restProps} />
            }

            {
                type === "password" &&  <div className={styles.wrapper}>
                    <input
                        ref={ref}
                        className={cn(styles.input, className, {
                            [styles.active]: isActive,
                            [styles.transparent]: appearance === 'transparent'
                        })}
                        type={showPassword ? 'text' : 'password'}
                        {...restProps}
                    />
                <span
                    onClick={() => setShowPassword(!showPassword)}
                    className={styles.icon}>
                   {showPassword ?  <IconEyeOpen/> :  <IconEye/>}
                </span>
                </div>
            }
        </>

    )
})