import React, {FC} from "react";

import styled from "styled-components";

import icon from './../../../assets/images/icons/icon-menu.svg'
import iconCross from "../../../assets/images/icons/iconCross.svg";
import {ButtonMenuPropsType} from "./types";


export const ButtonMenu: FC<ButtonMenuPropsType> = ({onClick, openNav}) => {

    const handleOnClick = (): void => onClick && onClick(!openNav)

    return(
        <Button onClick={handleOnClick}>
            <img src={openNav ? iconCross : icon} alt="menu"/>
        </Button>
    )
}

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;  
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  cursor: pointer;
  display: none;
  @media(max-width: 810px){
    display: flex;
  }
`

