export interface UserNavMenuItem {
    id: number, title: string, pathName?: string
}


export const userNavMenu: UserNavMenuItem[] = [
    {id: 11, title: 'My account', pathName: '/account/profile'},
    // {id: 77, title: 'History', pathName: '/account/history'},
    {id: 33, title: 'My objects', pathName: '/account/my-objects'},
    {id: 44, title: 'Add new object', pathName: '/account/new-object'},
    {id: 55, title: 'I liked it', pathName: '/account/liked'},
    {id: 66, title: 'Took note', pathName: '/account/note'},
    // {id: 22, title: 'Payment', pathName: '/account-test/payment'},
    {id: 222, title: 'Logout'},

]

export const adminNavMenu: UserNavMenuItem[] = [
    {id: 111, title: 'Admin panel', pathName: '/admin'},
]

export const defaultMenu: UserNavMenuItem[] = [
    {id: 1, title: 'Login'},
    {id: 2, title: 'Register'},
]