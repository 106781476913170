import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from '../../Icon'
import { IconNameType } from "../../Icon/types";


export type ButtonSelectProductPropsType = {
    onClick?: (id: number, isSelected: boolean) => void
    title: string
    isSelected?: boolean
    iconId: IconNameType
    categoryId: number
    dragging?: boolean
}

export const ButtonSelectProduct: FC<ButtonSelectProductPropsType> = (props) => {

    // console.log(props)

    //const [isSelected, setIsSelected] = useState<boolean>(props.isSelected ? props.isSelected : false)

    // useEffect(() => {
    //     console.log('useEffect ButtonSelectProduct', props)
    //     props.isSelected && setIsSelected(props.isSelected)
    // }, [props.isSelected]);

    const handleOnClick = (): void => {
        console.log('!!!ButtonSelectProduct props', props)
        props.onClick && props.onClick(props.categoryId, !props.isSelected)
        // setIsSelected(!isSelected)
    };

    return (
        <Button
            isSelected={!!props.isSelected}
            onClick={handleOnClick}
            dragging={props.dragging || false}
        >
            <IconContainer>
                <Icon name={props.iconId} isActive={!!props.isSelected} />
            </IconContainer>
            <Text>
                {props.title}
            </Text>
        </Button>
    )

}

const Button = styled.button<{ isSelected: boolean, dragging: boolean }>`
    width: auto;
    height: 40px;
    padding: 0 10px;
    outline: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow:  0 2px 6px rgba(0, 0, 0, 0.25);
    border: 1px solid ${({ isSelected }) => isSelected ? '#DEDEDE' : '#ffffff'};
    background-color: ${({ isSelected }) => isSelected ? '#808080' : '#ffffff'};
    margin: 5px;
    cursor: ${({dragging})=> dragging ? 'grabbing' : 'pointer'};
`

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 14px;
`

const Text = styled.span`
  padding-top: 2px;
`
