import React, { FC, useEffect, useState } from "react";
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import {  setAuth } from "../../../Redux/authReducer/authReduser";
import { AuthStateType } from "../../../Redux/authReducer/types";
import MenuItem from "@mui/material/MenuItem";
import styles from "./UserProfile.module.scss";
import { Avatar, IconButton, Modal } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import MenuUser from "@mui/material/Menu";
import { AuthPage } from "../AuthPage/AuthPage";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserMenu } from "../../helpers/getUserMenu";
import { url as baseUrl } from "../../../Api/API";
import noImage from "../../../Components/AccountPage/UserContacts/no_image.png";


export const UserProfile: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [pageName, setPageName] = useState<'login' | 'register' | 'forgot' | 'feedBack'>('login')
    const {
        isAuth,
        userData, status
    } = useSelector<AppStateType, AuthStateType>(state => state.authReducer)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (name: string) => {
        setAnchorEl(null);
        switch (name) {
            case 'Logout':
                localStorage.removeItem('apikey');
                dispatch(setAuth(false));
                navigate('/')
                break;
            case 'Login':
                setPageName('login');
                setOpenModal(true);
                break;
            case 'Register':
                setPageName('register')
                setOpenModal(true)
                break;
        }

    };

    useEffect(() => {
        isAuth && setOpenModal(false)
    }, [isAuth]);


    const activeStyle = {textDecoration: 'underline'}
    const buildNavMenu = () => {
        return (
            getUserMenu(userData?.roles, isAuth).map(({id, title, pathName}) => {
                if ( pathName ) {
                    return <NavLink
                        style={({isActive}) => isActive ? activeStyle : {}}
                        to={pathName}
                        className={styles['menu-link']}
                        key={id}>
                        <MenuItem onClick={() => handleClose(title)}>
                            {title}
                        </MenuItem>
                    </NavLink>
                } else {
                    return <MenuItem key={id}
                                     onClick={() => handleClose(title)}>{title}</MenuItem>
                }
            })
        )
    }

    return (
        <div className={styles.user}>
            <div style={{textAlign: "center"}}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color='secondary'
                    onClick={handleMenu}
                    sx={{p: 0, m: 0}}
                >
                    {userData && isAuth ?
                        <Avatar alt={userData?.username || 'no photo'}
                                srcSet={baseUrl + 'media/24x24/' + userData?.photoFile?.filename + '.webp' }
                                src={userData.photoFile ? baseUrl + userData.photoFile.filename : noImage}
                                sx={{width: 24, height: 24}}/>
                        :
                        <AccountCircle/>}

                </IconButton>
                <div
                    className={styles.name}>{!isAuth ? 'Profile' : userData && userData?.username! || userData?.email}</div>

            </div>


            <MenuUser
                id="menu-appbar"
                sx={{mt: '34px'}}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {buildNavMenu()}
            </MenuUser>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="open login page"
            >
                <AuthPage pageName={pageName}
                          responseStatus={status}
                          setPageName={setPageName}
                          close={() => setOpenModal(false)}></AuthPage>
            </Modal>
        </div>
    )
}