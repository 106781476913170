import { FC, useEffect } from "react";
import { LikedOrPinnedProps } from "./LikedOrPinned.props";
import styles from './LikedOrPinned.module.scss';
import cn from "classnames";
import { useSelector } from "react-redux";
import { selectCurrentList } from '../../../Redux/selectors/adminSelector'
import { getCurrentItemList } from "../../../Redux/adminReducer";
import { Card } from "../../Main/SearchBlock/RowResults/Card/Card";

import { useAppDispatch } from "../../../Redux/store";
import { BrandItemType } from "../../../Redux/brandItemReducer";
import { NotContent } from "../../NotContent/NotContent";
import { useMediaQuery } from "@mui/material";


export const LikedOrPinned: FC<LikedOrPinnedProps> = ({className, dataType, ...restProps}) => {
    const isSmallWindow = useMediaQuery('(max-width:810px)');
    const dispatch = useAppDispatch()

    const itemList = useSelector(selectCurrentList(dataType))

    useEffect(()=> {
        dispatch(getCurrentItemList(dataType, {
            page: 1,
            perPage: 15
        }))
    }, [dataType])

    const title = dataType === "pins" ? 'Took note' : 'I Liked it'

    return(
        <div className={cn(styles.container, className)} {...restProps}>
            {isSmallWindow && <div className={styles.title}>{title}</div>}

            {itemList && itemList.length === 0 && <NotContent>Favorite objects are missing</NotContent>}
            {itemList && itemList.length > 0 && <div className={styles.cards}>
                {

                    itemList && itemList.map((card) => <Card
                        isAuth
                        className={styles.card}
                        //@ts-ignore
                        brandObject={card.brandItem as BrandItemType} />)
                }
            </div>}
        </div>
    )
}