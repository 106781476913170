import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./NewsLandingPage.module.scss";
import { format, isValid } from "date-fns";
import { url } from "../../Api/API";
import parse from "html-react-parser";
import { AppStateType, useAppDispatch } from "../../Redux/store";
import { getLandingBlogBySlug, InitialStateBlogType } from "../../Redux/blogReducer";
import { useSelector } from "react-redux";
import { BreadCrumbs } from "../../Elements/BreadCrumbs/BreadCrumbs";
import { BreadCrumbItem } from "../../Elements/BreadCrumbs/BreadCrumbs.props";
import { Helmet } from "react-helmet";
 const NewsLandingPage: FC = () => {
    const {blogSlug} = useParams<{ blogSlug: string }>();
    const dispatch = useAppDispatch();

    const {landingBlog} = useSelector<AppStateType, InitialStateBlogType>(state => state.blogReducer)

    useEffect(() => {
        blogSlug && dispatch(getLandingBlogBySlug({slug: blogSlug}))
    }, [])

    const breadCrumbsData: BreadCrumbItem[] = [
        {label: 'Blog', key: '/blog/?page=1&perPage=12&isActive=true'},
        {label: landingBlog?.title!, key: ''}
    ]

    return (
        <div className={cn(styles.blog, 'container')}>
            <Helmet>
                <title>{landingBlog?.title || 'Blog'} </title>
                <meta name="description" content=''/>
            </Helmet>
            {
                landingBlog && <div className={styles.wrapper}>
                    {/*<div className={styles.prev}*/}
                    {/*     onClick={onBack}>*/}
                    {/*    <ArrowIcon/>back*/}
                    {/*</div>*/}
                    <BreadCrumbs breadCrumbs={breadCrumbsData}/>
                    <div className={styles.date}>
                        {landingBlog.publicationAt && isValid(new Date(landingBlog.publicationAt)) && format(new Date(landingBlog.publicationAt), 'MMMM dd, yyyy')}
                    </div>
                    <div className={styles.title}>
                        {landingBlog.title}
                    </div>
                    <div className={styles.image}>
                        <img className={styles.img}
                             src={url + landingBlog.image}
                             alt=''/>
                    </div>
                    <div className={styles.content}>
                        {parse(landingBlog.body)}
                    </div>
                </div>
            }
        </div>
    )
}

export default NewsLandingPage;