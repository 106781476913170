import { instance } from './API'
import { CurrencyType } from "../Redux/currencieReducer";

export interface ResponseCurrencies {
    page: number;
    perPage: number;
    lastPage: number;
    totalCount: number;
    items: CurrencyType[]
}

export const currencieAPI = {
    getActiveCurrencies() {
        return instance.get('api/currencies')
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    getAllActiveCurrencies() {
        return instance.get<ResponseCurrencies>('api/currencies', {
            params: {
                isActive: true,
                perPage: 200,
                page: 1
            }
        })
    },
}