import React, { FC, useState } from "react";
import styled from "styled-components";

import { ButtonLocationPropsType } from "./types";

export const ButtonLocation: FC<ButtonLocationPropsType> = (props) => {

    const [active, setActive] = useState<boolean>(false)

    const handleOnClick = (): void => {
        setActive(!active)
        props.onClick && props.onClick()
        console.log(active)
    }

    return (
        <Button className="buttonlocation" onClick={handleOnClick}>
            <svg width="19" height="19" viewBox="0 0 19 19" fill={active ? '#797DF9' : '#666666'}
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.11108 12.7111C11.0993 12.7111 12.7111 11.0993 12.7111 9.11108C12.7111 7.12286 11.0993 5.51108 9.11108 5.51108C7.12286 5.51108 5.51108 7.12286 5.51108 9.11108C5.51108 11.0993 7.12286 12.7111 9.11108 12.7111Z" />
                <path
                    d="M10.0111 1.97318V0.111084H8.21108V1.97318C6.62735 2.1754 5.15556 2.89764 4.0266 4.0266C2.89764 5.15556 2.1754 6.62735 1.97318 8.21108H0.111084V10.0111H1.97318C2.1751 11.5949 2.89725 13.0669 4.02626 14.1959C5.15527 15.3249 6.62724 16.0471 8.21108 16.249V18.1111H10.0111V16.249C11.595 16.0472 13.067 15.3251 14.1961 14.1961C15.3251 13.067 16.0472 11.595 16.249 10.0111H18.1111V8.21108H16.249C16.0471 6.62724 15.3249 5.15527 14.1959 4.02626C13.0669 2.89725 11.5949 2.1751 10.0111 1.97318ZM9.11108 14.5111C6.13298 14.5111 3.71108 12.0892 3.71108 9.11108C3.71108 6.13298 6.13298 3.71108 9.11108 3.71108C12.0892 3.71108 14.5111 6.13298 14.5111 9.11108C14.5111 12.0892 12.0892 14.5111 9.11108 14.5111Z" />
            </svg>
        </Button>
    )

}

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 6.66667px 6.66667px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all .3s;

    &:hover {
        background-color: #797DF9;
        svg{
        fill: #ffffff
        }
    }
`
