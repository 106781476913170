import React, {FC} from "react";

import Icons from './../../assets/images/icons/IconsSprite.svg'
import {IconPropsType} from "./types";

const icons = {
    ['Other']: {fill: '#9B51E0'},
    ['Business Services']: {fill: '#797DF9'},
    ['Cafe']: {fill: '#BE682A'},
    ['Flowers']: {fill: '#27AE60'},
    ['Beauty & Cosmetics']: {fill: '#F2C94C'},
    ['Apparel']: {fill: '#C13280'},
    ['Food']: {fill: '#EB5757'},
    ['Shopping']: {fill: '#2F80ED'},
    ['ATM']: {fill: '#797DF9'},
}

// export type IconsForCategorysType = {
//     categoryName: string
//     id: number
//     image: 
// }

// const iconsForCategorys: IconsForCategorysType[] = [
//     {
//         categoryName: 'Food',
//         id: 142
//     }
// ]

export const Icon: FC<IconPropsType> = (props) => {
    const {height = 20, width = 20, name, isActive = false} = props;

    return (
        <svg width={width} height={height}>
            
            <use xlinkHref={`${Icons}#icon-${name}`} fill={
                // @ts-ignore
                !isActive ? '#797DF9' : `${icons[name].fill
                }`}/>
        </svg>
    )
}
