import React, {FC} from "react";
import styled from "styled-components";
import {ButtonCloseMenuPropsType} from "./types";

const colorsData = {
    red: '#f93e3e',
    blue: '#D2D6F5'
}

export const ButtonCloseMenu: FC<ButtonCloseMenuPropsType> = (props) => {
    const {size = 50, bgColor = 'blue', onClick, className} = props;

    const handleOnClick = () => onClick && onClick(false)

    return (
        <CloseButton type='button' onClick={handleOnClick} bgColor={colorsData[bgColor]} size={size} className={className}>
            <Icon width="10" height="10" viewBox="0 0 10 10"  fill={bgColor === 'red' ? 'white' : "#797DF9"} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.403811 0.403806C0.556184 0.251433 0.762848 0.16583 0.978336 0.16583C1.19382 0.16583 1.40049 0.251433 1.55286 0.403806L5.00001 3.85095L8.44715 0.403806C8.59952 0.251433 8.80619 0.16583 9.02168 0.16583C9.23716 0.16583 9.44383 0.251433 9.5962 0.403806C9.74857 0.556179 9.83418 0.762842 9.83418 0.97833C9.83418 1.19382 9.74857 1.40048 9.5962 1.55285L6.14905 5L9.5962 8.44715C9.74857 8.59952 9.83418 8.80618 9.83418 9.02167C9.83418 9.23716 9.74857 9.44382 9.5962 9.59619C9.44383 9.74857 9.23716 9.83417 9.02168 9.83417C8.80619 9.83417 8.59952 9.74857 8.44715 9.59619L5.00001 6.14905L1.55286 9.59619C1.40049 9.74857 1.19382 9.83417 0.978336 9.83417C0.762848 9.83417 0.556184 9.74857 0.403811 9.59619C0.251438 9.44382 0.165836 9.23716 0.165836 9.02167C0.165836 8.80618 0.251438 8.59952 0.403811 8.44715L3.85096 5L0.403811 1.55285C0.251438 1.40048 0.165836 1.19382 0.165836 0.97833C0.165836 0.762842 0.251438 0.556179 0.403811 0.403806V0.403806Z"
                />
            </Icon>
        </CloseButton>
    )
}

const CloseButton = styled.button<{ bgColor: string, size: number }>`
  width: ${({size}) => `${size}px`};
  height: ${({size}) => `${size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: ${({bgColor}) => bgColor};
  cursor: pointer;
`

const Icon = styled.svg`
`
