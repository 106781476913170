import React, { FC, useEffect, useState } from "react";
import styles from "../AuthPage.module.scss";
import user from "../../../../assets/images/icons/IconUser.svg";
import cn from "classnames";
import password from "../../../../assets/images/icons/IconPassword.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Color, SocialLink } from "../../../../newComponent";
import iconFacebook from "../../../../assets/images/icons/IconFB.svg";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline
} from "react-google-login";
import iconGoogle from "../../../../assets/images/icons/IconGoogle.svg";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { SocialLoginDataType } from "../AuthPage.props";
import { socialAuth } from "../../../../Redux/authReducer/authReduser";
import { useAppDispatch } from "../../../../Redux/store";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { DefaultAuthFieldsValue } from "../AuthPage";
import { StatusType } from "../../../../Redux/appReducer";

interface ForgotPasswordProps {
    register: UseFormRegister<Pick<DefaultAuthFieldsValue, 'email'>>;
    setPageName: (pageName: 'login' | 'register' | 'forgot') => void;
    errors: FieldErrors<Pick<DefaultAuthFieldsValue, 'email'>>;
}

export const ForgotPassword: FC<ForgotPasswordProps> = (props) => {
    const {register, setPageName, errors} = props;


    return <>
        <div className={styles.title}>Forgot password</div>
        <div className={styles['input-wrapper']}>
            <input
                style={{backgroundImage: `url(${user})`}}
                type="email"
                autoComplete='on'
                {...register("email", {required: true})}
                className={cn(styles.input)}/>
            {errors.email && <span className={styles['error-field']}>error</span>}
        </div>
        <div className={styles['buttons-block']}>
            <button
                type='submit'
                className={cn(styles.btn, styles['btn-submit'])}>
                Forgot password
            </button>
            <button type='button'
                    style={{textAlign: "left"}}
                    className={cn(styles.btn, styles['btn-login'], styles['btn-link'])}
                    onClick={() => setPageName('login')}>
                Go back
            </button>
        </div>


    </>
}