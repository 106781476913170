import { Dispatch } from 'redux'
import { authAPI, IChangeUserData, LoginDataType } from '../../Api/authApi'
import { addError, addSuccess } from '../messageReducer'
import { BaseThunkType, InferActionsTypes } from '../store'
import { UserType } from '../UserReducer'
import { StatusType } from "../appReducer";
import { SocialLoginDataType } from "../../layout/Header/AuthPage/AuthPage.props";
import { AuthStateType } from "./types";


let initialState: AuthStateType = {
    isAuth: false,
    status: 'idle'
}

type ActionsType = InferActionsTypes<typeof actions>

const authReducer = (state = initialState, action: ActionsType): AuthStateType => {
    switch (action.type) {
        case 'AUTH/SET_AUTH':
            return {
                ...state,
                isAuth: action.isAuth,
                userData: !action.isAuth ? undefined : state.userData,
            }

        case 'AUTH/SET_USER_DATA':
            return {
                ...state,
                userData: action.userData
            }

        case 'AUTH/UPDATE_USER_DATA':
            return {
                ...state,
                userData: {...state.userData, ...action.userData}
            }

        case 'AUTH/DELETE_USER_BRAND_ITEM':
            if ( !state.userData ) {
                return state
            }
            return {
                ...state, userData: {
                    ...state.userData,
                    brandItems: [...state.userData.brandItems.filter(item => item.id !== action.brandItemId)]
                }
            }

        case 'AUTH/SET_STATUS':
            return {
                ...state,
                status: action.status
            }
        default:
            return state;
    }
}

export const actions = {
    setAuth: (isAuth: boolean) => ({type: 'AUTH/SET_AUTH', isAuth} as const),
    setUserData: (userData: UserType) => ({
        type: 'AUTH/SET_USER_DATA',
        userData
    } as const),
    updateUserData: (userData: UserType) => ({
        type: 'AUTH/UPDATE_USER_DATA',
        userData
    } as const),
    deleteUserBrandItem: (brandItemId: number) => ({
        type: 'AUTH/DELETE_USER_BRAND_ITEM',
        brandItemId
    } as const),
    setStatus: (status: StatusType) => ({type: 'AUTH/SET_STATUS', status} as const),
}

export const setAuth = (status: boolean): ThunkType => {
    return async (dispatch) => {
        dispatch(actions.setAuth(status))
    }
}

export const setUserData = (userData: UserType): ThunkType => {
    return async (dispatch) => {
        dispatch(actions.setUserData(userData))
    }
}

export const checkAuth = (): ThunkType => {
    return async (dispatch) => {
        const response = await authAPI.checkAuth()
        if ( response?.status === 200 ) {
            dispatch(actions.setUserData(response.data.userData))
            dispatch(actions.setAuth(true))

        } else {
            dispatch(actions.setAuth(false))
            localStorage.removeItem('apiKey')
        }
    }
}

export const loginCheck = (data: LoginDataType): ThunkType => {
    return async (dispatch) => {
        const response = await authAPI.loginCheck(data)

        if ( response.status === 200 ) {
            localStorage.setItem('apikey', response.data.token);
            dispatch(actions.setAuth(true))
            dispatch(checkAuth())
            dispatch(actions.setStatus('success'))
        } else {
            dispatch(actions.setStatus('failed'))
            // dispatch( actions.setError(null) )
        }
    }
}

export const register = (data: LoginDataType): ThunkType => {
    return async (dispatch) => {
        const response = await authAPI.register(data)
        if ( response.status === 202 ) {
            dispatch(addSuccess('You have successfully registered'))
            dispatch(loginCheck(data))
            dispatch(actions.setAuth(true))
        } else {
            dispatch(addError(response.data.invalidParams[0].name + ': ' + response.data.invalidParams[0].reason))
        }
    }
}

export const socialAuth = (data: SocialLoginDataType): ThunkType => {
    return async (dispatch, getState) => {
        const response = await authAPI.socialAuth(data)

        if ( response.status === 200 ) {
            localStorage.setItem('apikey', response.data.token);
            dispatch(actions.setAuth(true))
            dispatch(addSuccess('Authorization was successful'))
            dispatch(checkAuth())

        } else {
            // console.log('addSnack');
            // dispatch( actions.setError(response.message) )
            // dispatch( actions.setError(null) )
        }
    }
}

export const changeUserData = (data: IChangeUserData): ThunkType => {
    return async (dispatch) => {
        dispatch(actions.setStatus('loading'))
        try {
            const response = await authAPI.changeUserData(data)
            dispatch(actions.updateUserData(response.data))
            dispatch(addSuccess('The data was successfully updated'))
            dispatch(actions.setStatus('success'))
        } catch {
            dispatch(addError('Something went wrong, try again'))
            dispatch(actions.setStatus('failed'))
        }
    }
}


export default authReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>
