import React, { useEffect } from 'react'
import { message } from "antd";
import { useSelector } from 'react-redux';
import { AppStateType } from '../Redux/store';
import { MessageInitialStateType } from '../Redux/messageReducer';
import { AuthStateType } from '../Redux/authReducer/types';
import { refreshToken } from '../Api/API';


const MessageComponent:React.FC = () => {
    const {isAuth} = useSelector<AppStateType, AuthStateType>(state => state.authReducer)
    const { error, success } = useSelector<AppStateType, MessageInitialStateType>(state => state.messageReducer)

    const sendSuccessMessage = (value: string) => {
        message.success({
            content: value,
            style: {
                zIndex: 1000,
                position: 'relative',
                top: '15vh',
            },
            onClick: () => {
                message.destroy()
            },
        });
    }

    const sendErrorMessage = (value: string) => {
        message.error({
            content: value,
            style: {
                zIndex: 1000,
                position: 'relative',
                top: '15vh',
            },
            onClick: () => {
                message.destroy()
            },
        });
    }

    useEffect(() => {
        refreshToken()
    }, [isAuth]);

    useEffect(() => {
        success[0] && sendSuccessMessage(success[success.length-1])
    }, [success]);

    useEffect(() => {
        error[0] && sendErrorMessage(error[error.length-1])
    }, [error]);

    return (
        <></>
    )
}

export default  MessageComponent