import React, { Suspense } from 'react'
import { Provider } from 'react-redux';
import RouterComponent from './routers/Router';
import store from './Redux/store';
import { Spinner } from "./newComponent";
import 'swiper/css/pagination';
import 'swiper/swiper.min.css';
import MessageComponent from './routers/MessageComponent';


function App() {
    return (
        <Provider store={store}>
            <Suspense fallback={<Spinner/>}>
                <MessageComponent/>
                <RouterComponent/>
            </Suspense>
        </Provider>
    );
}

export default App;
