import React from "react";
import {connect} from 'react-redux'
import { calculateRoute, changeTravelMode } from '../../../Redux/mapReducer'
import { AppStateType } from '../../../Redux/store'
import GoogleMapComponent from './GoogleMap'
import {Dispatch, SetStateAction} from "react";
import {DataForShareLinkType} from "../MapPage";

type MapPropsType = ReturnType<typeof mapStateToProps>

type MapDispatchPropsType = {
    calculateRoute: (start: google.maps.LatLngLiteral, stop: google.maps.LatLngLiteral, routeTargetName?: string | undefined) => void
    changeTravelMode: (travelMode: google.maps.TravelMode) => void
}

type OwnGoogleMapComponentPropsType = {
    center: google.maps.LatLngLiteral
    myCoords: google.maps.LatLngLiteral
    setMyCoords: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | undefined>>
    openWindowForShareLink: (dataForShareLink: DataForShareLinkType) => void
    onContextMenu: (event?: React.MouseEvent & React.TouchEvent, LatLng?: google.maps.LatLng) => void
    onHideMenu: () => void
    mapType?: google.maps.MapTypeId
    openObjectId?: number
    setOpenObjectId: React.Dispatch<React.SetStateAction<number | undefined>>
    zoom?: number
    setZoom: Dispatch<SetStateAction<number | undefined>>
    setIsNavigateMode: React.Dispatch<React.SetStateAction<boolean>>
    isNavigateMode: boolean
    selectedCategory: number[]
}

export type GoogleMapComponentPropsType = MapPropsType & MapDispatchPropsType & OwnGoogleMapComponentPropsType

let mapStateToProps = (state: AppStateType) => {
    console.log('clustersList: ',state.brandItemReducer.clustersList)
    console.log('brendItemList: ',state.brandItemReducer.brandItemList)
    return {
        // Старая логика
        // brendObjectList: getBrandObjectList(state),

        // Новая логика
        brendItemList: state.brandItemReducer.brandItemList,
        clustersList: state.brandItemReducer.clustersList,

        atmList: state.brendObjectReducer.atmList,
        routes: state.mapReducer.routes,
        isInfoWindowDataLoad: state.brandItemReducer.isInfoWindowDataLoad
    }
}

export default connect<MapPropsType, MapDispatchPropsType, OwnGoogleMapComponentPropsType, AppStateType>(mapStateToProps,
    {  calculateRoute, changeTravelMode }
)
    (GoogleMapComponent)
