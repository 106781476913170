import { Dispatch } from 'redux'
import { userAPI } from '../Api/userApi'
import { RolesType } from './authReducer/types'
import { BrandItemType } from './brandItemReducer'
import { BaseThunkType, InferActionsTypes } from './store'

export type OauthType = 'Password' | 'Facebook' | 'Google'

export type UserFieldsType = 'id' | 'email' | 'fullName'

export type UserType = {
    username: string;
    id: number
    photoFile: {
        id: number;
        filename: string
    } | null
    email: string
    oauthType: OauthType[]
    roles: RolesType[]
    brandItems: BrandItemType[]
    isActive: boolean
    phone: string
    pins: {brandItemId: number, id: number}[]
    likes: {brandItemId: number, id: number}[]
}

export type InitialStateType ={
    userList?: UserType[]
    totalCount?: number
}

let initialState:InitialStateType = {

}

type ActionsType = InferActionsTypes<typeof actions>

const userReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'USER/SET_USER_LIST':
            return {
                    ...state,
                    userList: action.userList,
                    totalCount: action.totalCount
            }
        default:
            return state;
    }
}

export const actions = {
    setUserList: (userList: UserType[], totalCount: number) => ({type: 'USER/SET_USER_LIST', userList, totalCount} as const),
}

export const getUserList = (queryParams: any): ThunkType => {
    return async (dispatch) => {
        const response = await userAPI.getUsersByFilrter(queryParams)
        if (response.status === 200) {
            dispatch( actions.setUserList(response.data.items, response.data.totalCount) )
        }
    }
}

export default userReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>