import { Button, Modal, Table } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { actions, BrandItemType, deleteBrandItem } from '../../../Redux/brandItemReducer'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { selectAllCategoryesList } from '../../../Redux/selectors/categorySelectors'
import { getAllCategories } from '../../../Redux/categoryReducer'
import { getCurrentItemList } from '../../../Redux/adminReducer'
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import { url } from "../../../Api/API";
import { ColumnsType } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";
import { checkAuth } from "../../../Redux/authReducer/authReduser";
import { AuthStateType } from "../../../Redux/authReducer/types";

const {confirm} = Modal

interface ActionsProps {
    showConfirm: (id: number) => void;
    id: number;
}

export const Actions: FC<ActionsProps> = (props) => {
    const {showConfirm, id} = props;
    const navigate = useNavigate()

    return <ButtonsContainer>
        <Button
            type='default'
            size='small'
            shape='round'
            onClick={() => {
                navigate('/account/my-object/edit/' + id)
            }}
        >
            <EditIcon style={{width: 15}}/>
        </Button>
        <Button
            type='default'
            size='small'
            danger
            shape='round'
            onClick={() => showConfirm(id)}
        >
            <DeleteIcon style={{width: 15}}/>
        </Button>
    </ButtonsContainer>
}

const MyObjects: FC = () => {
    const dispatch = useAppDispatch()
    const {userData} = useSelector<AppStateType, AuthStateType>(state => state.authReducer)

    const categoryList = useSelector(selectAllCategoryesList)

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    let brandItemList: BrandItemType[] | undefined = []
    let totalCount: number = 0

    if ( userData?.brandItems ) {
        brandItemList = userData.brandItems
        totalCount = userData.brandItems.length
    }

    const onPaginationChange = (newPage: number, newPageSize: number) => {
        newPage !== page && setPage(newPage)
        newPageSize !== pageSize && setPageSize(newPageSize)
        dispatch(getCurrentItemList(
            'brand_items',
            {
                perPage: newPageSize,
                page: newPage
            },
            actions.setBrendItemsByFilter
        ))
    }

    const showConfirm = (id: number) => {
        confirm({
            title: 'Do you Want to delete ' + id + ' Brand Item?',
            icon: <ErrorOutlineOutlinedIcon/>,
            onOk() {
                dispatch(deleteBrandItem(id))
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    //TODO разобраться с логикой = убрать лишнее
    useEffect(() => {

        // TODO убрать - костыль!!!
        dispatch(checkAuth())

        !categoryList && dispatch(getAllCategories())
    }, []);

    const columns: ColumnsType<{}> = [
        {
            title: 'Logo',
            dataIndex: 'logoFileName',
            key: 'logoFileName',
            width: '70px',
            render: (path: string) => {
                return <img src={url + path}
                            style={{height: 30, width: 60, objectFit: 'contain'}}
                            alt='name of brand'/>
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '1fr',
        },
        {
            title: 'Active',
            dataIndex: ['isActive'],
            key: 'isActive',
            width: '50px',
            responsive: ['sm'],
            render: (isActive: boolean) => {
                return <div style={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <span style={{
                        width: 15,
                        height: 15,
                        borderRadius: '50%',
                        backgroundColor: isActive ? 'green' : 'red'
                    }}></span>
                </div>
            }
        },
        {
            title: 'Advertising',
            dataIndex: ['isAdvertising'],
            key: 'isAdvertising',
            width: '50px',
            responsive: ['sm'],
            render: (isAdvertising: boolean) => {
                return <div style={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <span style={{
                        width: 15,
                        height: 15,
                        borderRadius: '50%',
                        backgroundColor: isAdvertising ? 'green' : 'red'
                    }}></span>
                </div>
            }
        },
        {
            title: 'Category',
            dataIndex: ['category', 'title'],
            key: 'category',
            width: '150px',
            responsive: ['sm']
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'actions',
            width: '110px',
            render: (id: number,) => {
                return (
                    <Actions id={id}
                             showConfirm={showConfirm}
                    />
                )
            }
        },
    ]


    return (
        <Container>
            <Title>Brand Items</Title>
            <Table
                loading={false}
                columns={columns}
                rowKey='id'
                dataSource={brandItemList}
                pagination={{
                    position: ['bottomRight'],
                    pageSize: pageSize,
                    total: totalCount,
                    onChange: onPaginationChange,
                    hideOnSinglePage: true
                }}
                size='small'
            />
        </Container>
    )
}

export default MyObjects


const Container = styled.div`

`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 106%;
  color: #343434;
  text-align: center;
  margin-bottom: 40px;
`

const AddBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`