import styled from "styled-components";
import {Col} from "antd";

export const SelectionButtonsList = styled.ul<{ width: number, dragging: boolean }>`
  left: 110px;
  display: flex;
  overflow-x: auto;
  position: absolute;
  top: 17px;
  width: 1130px;
  max-width: calc(${({width}) => `${width - 144}px`});
  line-height: 16px;
  cursor: ${({dragging})=> dragging ? 'grabbing':'grab'};

  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-moz-scrollbar {
    display: none ;
  }

  @media (max-width: 1280px) {
    left: 120px;
  }
  @media (max-width: 1230px) {
    left: 30px;
    max-width: calc(${({width}) => `${width - 124}px`});
  }
  @media (max-width: 810px) {
    position: initial;
    max-width: 100%;
  }
`

export const ButtonOpenMenu = styled(Col)`
  height: 60px;
`

export const ButtonOpenMenuIcon = styled.img<{ isOpenMenu: boolean }>`
  display: none;
  width: 13px;
  height: 13px;
  margin: 17px 20px 0 30px;
  cursor: pointer;
  transition: all .3s;
  transform: rotate(${({isOpenMenu}) => isOpenMenu ? '45deg' : '0deg'});
  @media (max-width: 810px) {
    display: block;
  }
`


export const SelectionButtonsItem = styled.div`
  margin-right: 10px;
  

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1200px) {
    &:last-child {
      margin-right: 80px;
    }
  }

  @media (max-width: 1100px) {
    margin-right: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 810px) {
    margin-right: 10px;
  }
`

export const LayoutMobileMenu = styled.div<{ isOpenMenu: boolean }>`
  display: ${({isOpenMenu}) => isOpenMenu ? 'block' : 'none'};
  position: absolute;
  top: 140px;
  width: 100%;
  height: calc(100vh - 140px);
  z-index: 510;
`


