import { Dispatch } from 'redux'
import { GetBrendItemByFilrterParams } from '../Api/brandItemApi';
import { brendObjectAPI, CreateBrendObjectParamsType,  GetBrendObjectsByBoundsParamsType, GetNearestBrendObjectsParamsType } from '../Api/brendObjectApi'
import { BrendItemFullType, PhoneType } from './brandItemReducer';
import { BaseThunkType, InferActionsTypes } from './store'

type LastDateUpdatedType = {
    date: string; //"2022-04-21 17:54:17.000000"
    timezone: string; // "Europe/Moscow"
    timezone_type: number; // 3
}

type CategoryType = {
    id: number
    logoFileName: string
    markerFileName: string
    title: string
}

export type BrandInfoType = {
    averageGrade: number | null // средняя оценка заведения
    category: CategoryType
    currencies: any // приходит {} - типизировать
    description: string | null;
    head: any // null типизировать
    id: number
    images: any // {} типизировать
    isActive: boolean // ???
    isOffline: boolean
    isOnline: boolean
    isTagsCategory: boolean
    lastDateUpdate: LastDateUpdatedType
    link: string
    logoFileName: any // null
    logoSource: any //null
    metaDescription: string //"Korai Kitchen"
    metaTitle: string //"Korai Kitchen"
    notifications: any //{}
    partnerProgram: any //null
    promotions: any //{}
    reviews: any //{}
    slug: string //"korai-kitchen"
    subcategories: any //{}
    tags: any //{}
    title: string //"Korai Kitchen"
}

export type AddressFieldsType = 'latitude' | 'longitude' | 'data' | 'state' | 'country' | 'city' | 'isViewMap' | 'apartment' 
    | 'building' | 'street' 

export type AddressType = {
    id?: number
    latitude: string
    longitude: string
    data?: string //"United States, New Jersey, Summit Avenue   , 576"
    state?: {
        id: number
        title: string
        isActive?: boolean
    }
    country: {
        id: number
        title: string
        countryCode?: string
    }
    //TODO  сейчас city нет
    city: {
        id: number
        title: string
        country?: number
    }
    cityName?: string
    isViewMap: boolean
    apartment?: string
    building?: string
    street?: string
}



export type BrandObjectType = {
    dist?: number,
    address: AddressType
    id: number
    additionalInformation?: string | null
    phoneNumbers: PhoneType[]
    isActive?: boolean
    workingSchedules: any // {} типизировать
    hasDelivery?: boolean
    typeObject?: string //??? посмотреть все типы
    tasks?: any // {} типизировать
    routeUser?: any // null или ...
    tags?: any // {} типизировать
    isLike?: boolean
    isPin?: boolean
    brandInfo?: BrandInfoType
    // for ATM:
    title: string
    slug: string
}

export type AtmType = {
    address: AddressType
    id: number
    // lat: number
    // lng: number
    phoneNumbers: PhoneType[]
    title: string
    slug: string
    workingSchedules: any
}

export type BoundType = {
    lngMin: number
    lngMax: number
    latMin: number
    latMax: number
}

export type BrandObjectInitialStateType ={
    brendObjectList: BrandObjectType[]
    brendObjectMenuList: BrendItemFullType[]
    atmList: BrandObjectType[]
    // atmList: AtmType[]
    // atmMenuList: AtmType[]
    atmMenuList: BrandObjectType[]
    lastBounds: BoundType | null
    brandObjectData?: BrandObjectType
}

let initialState:BrandObjectInitialStateType = {
    brendObjectList: [],
    brendObjectMenuList: [],
    atmList: [],
    atmMenuList: [],
    lastBounds: null,
}

type ActionsType = InferActionsTypes<typeof actions>

const brendObjectReducer = (state = initialState, action: ActionsType): BrandObjectInitialStateType => {
    switch (action.type) {
        case 'BREND_OBJECT/SET_BREND_OBJECTS':
            return {
                    ...state,
                    brendObjectList: action.brendObjectList,
                    atmList: action.atm
            }

        case 'BREND_OBJECT/SET_BREND_OBJECTS_MENU_LIST':
            console.log('BREND_OBJECT/SET_BREND_OBJECTS_MENU_LIST', action.brendObjectList)
            return {
                ...state,
                brendObjectMenuList: action.brendObjectList,
                atmMenuList: action.atm
            }

        case 'BREND_OBJECT/SET_LAST_BOUNDS':
            return {
                ...state,
                lastBounds: action.bound
            }

        case 'BREND_OBJECT/SET_BRAND_OBJECT_DATA':
            return{
                ...state,
                brandObjectData: action.brandObjectData
            }
        default:
            return state;
    }
}

export const actions = {
    setBrendObjects: (brendObjectList: BrandObjectType[], atm: BrandObjectType[]) => ({type: 'BREND_OBJECT/SET_BREND_OBJECTS', brendObjectList, atm} as const),
    setBrendObjectsMenuList: (brendObjectList: any[], atm: AtmType[]) => ({type: 'BREND_OBJECT/SET_BREND_OBJECTS_MENU_LIST', brendObjectList, atm} as const),
    setLastBounds: (bound: BoundType) => ({type: 'BREND_OBJECT/SET_LAST_BOUNDS', bound} as const),
    setBrandObjectData: (brandObjectData: BrandObjectType) => ({type: 'BREND_OBJECT/SET_BRAND_OBJECT_DATA', brandObjectData} as const),
}

export const getBrandObjectById = (id: number): ThunkType => {
    return async (dispatch, getState) => {
        const response = await brendObjectAPI.getBrendObjectById(id)
        if (response.status === 200) {
            dispatch( actions.setBrandObjectData(response.data.brandObject) )
        }
    }
}

export const getBrendObjectByFilrter = (params: GetBrendItemByFilrterParams): ThunkType => {
    return async (dispatch, getState) => {
        const response = await brendObjectAPI.getBrendObjectByFilrter(params)
        console.log(response)
        if (response.status === 200) {
            dispatch( actions.setBrendObjects(response.data.brandObjects, []) )
        }
    }
}

export const getBrendObjectsByBounds = (params?: GetBrendObjectsByBoundsParamsType): ThunkType => {
    return async (dispatch, getState) => {
        const categoryFilter = getState().categoryReducer.categoryFilter
        const trustedCatygoryes = getState().categoryReducer.trustedCatygoryes

        const filter = categoryFilter && categoryFilter.length !==0 ? categoryFilter.filter( item => trustedCatygoryes?.includes(item)) : trustedCatygoryes

        const lastBounds = getState().brendObjectReducer.lastBounds

        if (params) {
            dispatch( actions.setLastBounds({
                latMax: params.latMax,
                latMin: params.latMin,
                lngMax: params.lngMax,
                lngMin: params.lngMin,
            }) )
            const response = await brendObjectAPI.getBrendObjectsByBounds(params, filter && filter.length !== 0 ? filter : categoryFilter)
            if (response.status === 200) {
                dispatch( actions.setBrendObjects(response.data.brandObjects, response.data.atm) )
            }
        } else if (lastBounds) {
            
            const response = await brendObjectAPI.getBrendObjectsByBounds(lastBounds as BoundType, filter && filter.length !== 0 ? filter : categoryFilter)
            if (response.status === 200) {
                dispatch( actions.setBrendObjects(response.data.brandObjects, response.data.atm) )
            }
        }
        
    }
}

export const getNearestBrendObjects = (params:GetNearestBrendObjectsParamsType): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setBrendObjectsMenuList([], []) )
        const response = await brendObjectAPI.getNearestBrendObjects(params)
        if (response.status === 200) {
            console.log('getNearestBrendObjects', response)
            dispatch( actions.setBrendObjectsMenuList(
                response.data.brandItems ? response.data.brandItems : [], 
                []
            ) )
        }
    }
}

// createBrendObject
export const createBrendObject = (params:CreateBrendObjectParamsType): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setBrendObjectsMenuList([], []) )
        const response = await brendObjectAPI.createBrendObject(params)
        // if (response.status === 200) {
        //     console.log('getNearestBrendObjects', response)
        //     dispatch( actions.setBrendObjectsMenuList(response.data.brandObjects, response.data.atm) )
        // }
    }
}

export default brendObjectReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>
