import { Pagination } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentItemList, QueryParamsInterface } from '../../Redux/adminReducer'
import { actions } from '../../Redux/currencieReducer'
import {
    selectCurrencyList,
    selectCurrencyListCount
} from '../../Redux/selectors/currencieSelector'
import { QueryParamsType } from '../../Utils/UniversalTableView/UniversalTableView'
import Exchange from './Exchange/Exchange'
import { CoinCard } from "./CoinCard/CoinCard";
import styles from './Currencies.module.scss'
import cn from "classnames";
import { useWindowSizeOld } from "../../hooks/useWindowWidthOld";
import { useAppDispatch } from "../../Redux/store";
import { Helmet } from "react-helmet";
import { getCurrencyNamesAsString } from "../../helpers/getCurrencyNamesAsString";


const Currencies: FC = () => {
    const dispatch = useAppDispatch()
    const currencyList = useSelector(selectCurrencyList)
    const totalCount = useSelector(selectCurrencyListCount)
    const [params, setParams] = useState<QueryParamsType>({
        page: 1,
        perPage: 15
    })

    const [width] = useWindowSizeOld()

    useEffect(() => {
        dispatch(getCurrentItemList(
            'currencies',
            params as QueryParamsInterface,
            actions.setCurrencieList
        ))
    }, [params]);

    const buildCardList = () => {
        return currencyList?.map(coin => {
            return (
                <CoinCard coin={coin} key={coin.id}/>
            )
        })
    }

    useEffect(() => {
        const noFollow = Array.from(document.querySelectorAll("[rel='nofollow']"))
        noFollow.forEach( item => item.removeAttribute('rel'))
    })
    
    return (
        <div className={cn(styles.currencies, 'container')}>
            <Helmet>
                <title>Exchange</title>
                <meta name="description" content={`Current exchange rates for: ${getCurrencyNamesAsString(currencyList)}`}/>
            </Helmet>
            <div className={styles.title}>Exchange</div>
            <Exchange currencyList={currencyList} className={styles.exchangeBox}/>
            <div className={styles.title}>Exchange rates against the United States dollar</div>
            <div className={styles.coins_list}>
                {buildCardList()}
            </div>
            <Pagination
                className={styles.pagination}
                onChange={(page, pageSize) => {
                    setParams({page, perPage: pageSize})
                    if(width > 810){
                        window.scrollTo({top: 195, behavior: "smooth"})
                    } else {
                        window.scrollTo({top: 255, behavior: "smooth"})
                    }

                }}
                defaultCurrent={1}
                current={params.page}
                pageSize={params.perPage}
                pageSizeOptions={[15, 20, 25, 30, 35]}
                total={totalCount}
                responsive
            />
        </div>
    )
}

//export default WithLayout(Currencies)
export default Currencies