import React, { FC } from "react";
import cn from "classnames";
import styles from "./NavMenu.module.scss";
import { NavLink } from "react-router-dom";
import { NavMenuProps } from "./NavMenu.props";

interface MenuDataType {
    id: number;
    title: string;
    navigateTo: string;
    roles?: string[]
    disabled?: boolean;
}

const menuData: MenuDataType[] = [
    {id: 1, title: 'Map', navigateTo: '/map'},
    {id: 2, title: 'Catalog', navigateTo: '/catalog'},
    {id: 3, title: 'Currencies', navigateTo: '/currencies'},
    {id: 4, title: 'Blog', navigateTo: '/blog'},
    {id: 5, title: 'About', navigateTo: '/about-us'},
    // {
    //     id: 6, title: 'Account', navigateTo: '/account',
    //     roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_PREMIUM_USER', 'ROLE_CLIENT', 'ROLE_DEFAULT'],
    //     disabled: true
    // },
    // {
    //     id: 7, title: 'Admin', navigateTo: '/admin',
    //     roles: ['ROLE_SUPER_ADMIN'],
    //     disabled: true
    // }
]


export const NavMenu: FC<NavMenuProps> = ({direction = 'horizontal', className, onClickItem, ...restProps}) => {

    const buildMenu = () => {
        return menuData.map(({id, title, navigateTo}) => {
            return (
                <li key={id}
                    onClick={onClickItem}
                    className={cn( {
                    [styles['item-horizontal']]: direction === 'horizontal',
                    [styles['item-vertical']]: direction === 'vertical',
                })}>
                    <NavLink to={navigateTo}
                             style={({isActive}) =>
                                 isActive ? activeStyle : {}}
                             className={cn(styles.link)}>{title}</NavLink>
                </li>
            )
        })
    }

    const activeStyle = {backgroundColor: "#4041FB", color: 'white'}
    return (<nav>
        <ul className={cn(styles.list, className, {
            [styles.vertical]: direction === 'vertical'
        })} {...restProps}>
            {buildMenu()}
        </ul>
    </nav>)
}