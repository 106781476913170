import React, {FC, useState} from "react";
import styled from "styled-components";

import iconStreet from '../../../assets/images/icons/icon-street-mob.png'
import iconSatellite from '../../../assets/images/icons/icon-satellite-mob.png'
import {ButtonSatelliteForMobilePropsType} from "./types";

export const ButtonSatelliteForMobile: FC<ButtonSatelliteForMobilePropsType> = (props) => {
    const { onClick } = props;

    const [isSatellite, setIsSatellite] = useState<Boolean>(false)

    const handleOnClick = () => {
        onClick && onClick()
        setIsSatellite(!isSatellite)
    }

    return (
        <Button onClick={handleOnClick}>
            <Icon src={isSatellite ? iconStreet : iconSatellite} />
        </Button>
    )
}

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: drop-shadow(0px 6.70909px 6.70909px rgba(0, 0, 0, 0.25));
  cursor: pointer;
`
const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  
`
