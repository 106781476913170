import { UploadFile } from 'antd/lib/upload/interface'
import { Dispatch } from 'redux'
import { blogAPI } from '../Api/blogApi'
import { uploadAPI } from '../Api/uploadApi'
import { BaseThunkType, InferActionsTypes } from './store'

export type BlogFieldsPropsType = 'id' | 'title' | 'bodyShort' | 'image'

export type BlogType = {
    id?: number
    title: string
    slug?: string
    bodyShort: string
    body: string
    image?: string
    imageObjects?: UploadFile[]
    dateCreate?: {
        date: string
        timezone_type: number
        timezone: string
    }
    publicationAt?: Date
}

export type InitialStateBlogType = {
    blogList?: BlogType[],
    landingBlog?: BlogType
}

let initialState: InitialStateBlogType = {}

type ActionsType = InferActionsTypes<typeof actions>

const blogReducer = (state = initialState, action: ActionsType): InitialStateBlogType => {
    switch (action.type) {
        case 'BLOG/GET_BLOG_LIST':
            return {
                ...state,
                blogList: action.blogList
            }
            case 'BLOG/GET_LANDING_BLOG':
            return {
                ...state,
                landingBlog: action.blog
            }
        default:
            return state;
    }
}

export const actions = {
    setBlogList: (blogList: BlogType[]) => ({
        type: 'BLOG/GET_BLOG_LIST',
        blogList
    } as const),
    setLandingBlog: (blog: BlogType) => ({type: 'BLOG/GET_LANDING_BLOG', blog} as const),
}

export const getBlogList = (): ThunkType => {
    return async (dispatch, getState) => {
        const response = await blogAPI.getBlogList()
        if ( response?.status === 200 ) {
            dispatch(actions.setBlogList(response.data.items))
        }
    }
}


export const updateBlog = (blog: BlogType, id: number, image?: UploadFile): ThunkType => {
    return async (dispatch, getState) => {
        if ( image ) {
            const response = await uploadAPI.uploadFile(image.originFileObj)
            blog.image = response.data.fileName
        }
        dispatch(updateBlogNext(blog, id))
    }
}

export const updateBlogNext = (blog: BlogType, id: number): ThunkType => {
    return async (dispatch, getState) => {
        const response = await blogAPI.updateBlog(blog, id)
        if ( response.status === 200 ) {
            blog.image = response.data.fileName
            dispatch(actions.setBlogList(response.data.blogs))
        }
    }
}

export const createBlog = (blog: BlogType, image: UploadFile): ThunkType => {
    return async (dispatch, getState) => {
        const response = await uploadAPI.uploadFile(image.originFileObj)
        if ( response.status === 200 ) {
            blog.image = response.data.fileName
            dispatch(createBlogNext(blog))
        }
    }
}

export const createBlogNext = (blog: BlogType): ThunkType => {
    return async (dispatch, getState) => {
        const response = await blogAPI.createBlog(blog)
        if ( response.status === 200 ) {
            blog.image = response.data.fileName
            dispatch(actions.setBlogList(response.data.blogs))
        }
    }
}

export const deleteBlog = (id: number): ThunkType => {
    return async (dispatch, getState) => {
        const response = await blogAPI.deleteBlog(id)
        if ( response.status === 200 ) {
            dispatch(actions.setBlogList(response.data.blogs))
        }
    }
}

export const getLandingBlogBySlug = (payload: {slug: string}): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await blogAPI.getBlogBySlug(payload)
            dispatch(actions.setLandingBlog(response.data))
            console.log('BLOG-REDUCER', response.data)
        } catch (e) {

        }
    }
}

export default blogReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>