import React, {FC, useState} from "react";

import styled from "styled-components";

import iconRoute from './../../../assets/images/icons/icon-route.svg'
import {ButtonNavigationPropsType} from "./types";


export const ButtonNavigation: FC<ButtonNavigationPropsType> = (props) => {
    const { onClick, isSelected = false } = props;
    const [active, setActive] = useState<boolean>(false);


    const handleOnClick = (): void => {
        onClick && onClick()
    };

    const handleOnMouseDown = (): void => setActive(true);

    const handleOnMouseUp = (): void => setActive(false);

    return (
        <Container>
            <Button active={active}
                    isSelected={isSelected}
                    onClick={handleOnClick}
                    onMouseUp={handleOnMouseUp}
                    onMouseDown={handleOnMouseDown} >
                <Img src={iconRoute} alt="route"/>
            </Button>
        </Container>

    )
}

const Container = styled.div`
  width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  
`

const Button = styled.button<{active: boolean, isSelected: boolean}>`
  width: 36px;
  height: 36px;
  transform: rotate(45deg);
  border: none;  
  outline: none;
  background-color: #797DF9;
  border-radius: 9px;  
  filter: ${({active, isSelected}) => active || isSelected ? 'none' : 'drop-shadow(0px 6.00843px 6.00843px rgba(0, 0, 0, 0.25))'};
  cursor: pointer;
  transition: all .3s;

  &:hover{
    background-color: #0057FF;
  }  
`
const Img = styled.img`
  transform: rotate(-45deg);
  margin: 0 auto;
`
