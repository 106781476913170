import { instance, prepearFilterParams, url } from './API'
import * as queryString from 'query-string'
import { QueryParamsType } from '../Utils/UniversalTableView/UniversalTableView';
// import { QueryParamsType } from '../Redux/adminReducer';

export interface ObjectInterface {
    id: number;
}

interface GetResultsInterface {
    items: ObjectInterface[];
    limit: number;
    offset: number;
    totalCount: number;
}

interface GetDataInterface {
    data: GetResultsInterface;
}

type ObjectType = {
    [key: string]: any
}

const mappedArray = [
    'category', 'address', 'owner', 'currencies', 'phoneNumbers', 'logoFile', 'imageFiles', 'additionalImageFiles', 'tags', 
    'imageFile'
]

const objectsToId = (object: ObjectType) => {
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            // ??? нужен ли этот if ???
            // if (mappedArray.includes(key)) {
                if(object[key]) {
                    if (object[key].id) {
                        object[key] = object[key].id
                    } else if (Array.isArray(object[key])) {
                        object[key].forEach( (item: any, index: number) => {
                            if (item.id) {
                                object[key][index] = item.id
                            }
                        })
                    }
                } else {
                    if (typeof object[key] !== 'boolean') {
                        delete object[key]
                    } 
                }
            // }
            if (typeof object[key] !== 'boolean' && !object[key]) {
                delete object[key]
            }
        }
    }
    return object
}

export const adminAPI = {
    getAllItemsByQuery(field: string, queryParams: QueryParamsType) {
        // queryParams.allActive = true

        let stringified: string = prepearFilterParams(queryParams, 'api');
        // stringified = stringified.replaceAll('categories=', 'categories[]=')

        return instance.get<GetDataInterface>('/api/' + field + stringified)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    createItem(field: string, item: any) {
        return instance.post('/api/' + field, item)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    updateItem(field: string, id: number, item: any) {
        console.log('updateCurrentItem item', {...item})
        const mappedItem = objectsToId(item)
        console.log('updateCurrentItem mappedItem', {...mappedItem})
        return instance.patch('/api/' + field + '/' + id, mappedItem)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    deleteItem(field: string, id: number) {
        return instance.delete('/api/' + field + '/' + id)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },
}