import React, { FC, useState } from "react";
import { Logo } from "../../newComponent";
import styles from './Header.module.scss';
import cn from 'classnames'
import { Drawer, useMediaQuery } from "@mui/material";
import { ReactComponent as IconMenu } from "../../assets/images/icons/icon-menu.svg";
import { NavMenu } from "./NavMenu/NavMenu";
import { UserProfile } from "./UserProfile/UserProfile";
import { MobileNavMenu } from "./MobileNavMenu/MobileNavMenu";


export const Header: FC = () => {
    const isSmallWindow = useMediaQuery('(max-width:810px)');
    const [openMenu, setOpenMenu] = useState(false)

    return (
        <header>
            <div className={cn('container', styles.wrapper)}>
                <Logo navigateTo='/'
                      size='responsive'/>
                {!isSmallWindow ?
                    <NavMenu/>
                    :
                    <Drawer anchor='left' open={openMenu} >
                        <MobileNavMenu closeMenu={() => setOpenMenu(false)} />
                    </Drawer>
                }
                <UserProfile />
                {isSmallWindow && <button
                    className={styles['btn-mobile-menu']}
                    onClick={() => setOpenMenu(true)}>
                    <IconMenu/>
                </button>}
            </div>
        </header>
    )
}