import { Image, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import {  useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { url } from '../../../Api/API'
import {
    actions,
    BrandItemType,
} from '../../../Redux/brandItemReducer'
import CatalogItemLocation from './CatalogItemLocation/CatalogItemLocation'
import styles from './CatalogItemBI.module.scss'
import { ReactComponent as Arrow } from "../../../assets/images/Arrow 10.svg";
import noImageIcon
    from './../../../Components/Main/SearchBlock/RowResults/Card/noImage.png'
import { getCurrentItemList } from '../../../Redux/adminReducer'
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import parse from "html-react-parser";
import { Spinner } from "../../../newComponent";
import { BreadCrumbs } from "../../../Elements/BreadCrumbs/BreadCrumbs";
import { BreadCrumbItem } from "../../../Elements/BreadCrumbs/BreadCrumbs.props";
import { Helmet } from "react-helmet";
import { getCurrencyNamesAsString } from "../../../helpers/getCurrencyNamesAsString";


const CatalogItemBI: React.FC = () => {
    const dispatch = useAppDispatch()
    const {id} = useParams()

    const brandItemsList = useSelector<AppStateType, BrandItemType[] | undefined>(state => state.brandItemReducer.brandItemListByFilter)
    const [target, setTarget] = useState<BrandItemType | undefined>(brandItemsList?.find(i => i.id == id))
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        setTarget(brandItemsList?.find(i => i.slug == id))
    }, [brandItemsList])

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    }, [])

    useEffect(() => {
        !target && dispatch(getCurrentItemList(
            'brand_items_slug',
            {
                slug: id,
                perPage: 1,
                page: 1,
                id: id
            },
            actions.setBrendItemsByFilter,
            true
        ))
    }, [])

    const onTabClick = (activeKey: string, e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
    }

    if ( !target ) {
        return <Spinner/>
    }

    const breadCrumbsData: BreadCrumbItem[] = [
        {label: 'Catalog', key: '/catalog/?page=1&perPage=12&isActive=true'},
        {label: target.category.title, key: '/catalog/?page=1&perPage=12&isActive=true&categories[]=' + target.category.id},
        {label: target.title, key: ''}]

    return (
        <div className={styles.catalog}>
            <Helmet>
                <title>{target.title} pay with cryptocurrency: {getCurrencyNamesAsString(target?.currencies)} </title>
                <meta name="description" content={`Pay with cryptocurrency for ${target.title} services or products. Find the closest place to buy or spend
your crypto currency anywhere in the world.`} />
            </Helmet>
            <BreadCrumbs breadCrumbs={breadCrumbsData}/>
            <h1 className={styles.title}>{target.title}</h1>
            <div className={styles.inner}>
                <div className={styles.logo}>
                    <img
                        src={target.logoFileName ? url + target.logoFileName : noImageIcon}
                        alt=''/>
                </div>
                <div className={styles.infoBlock}>
                    {
                        target && <>
                            <div
                                className={styles.text}>{target.brand && target.brand.title}</div>
                            <div className={styles.text}>{target.category.title}</div>
                            <div className={styles.text}>
                                {target.address.building &&
                                    <span>{target.address.building + ', '}</span>}
                                {target.address.street &&
                                    <span>{target.address.street + ', '}</span>}
                                {target.address.cityName &&
                                    <span>{target.address.cityName + ', '}</span>}
                                {/*{target.address.city &&*/}
                                {/*    <span>{target.address.city.title + ', '}</span>}*/}
                                {/*<span>{target.address?.state + ', '}</span>*/}
                                {target.address.country &&
                                    <span>{target.address.country.title}</span>}
                            </div>

                            {target.phoneNumbers && target.phoneNumbers[0] &&
                                <div className={styles.text}>
                                    <a href={'tel:' + target.phoneNumbers[0].number}>
                                        {target.phoneNumbers[0].number}
                                    </a>
                                </div>
                            }
                            {/*<Text>*/}
                            {/*    <a href="https://mySite.com" target='_blank'>*/}
                            {/*        https://mySite.com*/}
                            {/*    </a>*/}
                            {/*</Text>*/}
                            <div className={styles.coins}>
                                {target.currencies?.map(c => <span>
                                <img src={url + c.logoFileName} alt=""/>
                            </span>)}
                            </div>
                        </>
                    }
                </div>
            </div>
            {target.fullDescription && <div className={styles.description}>
                {parse(target.fullDescription)}
            </div>}
            <Tabs
                defaultActiveKey='1'
                onTabClick={onTabClick}
                size="large"
                centered
            >

                {target.images && target.images.length > 0 &&
                    <Tabs.TabPane tab="Photos" key="1" className={styles.slider}>
                        {/*<Carousel autoplay*/}
                        {/*          arrows*/}
                        {/*          swipe*/}
                        {/*          autoplaySpeed={3000}*/}
                        {/*          dots={{className: styles.dot}}*/}
                        {/*          nextArrow={<ArrowNext/>}*/}
                        {/*          prevArrow={<ArrowPrev/>}*/}
                        {/*          effect="fade">*/}
                        {/*    {target.images.map(i => {*/}
                        {/*        return (*/}
                        {/*            <div className={styles.sliderImage}>*/}
                        {/*                <Image src={url + i} />*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</Carousel>*/}
                        {/*<div className={styles.sliderImage}>*/}
                        {/*    */}
                        {/*</div>*/}
                        <Image preview={{visible: false}}
                               className={styles.sliderImage}
                               onClick={() => setVisible(true)}
                               src={url + target.images[0]}/>
                        <div style={{display: 'none'}}>
                            <Image.PreviewGroup preview={{
                                visible,
                                onVisibleChange: (vis) => setVisible(vis)
                            }}>
                                {target.images.map(i => <Image
                                    style={{display: "inline-block"}} src={url + i}
                                    key={i}/>)}
                            </Image.PreviewGroup>
                        </div>
                    </Tabs.TabPane>
                }

                <Tabs.TabPane tab="Location" key="2">
                    <CatalogItemLocation
                        target={target}/>
                </Tabs.TabPane>
                {target.additionalImages && target.additionalImages.length && target.additionalImages[0] &&
                    <Tabs.TabPane tab="Additional Images" key="4"
                                  style={{textAlign: 'center'}}>
                        <Image className={styles.additionalImg}
                               src={url + target.additionalImages[0]}
                               alt=''
                        />
                    </Tabs.TabPane>
                }
            </Tabs>
        </div>
    )
}

export default CatalogItemBI


const ArrowNext = styled(Arrow)`
  width: 70px !important;
  z-index: 1 !important;
  color: #2a2929 !important;
  right: 0 !important;
  transition: all 0.3s;

  &:hover {
    fill: #4041FB;
  }
`

const ArrowPrev = styled(Arrow)`
  width: 70px !important;
  z-index: 1 !important;
  color: #2a2929 !important;
  transform: rotate(180deg);
  left: 0 !important;
  transition: all 0.3s;

  &:hover {
    fill: #4041FB;
  }
`