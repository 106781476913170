import { FC } from "react";
import { useRouteError } from "react-router-dom";
import styles from './ErrorPage.module.scss'

export const ErrorPage: FC = (): JSX.Element => {
    const error = useRouteError() as Error;

    return (
        <div className={styles.errorPage}>
            <h1 className={styles.title}>Oops!</h1>
            <p className={styles.text}>Sorry, an unexpected error has occurred.</p>
            <p className={styles.text}>
                <i className={styles.error}>{error.message || JSON.stringify(error)}</i>
            </p>
        </div>
    );
}

