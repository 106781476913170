import React, { useEffect, useState } from 'react'

import { BrandListPropsType } from './BrandListContainer'
import { BrendItemFullType } from '../../../Redux/brandItemReducer';

import { ClientCard } from "../../../Elements/testComponents/ClientCard/ClientCard";

import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from "swiper";
import { DataForShareLinkType } from "../MapPage";

import styles from './BrandList.module.scss'
import cn from "classnames";


const BrandList: React.FC<BrandListPropsType> = (props) => {
    const [lastSelectedCategories, setLastSelectedCategories] = useState<number[]>([])

    const createRoute = () => {
        props.setSetselectedCategory([])
    }

    const addToFavorites = (item: BrendItemFullType) => {
        props.setCenter({
            lat: item.latitude,
            lng: item.longitude
        })
        props.setZoomValue(18)
    }

    const shareSocialNetwork = (dataForShareLink: DataForShareLinkType) => {
        props.openWindowForShareLink(dataForShareLink)
    }

    useEffect(() => {
        setLastSelectedCategories(props.selectedCategory)
        if (
            props.brendObjectMenuList &&
            props.brendObjectMenuList.length === 0 &&
            JSON.stringify(lastSelectedCategories) === JSON.stringify(props.selectedCategory)
        ) {
            const categories = props.selectedCategory.map(item => {
                console.log(props.categoryesList?.filter(i => i.id === item)[0])
                return props.categoryesList?.filter(i => i.id === item)[0] ?
                    props.categoryesList?.filter(i => i.id === item)[0].title : 'ATM'
            })


            props.addSuccess('There are no ' + categories.join(', ') + ' within a radius of 100 kilometers')
        }
    }, [props.brendObjectMenuList]);

    useEffect(() => {
        document.querySelector('.gm-svpc')?.setAttribute('hidden', 'true')
    }, []);

    if ( isMobile ) {
        return <div className={cn(styles.slider, styles.slider_native)}>
            {
                !props.selectedCategory.includes(0) &&
                props.brendObjectMenuList && props.brendObjectMenuList.map(item => {
                    return (
                        <ClientCard
                            key={`${item.id}-product-info-slider`}
                            addToFavorites={() => addToFavorites(item)}
                            createRoute={createRoute}
                            // @ts-ignore
                            shareSocialNetwork={shareSocialNetwork}
                            isAuth={props.isAuth}
                            brendObject={item}
                        />
                    )
                })
            }
        </div>
    }

    return (
        <div className={cn(styles.slider, styles.swiper)}>
            <Swiper
                    watchOverflow
                    updateOnWindowResize
                    breakpoints={{
                        1023: {
                            slidesPerView: 3.2,
                        },
                        1150: {
                            slidesPerView: 3.5,
                        },
                        1200: {
                            slidesPerView: 3.8,
                        },
                        1250: {
                            slidesPerView: 4,
                        },
                        1366: {
                            slidesPerView: 4.2,
                        }
                    }}
                    spaceBetween={20}
                    freeMode
                    modules={[FreeMode]}

            >
                {
                    !props.selectedCategory.includes(0) &&
                    props.brendObjectMenuList && props.brendObjectMenuList.map(item => {
                        return (
                            <SwiperSlide key={`${item.id}-product-info-slider`}
                                         style={{height: '100%'}}>
                                <ClientCard
                                    addToFavorites={() => addToFavorites(item)}
                                    createRoute={createRoute}
                                    // @ts-ignore
                                    shareSocialNetwork={shareSocialNetwork}
                                    isAuth={props.isAuth}
                                    brendObject={item}
                                />
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>
        </div>


    )
}


export default BrandList
