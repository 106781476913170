import { Dispatch } from 'redux'
import { refreshToken } from '../Api/API'
import { categoryAPI } from '../Api/categoryApi'
import { uploadAPI } from '../Api/uploadApi'
import { getBrendObjectsByBounds } from './brendObjectReducer'
import { BaseThunkType, InferActionsTypes } from './store'
import { UploadFilesType } from './uploadReducer'

export type CategoryFieldsPropsType =  'id' | 'title' | 'logoFileName' | 'markerFileName' | 'metaTitle' | 'metaDescription' | 'metaKeywords'

export type CategoryType = {
    id?: number
    title: string
    logoFileName?: string
    markerFileName: string
    metaTitle?: string
    metaDescription?: string
    metaKeywords?: string
    isActive?: boolean
}

export type CategoryInitialStateType ={
    categoryesList?: CategoryType[]
    allCategoryesList?: CategoryType[]
    categoryFilter: number[] | null
    trustedCatygoryes?: number[]
}

let initialState:CategoryInitialStateType = {
    categoryFilter: null
}

type ActionsType = InferActionsTypes<typeof actions>

const categoryReducer = (state = initialState, action: ActionsType): CategoryInitialStateType => {
    switch (action.type) {
        case 'CATEGORY/SET_CATEGORYES':
            return {
                    ...state,
                    categoryesList: action.categoryesList
            }

        case 'CATEGORY/SET_ALL_CATEGORYES':
            return {
                    ...state,
                    allCategoryesList: action.categoryesList
            }

        case 'CATEGORY/SET_CATEGORY_FILTER':
            return {
                ...state,
                categoryFilter: action.categoryFilter
            }

        case 'CATEGORY/SET_TRUSTED_CATIGORYES':
            return {
                ...state,
                trustedCatygoryes: action.trustedCatygoryes
            }

        default:
            return state;
    }
}

export const actions = {
    setCategoyes: (categoryesList: CategoryType[]) => ({type: 'CATEGORY/SET_CATEGORYES', categoryesList} as const),
    setAllCategoyes: (categoryesList: CategoryType[]) => ({type: 'CATEGORY/SET_ALL_CATEGORYES', categoryesList} as const),
    setCategoryFilter: (categoryFilter: number[] | null) => ({type: 'CATEGORY/SET_CATEGORY_FILTER', categoryFilter} as const),
    setTrustedCatygory: (trustedCatygoryes: number[] | undefined) => ({type: 'CATEGORY/SET_TRUSTED_CATIGORYES', trustedCatygoryes} as const)
}

export const createCatygory = (category: CategoryType, uploadFiles?: UploadFilesType): ThunkType => {
    return async (dispatch, getState) => {
        if (uploadFiles) {
            let newCategory = {...category}

            for (const key in uploadFiles) {
                if (Object.prototype.hasOwnProperty.call(uploadFiles, key)) {
                    const prop: CategoryFieldsPropsType = key as CategoryFieldsPropsType
                    const fileObject = uploadFiles[prop];
                    const response = await uploadAPI.uploadFile(fileObject.file.originFileObj)
                    if (response.status === 200 ) {
                        for (const key1 in category) {
                            if (Object.prototype.hasOwnProperty.call(category, key1)) {
                                if (key1 === key) {
                                    newCategory = {
                                        ...newCategory,
                                        [key1]: response.data.fileName
                                    }
                                }
                            }
                        }
                    }
                }
            }
            dispatch(createCatygory(newCategory))
        } else {
            const response = await categoryAPI.createCatygory(category)
            if (response.status === 200) {
                dispatch( actions.setAllCategoyes(response.data.items) )
            }
        }
    }
}

export const updateCatygory = (category: CategoryType, uploadFiles?: UploadFilesType): ThunkType => {
    return async (dispatch) => {
        if (uploadFiles) {
            let newCategory = {...category}

            for (const key in uploadFiles) {
                if (Object.prototype.hasOwnProperty.call(uploadFiles, key)) {
                    const prop: CategoryFieldsPropsType = key as CategoryFieldsPropsType
                    const fileObject = uploadFiles[prop];
                    if (fileObject.file.originFileObj) {
                        const response = await uploadAPI.uploadFile(fileObject.file.originFileObj)
                        if (response.status === 200 ) {
                            for (const key1 in category) {
                                if (Object.prototype.hasOwnProperty.call(category, key1)) {
                                    if (key1 === key) {
                                        newCategory = {
                                            ...newCategory,
                                            [key1]: response.data.fileName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            dispatch(updateCatygory(category))
        } else {
            if (category.id) {
                const response = await categoryAPI.updateCategories(category, category.id)
                if (response.status === 200) {
                    dispatch( actions.setAllCategoyes(response.data.items) )
                }
            } 
        }
    }
}

export const changeTrustedCatygory = (trustedCatygoryes?: number[]): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setTrustedCatygory(trustedCatygoryes) )
        dispatch(getBrendObjectsByBounds())
    }
}

export const changeCategoryFilter = (categoryFilter: number[] | null): ThunkType => {
    return async (dispatch, getState) => {
        dispatch( actions.setCategoryFilter(categoryFilter) )
        dispatch(getBrendObjectsByBounds())
    }
}

export const getActiveCategoryes = (): ThunkType => {
    return async (dispatch, getState) => {
        const response = await categoryAPI.getActiveCategoryes()
        if (response?.status === 200) {
            dispatch( actions.setCategoyes(response.data.items) )
        } else {
            localStorage.removeItem('apikey')
            refreshToken()
            // dispatch(getActiveCategoryes())
        }
    }
}

export const getAllCategories = (): ThunkType => {
    return async (dispatch, getState) => {
        const response = await categoryAPI.getAllCategories()
        if (response.status === 200) {
            // dispatch( actions.setAllCategoyes(response.data.items ? response.data.items : response.data.categories) )
            dispatch( actions.setAllCategoyes(response.data.items) )
        }
    }
}

export const deleteCategory = (id: number): ThunkType => {
    return async (dispatch, getState) => {
        const response = await categoryAPI.deleteCategory(id)
        if (response.status === 200) {
            dispatch( actions.setAllCategoyes(response.data.items) )
        }
    }
}
export default categoryReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>