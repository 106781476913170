import { Dispatch } from 'redux'
import { currencieAPI, ResponseCurrencies } from '../Api/currencieApi'
import { BaseThunkType, InferActionsTypes } from './store'

export type CurrencyType = {
    id: number
    title: string
    symbol: string
    price: number
    fullPrice?: number
    logoFileName: string
    position: number
    urlSite: string
    isActive: boolean

    marketCap?: string;
    percentChange24h?: string;
    useForPayment?: boolean;
    cmcRank?: number;
}

export type CurrencyInitialStateType ={
    currencyList?: CurrencyType[]
    currencyListCount?: number,

    page?: number,
    perPage?: number,
    totalCount?: number,
}

let initialState:CurrencyInitialStateType = {

}

type ActionsType = InferActionsTypes<typeof actions>

const currencieReducer = (state = initialState, action: ActionsType): CurrencyInitialStateType => {
    switch (action.type) {
        case 'CURRENCIES/TEST':
            return {
                    ...state,
                    currencyList: action.currencyList.sort((a, b) => a.position < b.position ? -1 : 1),
                    currencyListCount: action.currencyListCount
            }
        case 'CURRENCIES/SET_CURRENCIES_LIST':
            return {
                ...state,
                currencyList: action.payload.items,
                perPage: action.payload.perPage,
                page: action.payload.page,
                totalCount: action.payload.totalCount
            }
        default:
            return state;
    }
}

export const actions = {
    setCurrencieList: (currencyList: CurrencyType[], currencyListCount: number) => ({type: 'CURRENCIES/TEST', currencyList, currencyListCount} as const),
    setCurrenciesList: (payload: ResponseCurrencies) => ({type: 'CURRENCIES/SET_CURRENCIES_LIST', payload} as const),
}

export const getActiveCurrencies = (): ThunkType => {
    return async (dispatch) => {
        const response = await currencieAPI.getActiveCurrencies()
        if (response?.status === 200) {
            dispatch( actions.setCurrencieList(response.data.items, response.data.totalCount) )
        }
    }
}

export const getAllActiveCurrencies = (): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await currencieAPI.getAllActiveCurrencies()
            dispatch(actions.setCurrenciesList(response.data))
        } catch (e) {

        }
    }
}

export default currencieReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>