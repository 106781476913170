import React, { FC, useEffect, useState } from "react";
import styles from './UserInfo.module.scss'
import { Avatar, Button, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import { changeUserData } from "../../../Redux/authReducer/authReduser";
import { AuthStateType } from "../../../Redux/authReducer/types";
import { url } from "../../../Api/API";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { useForm } from "react-hook-form";
import { addError, addSuccess } from "../../../Redux/messageReducer";
import { authAPI } from "../../../Api/authApi";


interface EditableTextFieldProps {
    isEditable?: boolean;
    textFieldProps?: TextFieldProps;
    value?: string;
    label: string;
}

const EditableTextField: FC<EditableTextFieldProps> = (props) => {
    const {isEditable, textFieldProps, value, label} = props;

    return <>
        {isEditable ? <TextField label={label}
                                 value={value}
                                 fullWidth  {...textFieldProps} />
            :
            <div className={styles.textField}>
                <span className={styles.label}>{label}</span>
                {value}
            </div>}
    </>
}

export const UserInfo: FC = () => {
    const dispatch = useAppDispatch()
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const {register, setValue, watch} = useForm()
    const [phone, username, password] = watch(['phone', 'username', 'oldPassword', 'password'])
    const {userData, status} = useSelector<AppStateType, AuthStateType>(state => state.authReducer)

    const updateUserInfo = () => {
        if(userData){
            dispatch(changeUserData({id: userData.id, username, phone: '+' + phone }))
        }
    }

    const updatePassword = async () => {

        if ( password.length < 8 ) {
            return dispatch(addError('The password must be at least 8 characters long'))
        }
        try {
            await authAPI.changePassword({password})
            dispatch(addSuccess('The data was successfully updated'))
            setIsEditable(false)

        } catch {
            dispatch(addError('Something went wrong, try again'))
        }
        setValue('password', '')
        setValue('oldPassword', '')
    }

    useEffect(() => {
        setValue('phone', userData?.phone)
        setValue('username', userData?.username)

    }, [userData])

    useEffect(() => {
        if(status === 'success'){
            setIsEditable(false)
        }
    }, [status])

    return (<div className={styles.userInfo} style={status === "loading" ? {opacity: '0.5', pointerEvents: 'none'} : {}}>
        <button className={styles['edit-button']}
                onClick={() => setIsEditable(!isEditable)}>+
        </button>


        <div className={styles.photoBlock}>
            <Avatar sx={{width: 150, height: 150}}
                    variant='square'
                    src={url + userData?.photoFile?.filename}/>
        </div>
        <div>
            <div className={styles.fieldset}>
                <div>Personal information</div>
                <EditableTextField isEditable={isEditable}
                                   textFieldProps={{...register('username'), autoFocus: true}}
                                   label='Full Name'
                                   value={username}/>
                <EditableTextField isEditable={false}
                                   label='Email'
                                   value={userData?.email}/>
                <PhoneInput
                    inputProps={{
                        required: true,
                        autoFocus: true,
                        style: {maxWidth: 300, width: '100%'},
                    }}
                    country={'us'}
                    prefix='+'
                    value={phone}
                    disabled={!isEditable}
                    onChange={phone => setValue('phone', phone)}
                />
                {
                    isEditable && <>
                        <Button variant='outlined' onClick={updateUserInfo}>Save</Button>
                    </>
                }
            </div>
            {isEditable && <div className={styles.fieldset}>
                <legend>Change Password</legend>
                <TextField label='Old password'
                           type='password'
                           fullWidth
                           {...register('oldPassword')}
                           tabIndex={1}/>
                <TextField label='New Password'
                           type='password'
                           fullWidth
                           {...register('password')}
                           tabIndex={1}/>
                <Button variant='outlined' onClick={updatePassword}>Save Password</Button>
            </div>}
        </div>

    </div>)
}