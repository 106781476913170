import React, { FC, useState } from "react";
import styles from './CardLarge.module.scss'
import cn from "classnames";
import noImageIcon from './noImage.png'
import { url } from "../../../../../Api/API";
import { Rating } from "../../../../../Elements/Rating/Rating";
import { useNavigate } from "react-router-dom";
import { CardLargeProps } from "./CardLarge.props";
import { Button } from "../../../../../Elements/common/Button/Button";
import { ReactComponent as PinIcon } from "../../../../../assets/images/icons/pin.svg";
import { ReactComponent as LikeIcon } from "../../../../../assets/images/icons/like.svg";
import { ReactComponent as BitcoinIcon } from './../../../../../assets/images/icons/bitcoin.svg'
import styled from "styled-components";


export const CardLarge: FC<CardLargeProps> = (props) => {
    const {className, brandObject, ...restProps} = props;
    const {title, logoFileName, id, markerFileName, address, phoneNumbers, category, averageGrade } = brandObject;
    const [isActiveFavoriteBtn, setIsActiveFavoriteBtn] = useState<{pin: boolean, like: boolean}>({ pin: false, like: false})

    const navigate = useNavigate()
    console.log('BrandObjectForCard', brandObject)


    const logo =  logoFileName ? url + logoFileName : noImageIcon
    const markerIcon = url + markerFileName;
    const coinCount = brandObject.currencies && brandObject.currencies.length > 3 ? brandObject.currencies.length - 3 : undefined

    return(
        <div className={cn(styles.cardLarge, className)} {...restProps}>
            <div className={styles.gridContainer}>
                <div className={styles.logoBlock}>
                    <img className={styles.logo} src={logo} alt={title}/>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.address}>
                        {address && address.data || 'online'}
                    </div>
                    <div>
                        {props.brandObject.phoneNumbers && phoneNumbers && phoneNumbers.length > 0 
                            ? <a href={'tel:' + phoneNumbers[0].number}>{phoneNumbers[0].number}</a> 
                            : 'No Phone'//'+1 208 444 34 20'
                        }
                    </div>
                </div>
                <div className={cn(styles.actionBlock)}>
                    <button> <span/></button>
                    <button onClick={() => navigate('catalog/' + id)}>
                        <span/>
                    </button>
                </div>
                <div className={cn(styles.favoriteBlock)}>
                    <Button appearance='transparent'
                            onClick={() => {setIsActiveFavoriteBtn({...isActiveFavoriteBtn, pin: !isActiveFavoriteBtn.pin})}}
                            className={cn(styles.favoriteIcon, {
                                [styles.activeIcon]: isActiveFavoriteBtn.pin
                            })} >
                        <PinIcon />
                    </Button>
                    <Button appearance='transparent'
                            onClick={() => {setIsActiveFavoriteBtn({...isActiveFavoriteBtn, like: !isActiveFavoriteBtn.like})}}
                            className={cn(styles.favoriteIcon, {
                                [styles.activeIcon]: isActiveFavoriteBtn.like
                            })}
                    >
                        <LikeIcon/>
                    </Button>
                </div>
            </div>
            <div className={styles.category}>
                <img className={styles.categoryIcon} src={markerIcon} alt={title}/>
                {category.title}
            </div>
            <div className={cn(styles.icons)}>
                {props.brandObject.currencies?.length === 0 ? <div className={styles.icon}><BitcoinIcon/></div> :
                    props.brandObject.currencies?.slice(0, 3).map(({id, title, logoFileName}) => {
                        return (<div className={styles.icon} key={id}>
                            <img src={url + logoFileName} alt={title}/>
                        </div>)
                    })}

                {
                    coinCount && <div className={styles.icon}>
                        <EmptyCryptaIcon>+{coinCount}</EmptyCryptaIcon>
                    </div>
                }
            </div>
            <Rating className={styles.rating} rating={ averageGrade && averageGrade > 0 ? averageGrade : 4} />
        </div>
    )
}

const EmptyCryptaIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  border-radius: 50%;
  background-color: #FFFFFF;
  color: #4041FB;
  border: 1px solid #4041FB;
  height: 23px;
  width: 23px;
`