import { AutocompleteOptionsType } from '../../Elements/common/Autocomplete/Autocomplete';
import { BrandItemType, ClusterType, InfoWindowDataType } from '../brandItemReducer';
import { AppStateType } from '../store';
import { createSelector } from 'reselect'

export const selectBrendItemListByFilter = (state: AppStateType): BrandItemType[] | undefined => state.brandItemReducer.brandItemListByFilter;
export const selectTotalCount = (state: AppStateType): number | undefined => state.brandItemReducer.totalCount;

export const brandItemList = (state: AppStateType): BrandItemType[] => state.brandItemReducer.brandItemList;
export const selectBrendItemList = createSelector(brandItemList, items =>  {
    return items 
})

export const selectClustersList = (state: AppStateType): ClusterType[] => state.brandItemReducer.clustersList;
// export const clustersList = (state: AppStateType): ClusterType[] => state.brandItemReducer.clustersList;
// export const selectClustersList = createSelector(clustersList, items =>  {
//     return items 
// })

export const selectInfoWindowData = (state: AppStateType): InfoWindowDataType | undefined => {
    return state.brandItemReducer.infoWindowData
}
// export const selectIsInfoWindowDataLoad = (state: AppStateType): boolean => state.brandItemReducer.isInfoWindowDataLoad;

export const selectBrendItemListByOwner = (state: AppStateType): BrandItemType[] | undefined => state.authReducer.userData?.brandItems;

export const selectBrandItemsByQuery = (state: AppStateType): AutocompleteOptionsType[] => state.brandItemReducer.brandItemsByQuery
