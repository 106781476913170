export const mapTypeStyle: google.maps.MapTypeStyle[] = [
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{visibility: "off"}]
  },
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [{visibility: "off"}]
  },
  {
    featureType: "administrative.country",
    elementType: "all",
    stylers: [{visibility: "on"}]
  },
  {
    featureType: "administrative.locality",
    elementType: "all",
    stylers: [{visibility: "on"}]
  },
]

