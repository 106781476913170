import React from "react";
import { connect } from 'react-redux'
import { AppStateType } from '../../../Redux/store'
import { addSuccess } from '../../../Redux/messageReducer'
import BrandList from './BrandList'
import { DataForShareLinkType } from "../MapPage";

type MapPropsType = ReturnType<typeof mapStateToProps>

type MapDispatchPropsType = {
    addSuccess: (data: string) => void
}

type OwnBrandListPropsType = {
    setSetselectedCategory: React.Dispatch<React.SetStateAction<number[]>>
    openWindowForShareLink: (dataForShareLink: DataForShareLinkType) => void
    selectedCategory: number[]
    setZoomValue: React.Dispatch<React.SetStateAction<number>>
    setCenter: React.Dispatch<React.SetStateAction<google.maps.LatLngLiteral | undefined>>
}

export type BrandListPropsType = MapPropsType & MapDispatchPropsType & OwnBrandListPropsType

let mapStateToProps = (state: AppStateType) => {
    const brendObjectMenuList = state.brendObjectReducer.brendObjectMenuList

    return {
        brendObjectMenuList: brendObjectMenuList,
        atmMenuList: state.brendObjectReducer.atmMenuList,
        myCoordinates: state.mapReducer.myCoordinates,
        categoryesList: state.categoryReducer.categoryesList,
        isAuth: state.authReducer.isAuth
    }
}

export default connect<MapPropsType, MapDispatchPropsType, OwnBrandListPropsType, AppStateType>(mapStateToProps,
    { addSuccess }
)
    (BrandList)
