import {Col} from 'antd'
import React, {RefObject, useEffect, useState} from 'react'
import styled from 'styled-components'

import {
    ButtonLayers,
    ButtonLocation,
    ButtonSatellite,
    ButtonSelectProduct
} from '../../../newComponent'
import {MobileMapMenu} from '../../../newComponent/MobileMapMenu/MobileMapMenu'
import {CategoryType} from '../../../Redux/categoryReducer'
import {MapMenuNewPropsType} from './MapMenuNewContainer'
import {IconNameType} from "../../../newComponent/Icon/types";

import {
    LayoutMobileMenu,
    SelectionButtonsItem,
    SelectionButtonsList
} from './MapMenuComponents'
import {useWindowSizeOld as useWindowSize} from "../../../hooks/useWindowWidthOld";
import useDrag, {UseDragType} from "../../../hooks/useDrag";

export type HeaderMenuDataType = CategoryType & {
    isChacked: boolean
    isSelected: boolean
}

const MapMenuNew: React.FC<MapMenuNewPropsType> = (props) => {
    const {onMouseMove, onMouseUp, onMouseDown, scrollContainer, dragging}: UseDragType = useDrag(0.3)

    const [width, height ] = useWindowSize()
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
    const [headerMenuData, setHeaderMenuData] = useState<HeaderMenuDataType[]>()
    const [myCoords, setMyCoords] = useState<google.maps.LatLngLiteral>()


    useEffect(() => {
        myCoords && props.setMyCoordinates(myCoords)
    }, [myCoords]);

    useEffect(() => {
        getLocation()
    }, []);

    const getLocation = () => {
        if (!navigator.geolocation) {
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setMyCoords({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            }, (error) => {
                //TODO message for user
            });
        }
    }

    useEffect(() => {
        props.getActiveCategoryes()
    }, [])

    useEffect(() => {
        if (!headerMenuData && props.categoryesList) {
            const newHeaderMenuData: HeaderMenuDataType[] = props.categoryesList.map((category) => {
                const item: HeaderMenuDataType = {...category, isChacked: true, isSelected: false}
                return item
            })
            setHeaderMenuData(newHeaderMenuData)
        }
    }, [props.categoryesList]);

    useEffect(() => {
        setHeaderMenuData(headerMenuData?.map( (item) => {
            if (props.selectedCategory.includes(item.id as number)) {
                return {
                    ...item,
                    isSelected: true
                }
            } else {
                return {
                    ...item,
                    isSelected: false
                }
            }

        }))
    }, [props.selectedCategory]);

    const openMenu = () => {
        setIsOpenMenu(!isOpenMenu)
    }


    const selectProduct = (id: any, isSelected: boolean) => {
        if(dragging){
            return false
        }
        const newCategoryArray = [...props.selectedCategory]

        if (!props.selectedCategory.includes(id)) {
            newCategoryArray.push(id)
            props.setSetselectedCategory(newCategoryArray)
        } else {
            props.setSetselectedCategory(newCategoryArray.filter(item =>item !== id))
        }

        
        const selected = headerMenuData?.filter(i=>i.isSelected)
        let selectedIds = selected ? selected.map(i=>i.id) : []
        console.log('selectedIds', selectedIds)
        if (selectedIds.includes(id)) {
            selectedIds = selectedIds.filter(i => i !== id)
        } else {
            selectedIds.push(id)
        }

        props.getNearestBrendObjects({
            lat: props.myCoordinates?.lat as number,
            lng: props.myCoordinates?.lng as number,
            'categories[]': selectedIds as number[]
        })
    }

    const changeMapLayer = (type: google.maps.MapTypeId): void => {
        props.changeMapType(type)
    }

    const identifyUserLocation = (): void => {
        props.setLocations()
    }

    const selectAllProducts = () => {

    }

    return (
        <>
            <Wrapper  hidden={props.isHidden} className='Wrapper m-3'>
                <Col span={25}>
                    <SelectionButtonsList
                        className='SelectionButtonsList'
                        onMouseUp={onMouseUp}
                        onMouseDown={onMouseDown}
                        onMouseMove={onMouseMove}
                        ref={scrollContainer as RefObject<HTMLUListElement>}
                        width={width}
                        dragging={dragging}>
                        {headerMenuData?.filter(item => item.isChacked).map(category => {
                            return (
                                <SelectionButtonsItem
                                    className='m-1'
                                    key={`${category.id}-map-menu-new`}
                                >
                                    <ButtonSelectProduct
                                        categoryId={category.id ? category.id: 0}
                                        onClick={selectProduct}
                                        isSelected={category.isSelected}
                                        title={category.title}
                                        iconId={category.title as IconNameType}
                                        dragging={dragging}
                                    />
                                </SelectionButtonsItem>
                            )
                        })}
                    </SelectionButtonsList>

                </Col>
                {props.selectedCategory.length === 0 &&
                    <LocationButtonsList>
                        <LocationButtonsItem>
                            <ButtonLocation active onClick={identifyUserLocation}/>
                        </LocationButtonsItem>
                        {/* <LocationButtonsItem>
                            <ButtonGroupForZooming setZoomValue={props.setZoomValue}/>
                        </LocationButtonsItem> */}
                    </LocationButtonsList>
                }


                {props.selectedCategory.length === 0 &&
                    <WrapperButtonSatellite height={height}>
                        {
                            width <= 600 ?
                                <ButtonLayers onClick={changeMapLayer}/>
                                :
                                <ButtonSatellite onClick={changeMapLayer}/>
                        }
                    </WrapperButtonSatellite>
                }

            </Wrapper>

            {headerMenuData
                ?
                <LayoutMobileMenu
                    isOpenMenu={isOpenMenu}
                >
                    <MobileMapMenu
                        productsMenuData={headerMenuData as HeaderMenuDataType[]}
                        onChange={selectProduct}
                        changeHeaderMenuData={setHeaderMenuData}
                        onClickAll={selectAllProducts}
                    />
                </LayoutMobileMenu>
                :
                null
            }
        </>
    )
}
//TODO в css hidden нет - это display-none
const Wrapper = styled.div<{hidden: boolean}>`

    position: absolute;
    z-index: 500;
    max-width: 1466px;
    //hidden: ${(props) => `${props.hidden}`};
    display: ${(props) => props.hidden ? 'none' : 'block'};

    @media (max-width: 810px) {
    //padding: 19px 0 13px;
    padding-top: 6px;
    top: 49px;
    width: 100%;
    background-color: #ffffff;
}
`

const LocationButtonsList = styled.ul`
  position: absolute;  
  left: 1296px;
  max-height: 200px;
  bottom: calc(220px - 100vh);
  z-index: 500;
  
  @media (max-width: 1366px) {
    position: fixed;
    left: initial;
    right: 30px;
    bottom: 115px;  
  }

  @media (max-width: 810px) {   
    height: 40px;
    right: 20px;
  }
`

const LocationButtonsItem = styled.li`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 810px) {  

    &:nth-child(2) {
      display: none;
    }
  }
`

const WrapperButtonSatellite = styled.div<{height: number}>`
  position: absolute;
  bottom: ${(props) => `calc(100px - ${props.height}px)`};
  left: 30px;

  @media (max-width: 810px) {
    bottom: ${(props) => `calc(154px - ${props.height}px)`};
    left: 20px;
  }
`
export default MapMenuNew


