import { BlogType } from '../Redux/blogReducer'
import { instance, url } from './API'

export const blogAPI = {
    getBlogList() {
        return instance.get('/api/blog_pages')
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    createBlog(blog: BlogType) {
        console.log('createBlog', blog)
        return instance.post('/api/blog_pages', blog)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    updateBlog(blog: BlogType, id: number) {
        console.log('createBlog', blog)
        return instance.patch('/api/blog_pages/' + id, blog)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    deleteBlog(id: number) {
        return instance.delete('/api/blog_pages/' + id)
            .then(response => {
                return response
            })
            .catch(err => {
                if ( err.response ) {
                    return err.response
                } else if ( err.request ) {
                } else {
                }
                return null
            })
    },

    getBlogBySlug(payload: { slug: string }) {
        return instance.get('/api/blog_pages_slug/', {
            params: {
                slug: payload.slug
            }
        })
    }
}