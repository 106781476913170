import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BottomSheet, ModalWindow, Spinner } from '../../newComponent'
import {
    CategoryInitialStateType,
    changeCategoryFilter
} from '../../Redux/categoryReducer'
import BrandList from '../MapPage/BrandList/BrandListContainer'
import MapMenu from './../MapPage/MapMenuNew/MapMenuNewContainer'
import GoogleMapComponent from './GoogleMap/GoogleMapContainer'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AddressType } from "../../Redux/brendObjectReducer";
import { addError, addSuccess } from "../../Redux/messageReducer";
import { setMyCoordinates } from '../../Redux/mapReducer'
import { url } from '../../Api/API'
import { AppStateType, useAppDispatch } from "../../Redux/store";
import { Helmet } from "react-helmet";

export type DataForShareLinkType = {
    address: AddressType;
    objectId: number;
}

const MapPage= () => {

    const dispatch = useAppDispatch()
    const {trustedCatygoryes} = useSelector<AppStateType, CategoryInitialStateType>(state => state.categoryReducer)
    const [copyLink, setCopyLink] = useState<DataForShareLinkType | null>(null)
    const [zoomValue, setZoomValue] = useState<number>(14)
    const [selectedCategory, setSetselectedCategory] = useState<number[]>([])
    const [isOpenModalWindowShareSocialNetwork, setIsOpenModalWindowShareSocialNetwork] = useState<boolean>(false)
    const [myCoords, setMyCoords] = useState<google.maps.LatLngLiteral>()
    const [center, setCenter] = useState<google.maps.LatLngLiteral>()
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
    } | null>(null)
    const [isHiddenMenu, setIsHiddenMenu] = useState<boolean>(false)
    const [handleCoordinates, setHandleCoordinates] = useState<google.maps.LatLngLiteral>()
    const [mapType, setMapType] = useState<google.maps.MapTypeId>()
    const [openObjectId, setOpenObjectId] = useState<number>()
    const queryParams = new URLSearchParams(window.location.search)

    const [isNavigateMode, setIsNavigateMode] = useState<boolean>(false)

    useEffect(() => {
        selectedCategory !== undefined ?
            dispatch(changeCategoryFilter(selectedCategory))
            :
            dispatch(changeCategoryFilter(null))
    }, [selectedCategory]);

    useEffect(() => {
        const lat = queryParams.get("lat")
        const lng = queryParams.get("lng")
        const targetId = queryParams.get("target")
        const handleZoom = queryParams.get("zoom")

        targetId && setOpenObjectId(parseInt(targetId))
        handleZoom && setZoomValue(parseInt(handleZoom))

        if (lat && lng) {
            setMyCoords(getLocationsLiteral())
            setCenter({
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            })
        } else {
            getLocation()
        }

    }, []);

    const setNewZoomValue = (isNextZoomValue: boolean) => {
        if (isNextZoomValue) {
            return setZoomValue(zoomValue + 1)
        }
        return setZoomValue(zoomValue - 1)
    }

    const getLocationsLiteral = (): google.maps.LatLngLiteral => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords && position.coords.latitude && position.coords.longitude) {
                    return {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                } else {
                    return {
                        lat: 53.9416502,
                        lng: 27.686863
                    }
                }
            })
        }
        return {
            lat: 53.9416502,
            lng: 27.686863
        }
    }

    const getLocation = () => {
        if (!navigator.geolocation) {
            setMyCoords({
                lat: 53.9416502,
                lng: 27.686863
            })
            setCenter({
                lat: 53.9416502,
                lng: 27.686863
            })
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords &&
                    position.coords.latitude &&
                    position.coords.longitude
                ) {
                    setMyCoords({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    })
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    })
                } else {
                    setMyCoords({
                        lat: 53.9416502,
                        lng: 27.686863
                    })
                    setCenter({
                        lat: 53.9416502,
                        lng: 27.686863
                    })
                }

            }
                ,
                (e: any) => {
                    setMyCoords({
                        lat: 53.9416502,
                        lng: 27.686863
                    })
                    setCenter({
                        lat: 53.9416502,
                        lng: 27.686863
                    })
                });
        }
    }

    const handleClose = () => {
        setContextMenu(null);
    }

    const onHideMenu = () => {
        setIsHiddenMenu(!isHiddenMenu)
    }

    const openWindowForShareLink = (dataForShareLink: DataForShareLinkType): void => {
        setIsOpenModalWindowShareSocialNetwork(true)
        setCopyLink(dataForShareLink)
    }

    const closeModalWindow = () => {
        setCopyLink(null)
        setIsOpenModalWindowShareSocialNetwork(false)
    }

    const handleAddError = (err: string): void => {
        dispatch(addError(err))
    }

    const handleAddSuccess = (message: string): void => {
        dispatch(addSuccess(message))
    }

    const onContextMenu = (event?: React.MouseEvent & React.TouchEvent, coordinates?: google.maps.LatLng) => {
        if (!event) {
            setContextMenu(null)
        } else if (event && event.changedTouches) {
            event && event.preventDefault();
            event && setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.changedTouches[0].pageX + 2,
                        mouseY: event.changedTouches[0].pageY + 6,
                    }
                    : null,
            )
            coordinates && setHandleCoordinates({
                lat: coordinates.lat(),
                lng: coordinates.lng()
            })
        } else {
            event && event.preventDefault();
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY + 6,
                    }
                    : null,
            )
            coordinates && setHandleCoordinates({
                lat: coordinates.lat(),
                lng: coordinates.lng()
            })
        }
    }

    const choiseLocation = () => {
        handleCoordinates && setMyCoords(handleCoordinates)
        handleCoordinates && dispatch(setMyCoordinates(handleCoordinates))
        handleClose()
    }

    const copyMyLocation = (): void => {
        if (!handleCoordinates) {
            addError('Your location was not found')
            return setContextMenu(null);
        }
        navigator.clipboard.writeText(
            url + 'map?' +
            `lat=${handleCoordinates.lat}&` +
            `lng=${handleCoordinates.lng}` +
            `&zoom=12`
        )
            .then(() => {
                dispatch(addSuccess('Data successfully copied to clipboard'))
            })
            .catch((err) => addError('Error' + err))
            .finally(() => {
                setContextMenu(null)
            })
    }


    return (
        <>
            <Helmet>
                <title>Map of shops, merchants and services to accept cryptocurrency payment</title>
                <meta name="description" content='Looking for a place to spend your cryptocurrency? Check out our map of merchants and services that accept cryptocurrency payments.' />
            </Helmet>
            <MapMenu
                setZoomValue={setNewZoomValue}
                selectedCategory={selectedCategory}
                setSetselectedCategory={setSetselectedCategory}
                isHidden={isHiddenMenu}
                changeMapType={(type: google.maps.MapTypeId) => {
                    setMapType(type)
                }}
                setLocations={() => {
                    getLocation()
                }}
            />


            {myCoords && center ?
                <div>
                    <GoogleMapComponent
                        center={center}
                        myCoords={myCoords}
                        setMyCoords={setMyCoords}
                        openWindowForShareLink={openWindowForShareLink}
                        onContextMenu={onContextMenu}
                        onHideMenu={onHideMenu}
                        mapType={mapType}
                        openObjectId={openObjectId}
                        setOpenObjectId={setOpenObjectId}
                        zoom={zoomValue}
                        setZoom={setZoomValue as () => void}
                        setIsNavigateMode={setIsNavigateMode}
                        isNavigateMode={isNavigateMode}
                        selectedCategory={selectedCategory}
                    />
                </div> : <Spinner />

            }
            {selectedCategory.length !== 0
                && !isHiddenMenu
                && trustedCatygoryes && trustedCatygoryes?.length > 0
                ||
                selectedCategory.length !== 0
                && !isHiddenMenu && trustedCatygoryes === undefined
                ?
                <BrandList
                    selectedCategory={selectedCategory}
                    setSetselectedCategory={setSetselectedCategory}
                    openWindowForShareLink={openWindowForShareLink}
                    setZoomValue={setZoomValue}
                    setCenter={setCenter}
                />
                :
                document.querySelector('.gm-svpc')?.removeAttribute('hidden')
            }


            <ModalWindow
                open={isOpenModalWindowShareSocialNetwork}
                setOpen={closeModalWindow}
                isBottomContent
            >
                <BottomSheet
                    copyLink={copyLink}
                    addSuccess={handleAddSuccess}
                    addError={handleAddError}
                    zoomValue={zoomValue}
                />
            </ModalWindow>

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={choiseLocation}>I`m here</MenuItem>
                <MenuItem onClick={copyMyLocation}>Copy</MenuItem>

            </Menu>
        </>
    )
}

export default MapPage

