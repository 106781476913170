import React, {FC, useState} from "react";

import styled from "styled-components";

import iconMan from './../../../assets/images/icons/icon-man.svg'
import {ButtonIconHumanPropsType} from "./types";


export const ButtonIconHuman: FC<ButtonIconHumanPropsType> = (props) => {
    const {onClick} = props;
    const [active, setActive] = useState<boolean>(false)

    const handleOnClick = (): void => {
        onClick && onClick()
    };

    const handleOnMouseDown = (): void => setActive(true);

    const handleOnMouseUp = (): void => setActive(false);


    return (
        <Button active={active}>
            <img src={iconMan}
                 alt="human"
                 onClick={handleOnClick}
                 onMouseUp={handleOnMouseUp}
                 onMouseDown={handleOnMouseDown}/>
        </Button>
    )
}

const Button = styled.button<{ active: boolean }>`
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  filter: ${({active}) => active ? 'none' : 'drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25))'};
  cursor: pointer;
  transition: all .3s;
`
