import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Icon } from "../Icon";
import styled from "styled-components";
import { changeTrustedCatygory } from "../../Redux/categoryReducer";
import { HeaderMenuDataType } from "../../Components/MapPage/MapMenuNew/MapMenuNew";
import { IconNameType } from "../Icon/types";
import { useAppDispatch } from "../../Redux/store";

type MobileMapMenuPropsType = {
    productsMenuData: HeaderMenuDataType[];
    onChange: (iconId: string, isChecked: boolean) => void;
    onClickAll: () => void;
    changeHeaderMenuData: React.Dispatch<React.SetStateAction<HeaderMenuDataType[] | undefined>>
}
export const MobileMapMenu: FC<MobileMapMenuPropsType> = (props) => {

    const dispatch = useAppDispatch()

    const [isAllSelected, setIsAllSelected] = useState<boolean>(true)

    useEffect(() => {
        console.log('!!!!', props.productsMenuData)
    }, [props.productsMenuData]);

    const selectAll = () => {
        let newData: HeaderMenuDataType[] = []
        if (props.productsMenuData.find(item => !item.isChacked)) {
            newData = props.productsMenuData.map(item => {
                let newItem = { ...item }
                newItem.isChacked = true
                return newItem
            })
            setIsAllSelected(true)
        } else {
            newData = props.productsMenuData.map(item => {
                let newItem = { ...item }
                newItem.isChacked = false
                return newItem
            })
            setIsAllSelected(false)
        }

        props.changeHeaderMenuData(newData)

        const trustedCategoryes = newData.filter(item => {
            if (item.id !== undefined) {
                return item
            }
        }).map( item => item.id)
        console.log('DISPATCH', trustedCategoryes)
        dispatch(changeTrustedCatygory(trustedCategoryes as number[]))
    }

    const changeHeaderMenuData = (id: number, isChacked: boolean) => {
        const newData: HeaderMenuDataType[] = props.productsMenuData.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    isChacked: isChacked
                }
            } else {
                return item
            }
        })
        props.changeHeaderMenuData(newData)

        const trustedCategoryes = newData.filter(item => item.isChacked).map( item => item.id)
        console.log('DISPATCH', trustedCategoryes)
        dispatch(changeTrustedCatygory(trustedCategoryes as number[]))

        !isChacked && setIsAllSelected(false)
    }

    return (
        <Container className="MenuListContainer">
            {/* <Input type="text" placeholder='What to show on the map?' /> */}
            <Button
                onClick={selectAll}
                isSelectedAll={isAllSelected}
            >
                {isAllSelected ? 'Clear All' : 'Select All'}
            </Button>
            <MenuList className="MenuList">
                {
                    props.productsMenuData.map((category) => {
                        return (
                            <MobileMapMenuItem
                                key={`${category.id}-mobile-map-menu`}
                                category={category}

                                changeHeaderMenuData={changeHeaderMenuData}
                            />
                        )
                    }
                    )
                }
            </MenuList>
        </Container>
    )
}

type MobileMapMenuItemPropsType = {
    category: HeaderMenuDataType
    changeHeaderMenuData: (id: number, data: boolean) => void
}

const MobileMapMenuItem: React.FC<MobileMapMenuItemPropsType> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(true)

    useEffect(() => {
        isChecked !== props.category.isChacked && setIsChecked(props.category.isChacked)
    }, [props.category]);

    const handleOnChange = (id: number, e: ChangeEvent<HTMLInputElement>): void => {
        console.log('CHECKED - ', e.currentTarget.checked)
        setIsChecked(e.currentTarget.checked)
        props.changeHeaderMenuData(id, e.currentTarget.checked)
    }

    return (
        <MenuItem key={`${props.category.id}-mobile-menu`}>
            <Label>

                <CheckBox
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => { props.category.id && handleOnChange(props.category.id, e) }}
                />

                <Icon name={props.category.title as IconNameType} />

                <Text>
                    {props.category.title}
                </Text>

            </Label>
        </MenuItem>
    )
}

const Container = styled.div`
  display: none;
  max-width: 50%;
//   height: 100%;
  padding: 25px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  background-color: #ffffff;
  text-align: center;
  @media(max-width: 810px){
    display: block;
  }

  @media(max-width: 650px){
    max-width: 80%;
  }

  @media(max-width: 450px){
    max-width: 100%;
  }
`
const Input = styled.input`
  outline: none;
  height: 47px;
  width: 100%;
  border: 1px solid #A9A9A9;
  border-radius: 80px;
  margin-bottom: 30px;
  color: #A9A9A9;
  padding: 0 20px;
  &::placeholder{
    text-align: center;
    color: #A9A9A9;
  }
`
const Button = styled.button<{ isSelectedAll: boolean }>`  
  outline: none;
  border: 2px solid #797DF9;
  color:  ${({ isSelectedAll }) => isSelectedAll ? '#ffffff' : '#333333'};
  width: 136px;
  height: 44px;
  background-color: ${({ isSelectedAll }) => isSelectedAll ? '#797DF9' : '#ffffff'};
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
  transition: all .3s;
  &:hover {
    background-color: #797DF9;
    color: #ffffff;
  }
`
const MenuList = styled.ul`
text-align: left;
`
const MenuItem = styled.li`
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;  
`
const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Text = styled.span`
  padding: 0 10px;
`
const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 10px;
`
