import React, { FC, useEffect, useState } from "react";
import { CardProps } from "./Card.props";
import styles from './Card.module.scss'
import cn from "classnames";
import { url } from "../../../../../Api/API";
import { Rating } from "../../../../../Elements/Rating/Rating";
import { Button } from "../../../../../Elements/common/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import noImageIcon from './noImage.png'
import noImageIconWebp from './noImage.webp'
import { ReactComponent as PinIcon } from './../../../../../assets/images/icons/pin.svg'
import { ReactComponent as LikeIcon } from './../../../../../assets/images/icons/like.svg'
import { ReactComponent as BitcoinIcon } from './../../../../../assets/images/icons/bitcoin.svg'
import { ReactComponent as GoToMapIcon } from './../../../../../Elements/testComponents/ClientCard/iconComponents/to_navigator_f.svg'
import { ReactComponent as InfoIcon } from './../../../../../Elements/testComponents/ClientCard/iconComponents/i_nofill_f 2.svg'
import { checkAuth } from "../../../../../Redux/authReducer/authReduser";
import { selectUserData } from "../../../../../Redux/selectors/authSelector";
import {  useSelector } from "react-redux";
import { createCurrentItem, deleteCurrentItem } from "../../../../../Redux/adminReducer";
import { isDesktop, isMobile } from "react-device-detect";
import { useAppDispatch } from "../../../../../Redux/store";


export const Card: FC<CardProps> = (props) => {
    const {className, brandObject, isAuth, ...restProps} = props;
    const {title, logoFileName, id, category, averageGrade } = brandObject;

    const userData = useSelector(selectUserData)

    const [isActive, setIsActive] = useState<boolean>(false)
    const [isActiveFavoriteBtn, setIsActiveFavoriteBtn] = useState<{ pins: boolean, likes: boolean }>({
        pins: !!userData?.pins.find(i => i.brandItemId === brandObject.id),
        likes: !!userData?.likes.find(i => i.brandItemId === brandObject.id)
    })

    useEffect(() => {
        setIsActiveFavoriteBtn({
            pins: !!userData?.pins.find(i => i.brandItemId === brandObject.id),
            likes: !!userData?.likes.find(i => i.brandItemId === brandObject.id)
        })
    }, [brandObject])

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const onChange = (target: 'pins' | 'likes') => {
        if (!isActiveFavoriteBtn[target]) {
            userData && dispatch(createCurrentItem(
                target, 
                {
                    //user: userData.id,
                    brandItem: brandObject.id
                },
                undefined,
                undefined,
                undefined,
                checkAuth
            ))
            // dispatch(checkAuth())
        } else {
            if (userData) {
                const targetObj = userData[target].find(i => i.brandItemId === brandObject.id)
                targetObj && dispatch(deleteCurrentItem(
                    target, 
                    targetObj.id, 
                    {
                        page: 1,
                        perPage: 10
                    },
                    checkAuth
                ))
            }
            dispatch(checkAuth())
        }

        const newdata = {...isActiveFavoriteBtn}
        newdata[target] = !isActiveFavoriteBtn[target]

        setIsActiveFavoriteBtn(newdata)
    }

    const favoritesBlock = () => {
        return (
            <>
                <Button appearance='transparent'
                        onClick={() => {
                            onChange('pins')
                        }}
                        className={cn(styles.favoriteIcon, {
                            [styles.activeIcon]: isActiveFavoriteBtn.pins
                        })}>
                    <PinIcon/>
                </Button>
                <Button appearance='transparent'
                        onClick={() => {
                            onChange('likes')
                        }}
                        className={cn(styles.favoriteIcon, {
                            [styles.activeFavoriteIcon]: isActiveFavoriteBtn.likes
                        })}
                >
                    <LikeIcon/>
                </Button>
            </>
        )
    }

    const logo = logoFileName ? url + 'media/140x70' + logoFileName : noImageIcon
    const logoWebp = logoFileName ? url + 'media/140x70' + logoFileName + '.webp' : noImageIconWebp
    const coinCount = props.brandObject.currencies && props.brandObject.currencies.length > 4 ? props.brandObject.currencies.length - 3 : undefined

    return (
        <div 
            className={cn(styles.card, props.brandObject.isAdvertising ? styles.isAdvertising : '', className)} 
            {...restProps}
            style={{marginBottom: 30, marginLeft: 10, marginRight: 10}}
        >
            <TopContainer>
                <div className={cn(styles.favouritesBlock)}>
                    {isAuth && favoritesBlock()}
                </div>
                <div className={cn(styles.icons)}>
                    {props.brandObject.currencies?.length === 0 && <div className={styles.icon}><BitcoinIcon/></div>}
                    {props.brandObject.currencies
                        && props.brandObject.currencies.length > 0
                        && props.brandObject.currencies?.length <= 4
                        && props.brandObject.currencies?.map(({id, title, logoFileName}) => {
                        return (<div className={styles.icon} key={id}>
                            <picture>
                                <source srcSet={url + 'media/30x30' + logoFileName + '.webp'} type="image/webp"/>
                                <img 
                                    src={url + 'media/30x30' + logoFileName} 
                                    alt={title}
                                    onError = {(el: any) => {
                                        el.target.src = url + logoFileName
                                    }}
                                />
                            </picture>
                        </div>)
                    })}
                    {props.brandObject.currencies
                        && props.brandObject.currencies.length > 4
                        && props.brandObject.currencies?.slice(0, 3).map(({id, title, logoFileName}) => {
                        return (<div className={styles.icon} key={id}>
                            <picture>
                                <source srcSet={url + 'media/30x30' + logoFileName + '.webp'} type="image/webp"/>
                                <img 
                                    src={url + 'media/30x30' + logoFileName} 
                                    alt={title}
                                    onError = {(el: any) => {
                                        el.target.src = url + logoFileName
                                    }}
                                />
                            </picture>
                        </div>)
                    })}
                    {
                        coinCount && <div className={styles.icon}>
                            <EmptyCryptaIcon>+{coinCount}</EmptyCryptaIcon>
                        </div>
                    }
                </div>
            </TopContainer>

            <div
                onMouseEnter={() => {
                    isDesktop && setIsActive(!isActive)
                }}
                onMouseLeave={() => {
                    isDesktop && setIsActive(!isActive)
                    isMobile && isActive && setIsActive(false)
                }}
                onClick={() => {
                    isMobile && setIsActive(!isActive)
                    // isTablet && setIsActive(!isActive)
                }}
            >
                
                {/* <Link to={'/catalog/' + id}> */}
                <picture>
                    <source srcSet={logoWebp} type="image/webp"/>
                    <img className={styles.logo} src={logo} alt={title}/>
                </picture>
                {/* </Link> */}
                <div className={styles.title}>{title}</div>
                <div className={styles.type}>{category.title}</div>
                <div className={cn(styles.actionBlock, {[styles.active]: isActive})}>
                    <IconButton
                        onClick={() => {
                            console.log('REDIRECT DATA location.pathname:', location)
                            console.log('REDIRECT DATA creteria:', location.pathname === '/catalog')
                            console.log('REDIRECT DATA', location.pathname === '/catalog' ?
                            '/map?lat=' + props.brandObject.latitude + '&lng=' + props.brandObject.longitude
                            + '&title=' + decodeURI(props.brandObject.title) + '&zoom=18'
                            :
                            '/map?lat=' + props.brandObject.latitude + '&lng=' + props.brandObject.longitude
                            + '&title=' + decodeURI(props.brandObject.title) + '&zoom=18')

                            navigate(location.pathname === '/catalog' ?
                            '/map?lat=' + props.brandObject.latitude + '&lng=' + props.brandObject.longitude
                            + '&title=' + decodeURI(props.brandObject.title) + '&zoom=18'
                            :
                            '/map?lat=' + props.brandObject.latitude + '&lng=' + props.brandObject.longitude
                            + '&title=' + decodeURI(props.brandObject.title) + '&zoom=18')
                        }}>
                        <GoToMapIcon/>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            console.log('IconButton catalog', '/catalog/'+brandObject.category.title.toLocaleLowerCase().replaceAll(' ', '_') + '/' + brandObject.slug)
                            navigate('/catalog/'+brandObject.category.title.toLocaleLowerCase().replaceAll(' ', '_') + '/' + brandObject.slug)
                        }}
                        // onClick={() => navigate('/catalog/' + id)}
                    >
                        <InfoIcon/>
                    </IconButton>
                </div>
                <Rating className={styles.rating} rating={ averageGrade && averageGrade > 0 ? averageGrade : 4}/>
            </div>
        </div>
    )
}

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 10px;
`


const IconButton = styled.button`
  border-radius: 5px;
  border: 1px solid #FFFFFF;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s;
  
  &:hover{
    background-color: #7395C7;
  }
  svg{
    width: 30px;
    height: 30px;
    padding: 3px 0;
  }
`

const EmptyCryptaIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  border-radius: 50%;
  background-color: #FFFFFF;
  color: #9ec3f9;
  border: 1px solid #9ec3f9;
  height: 23px;
  width: 23px;
`