import React, {FC, useState} from "react";
import styled from "styled-components";
import {ButtonMapFullScreenPropsType} from "./types";



export const ButtonMapFullScreen: FC<ButtonMapFullScreenPropsType> = (props) => {
    const {onClick, isSelected = false} = props;
    const [active, setActive] = useState<boolean>(false);

    const handleOnClick = (): void => {
        onClick && onClick()
    };

    const handleOnMouseDown = (): void => setActive(true);

    const handleOnMouseUp = (): void => setActive(false);

    return (
        <Button isSelected={isSelected}
                active={active}
                onMouseUp={handleOnMouseUp}
                onMouseDown={handleOnMouseDown}
                onClick={handleOnClick}>
            {isSelected
                ?
                <Icon width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                    <rect x="6" y="6" width="2" height="6" rx="1" transform="rotate(180 6 6)"/>
                    <rect x="6" y="4" width="2" height="6" rx="1" transform="rotate(90 6 4)"/>
                    <rect x="15" y="6" width="2" height="6" rx="1" transform="rotate(-90 15 6)"/>
                    <rect x="17" y="6" width="2" height="6" rx="1" transform="rotate(180 17 6)"/>
                    <rect x="6" y="15" width="2" height="6" rx="1" transform="rotate(90 6 15)"/>
                    <rect x="4" y="15" width="2" height="6" rx="1"/>
                    <rect x="15" y="15" width="2" height="6" rx="1"/>
                    <rect x="15" y="17" width="2" height="6" rx="1" transform="rotate(-90 15 17)"/>
                </Icon>
                :
                <Icon width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                    <rect width="2" height="6" rx="1"/>
                    <rect y="2" width="2" height="6" rx="1" transform="rotate(-90 0 2)"/>
                    <rect x="21" width="2" height="6" rx="1" transform="rotate(90 21 0)"/>
                    <rect x="19" width="2" height="6" rx="1"/>
                    <rect y="21" width="2" height="6" rx="1" transform="rotate(-90 0 21)"/>
                    <rect x="2" y="21" width="2" height="6" rx="1" transform="rotate(-180 2 21)"/>
                    <rect x="21" y="21" width="2" height="6" rx="1" transform="rotate(-180 21 21)"/>
                    <rect x="21" y="19" width="2" height="6" rx="1" transform="rotate(90 21 19)"/>
                </Icon>
            }
        </Button>
    )

}

const Button = styled.button<{ active: boolean, isSelected: boolean }>`
  width: 40px;
  height: 40px;
  padding: 10px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  filter: ${({active}) => active ? 'none' : 'drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.25))'};
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: #797DF9;
    svg {
      fill: #ffffff;
    }
  }
`

const Icon = styled.svg`
  fill: #666666;
`


