import { FC, PropsWithChildren } from "react";
import { SelectProps } from "./Select.props";
import styles from './Select.module.scss'
import cn from "classnames";

export const Select: FC<PropsWithChildren<SelectProps>> = (props) => {
    const {children, className, options, placeholder, ...restProps} = props;

    const buildOptionsList = () => {
        const newOptions = options?.map(({id, title}, index): JSX.Element => {
            return <option className={styles.option} key={id + '-' + index} value={id}>{title}</option>
        })

        // newOptions && newOptions.unshift(
        //     <option className={styles.option} key='all'>All</option>
        // )

        return newOptions
    }

    return (
        <select className={cn(styles.select, className)} {...restProps}>
            {placeholder && <option value=''>{placeholder}</option>}
            {options && buildOptionsList()}
            {children}
        </select>

    )
}