import React, { FC, useState } from "react";
import styles from "../AuthPage.module.scss";
import user from "../../../../assets/images/icons/IconUser.svg";
import cn from "classnames";
import password from "../../../../assets/images/icons/IconPassword.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Color, SocialLink } from "../../../../newComponent";
import iconFacebook from "../../../../assets/images/icons/IconFB.svg";
import GoogleLogin, {
    GoogleLoginResponse,
    GoogleLoginResponseOffline
} from "react-google-login";
import iconGoogle from "../../../../assets/images/icons/IconGoogle.svg";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { SocialLoginDataType } from "../AuthPage.props";
import { socialAuth } from "../../../../Redux/authReducer/authReduser";
import { useAppDispatch } from "../../../../Redux/store";
import { UseFormRegister } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { DefaultAuthFieldsValue } from "../AuthPage";
import { Checkbox } from "antd";


interface RegisterProps {
    register: UseFormRegister<Omit<DefaultAuthFieldsValue, 'email'>>;
    setPageName: (pageName: 'login' | 'register' | 'forgot') => void;
    errors: FieldErrors<Omit<DefaultAuthFieldsValue, 'email'>>;
}

export const Register: FC<RegisterProps> = (props) => {
    const {register, setPageName, errors} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false)

    return <>
        <div className={styles.title}>Register</div>
        <div className={styles['input-wrapper']}>
            <input
                style={{backgroundImage: `url(${user})`}}
                type="email"
                autoComplete='off'
                {...register("username", {required: true})}
                className={cn(styles.input)}/>
            {errors.username && <span className={styles['error-field']}>error</span>}
        </div>
        <div className={styles['input-wrapper']}>
            <input
                style={{backgroundImage: `url(${password})`}}
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                {...register("password", {required: true, minLength: 8})}
                className={cn(styles.input)}/>
            {errors.password && <span className={styles['error-field']}>error</span>}
        </div>
        <div className={styles['input-wrapper']}>
            <Checkbox onChange={(e) => setShowPassword(e.target.checked)}>
                Show password
            </Checkbox>
        </div>
        <div className={styles['buttons-block']}>
            <button
                type='submit'
                className={cn(styles.btn, styles['btn-submit'])}>
                Register
            </button>
            <button type='button'
                    style={{textAlign: "left"}}
                    className={cn(styles.btn, styles['btn-register'], styles['btn-link'])}
                    onClick={() => setPageName('login')}>
                Login
            </button>
        </div>

    </>
}