import { Button } from "../../common/Button/Button";
import cn from "classnames";
import styles
    from "../../../Components/Main/SearchBlock/RowResults/Card/Card.module.scss";
import style from './ClientCard.module.scss'
import React, { DetailedHTMLProps, FC, HTMLAttributes, useState } from "react";
import { Rating } from "../../Rating/Rating";
import { BrendItemFullType } from "../../../Redux/brandItemReducer";
import {  useSelector } from "react-redux";
import { AppStateType, useAppDispatch } from "../../../Redux/store";
import { calculateRoute, MapInitialStateType } from "../../../Redux/mapReducer";
import { url } from "../../../Api/API";
import { AddressType } from "../../../Redux/brendObjectReducer";
import { Link, useNavigate } from "react-router-dom";
import {ReactComponent as ShareIcon} from "./../ClientCard/iconComponents/figma_share.svg";
import {ReactComponent as ArrowUpIcon} from "./../ClientCard/iconComponents/action_f.svg";
import {ReactComponent as InfoIcon} from "./../ClientCard/iconComponents/view_to_map_f.svg";
import {ReactComponent as ShowToMap} from "./../ClientCard/iconComponents/i_nofill_f 2.svg";
import {ReactComponent as CreateRouteIcon} from "./../ClientCard/iconComponents/to_navigator_f.svg";
import { ReactComponent as PinIcon } from "../../../assets/images/icons/pin.svg";
import { ReactComponent as LikeIcon } from "../../../assets/images/icons/like.svg";
import { ReactComponent as BitcoinIcon } from '../../../assets/images/icons/bitcoin.svg'
import { ReactComponent as MapIcon } from './map-pin.svg'
import { ReactComponent as PhoneIcon } from './phone.svg'
import { selectUserData } from "../../../Redux/selectors/authSelector";
import { createCurrentItem, deleteCurrentItem } from "../../../Redux/adminReducer";
import { checkAuth } from "../../../Redux/authReducer/authReduser";


enum ButtonName {
    pins = 'pins',
    likes = 'likes'
}

export interface ClientCard extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    createRoute: () => void;
    shareSocialNetwork: (dataForShareLink: { address: AddressType; objectId: number | undefined }) => void;
    addToFavorites: () => void;
    brendObject: BrendItemFullType;
    //dragging?: boolean;
    isAuth: boolean
}

export const ClientCard: FC<ClientCard> = (props) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const userData = useSelector(selectUserData)

    const {myCoordinates} = useSelector<AppStateType, MapInitialStateType>(state => state.mapReducer)
    const {
        className, createRoute, shareSocialNetwork, addToFavorites, brendObject, isAuth, ...restProps
    } = props;
    const {id, currencies, title, category, logoFileName, address, phoneNumbers, averageGrade, dist} = brendObject;
    const {state, country, building, cityName, street, apartment} = address
    const [isActiveFavoriteBtn, setIsActiveFavoriteBtn] = useState<{ pins: boolean, likes: boolean }>({
        pins: !!userData?.pins.find(i => i.brandItemId === brendObject.id),
        likes: !!userData?.likes.find(i => i.brandItemId === brendObject.id)
    })
    const [isHidden, setIsHidden] = useState<boolean>(true)
    const phone = phoneNumbers.length > 0 ? phoneNumbers[0] : undefined

    const handleOnClickAddToFavorites = (): void => {
        addToFavorites()
    }

    const handleOnClickShareSocialNetwork = (): void => {
        const dataForShareLink = {address: props.brendObject.address, objectId: props.brendObject.id}
        shareSocialNetwork(dataForShareLink)
    }

    const handleOnClickCreateRoute = (): void => {
        if (myCoordinates) {
            dispatch(calculateRoute(myCoordinates, {
                lat: props.brendObject.latitude,
                lng: props.brendObject.longitude
            }))
        }
        createRoute()
    }

    const onChange = (target: 'pins' | 'likes') => {
        if (!isActiveFavoriteBtn[target]) {
            userData && dispatch(createCurrentItem(
                target, 
                {
                    user: userData.id,
                    brandItem: brendObject.id
                },
                undefined,
                undefined,
                undefined,
                checkAuth
            ))
            dispatch(checkAuth())
        } else {
            if (userData) {
                const targetObj = userData[target].find(i => i.brandItemId === brendObject.id)
                targetObj && dispatch(deleteCurrentItem(target, targetObj.id, {
                    page: 1,
                    perPage: 10
                },
                checkAuth
                ))
            }
            dispatch(checkAuth())
        }

        const newdata = {...isActiveFavoriteBtn}
        newdata[target] = !isActiveFavoriteBtn[target]

        setIsActiveFavoriteBtn(newdata)
    }

    const favoritesBlock = () => {
        if (isAuth) return (
            <>
                <Button appearance='transparent'
                        onClick={() => onChange(ButtonName.likes)}
                        className={cn(styles.favoriteIcon, {
                            [styles.activeFavoriteIcon]: isActiveFavoriteBtn.likes
                        })}
                >
                    <LikeIcon/>
                </Button>
                <Button appearance='transparent'
                        onClick={() => onChange(ButtonName.pins)}
                        className={cn(styles.favoriteIcon, {
                            [styles.activeIcon]: isActiveFavoriteBtn.pins
                        })}>
                    <PinIcon/>
                </Button>
            </>
        )
        return <></>
    }

    const coinCount = currencies && currencies.length > 4 ? currencies.length - 3 : undefined
    
    return (
        <div className={cn(style.card, className)} {...restProps}>
            <div className={style.card__top}>
                <div className={style.card__title}>
                    <Link to={'/catalog/' + brendObject.category.title + '/' + brendObject.slug}>
                        {title}
                    </Link>
                </div>
                <span className={style.card__distance}>{dist && dist.toFixed(2) + 'km'}</span>
            </div>
            <div className={style.card__content}>
                <div className={style.card__inner}>
                    <Link to={'/catalog/' + brendObject.category.title + '/' + brendObject.slug}>
                        <div className={style.card__image}>
                            <img 
                                src={url + 'media/100x70' + logoFileName} 
                                onError = {(el: any) => {
                                    el.target.src = url + logoFileName
                                }}
                            />
                        </div>
                    </Link>
                    <div>
                        <div className={style.card__favoriteBlock}>
                            <div>{isAuth && favoritesBlock()}</div>
                        </div>
                        <div>
                            <Rating rating={ averageGrade && averageGrade > 0 ? averageGrade : 4} className={style.card__rating}/>
                        </div>
                        <div className={style.card__category}>
                            {category?.title}
                        </div>
                    </div>
                </div>
                <div className={style.card__contactBlock}>
                    <div className={style.card__address}>
                        <span className={style.card__contentIcon}>
                            <MapIcon className={style.card__contentIcon}/>
                        </span>
                        <div>
                            <div className={style.card__addressText}>
                                {building && <span>{building + ', '}</span>}
                                {street && <span>{street + ', '}</span>}
                                {cityName  && <span>{cityName + ', '}</span>}
                            </div>
                            <div>
                                {/*<span>*/}
                                {/*    {state && state.title}*/}
                                {/*</span>*/}
                                <span>
                                    {country?.title}
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                       props.brendObject.phoneNumbers && props.brendObject.phoneNumbers.length > 0 && <div className={style.card__phone}>
                        <span className={style.card__contentIcon}>
                            <PhoneIcon/>
                        </span>
                        <span className={style.card__phoneLink} onClick={() => window.open('tel:' + phoneNumbers[0].number)}>
                            {phoneNumbers[0].number}
                        </span>
                        </div>
                    }

                </div>
            </div>
            <div className={style.card__bottom}>
                <button className={style.card__openBtn}
                        onClick={() => setIsHidden(false)}><ArrowUpIcon />
                </button>
                <div className={style.card__coin}>
                    {currencies?.length === 0 && <span><BitcoinIcon/></span>}
                    {currencies && currencies.length > 0 && currencies.length <= 4 && currencies.map(c =>
                        <span key={c.id}>
                                <img 
                                    src={url + 'media/30x30' + c.logoFileName} 
                                    alt={c.title}
                                    onError = {(el: any) => {
                                        el.target.src = url + c.logoFileName
                                    }}
                                />
                            </span>)}
                    {currencies && currencies.length > 4 && currencies?.slice(0, 3).map(c =>
                        <span key={c.id}>
                                <img 
                                    src={url + 'media/30x30' + c.logoFileName} 
                                    alt={c.title}
                                    onError = {(el: any) => {
                                        el.target.src = url + c.logoFileName
                                    }}
                                />
                            </span>)}
                    {coinCount && <span className={style.card__emptyCoin}>
                        {coinCount}
                    </span> }
                </div>

            </div>
            <div className={cn(style.card__actionBlock, {
                [style.active]: !isHidden
            })}>
                <button className={style.card__actionBtn} onClick={handleOnClickShareSocialNetwork}><ShareIcon/></button>
                <button className={style.card__actionBtn} onClick={handleOnClickAddToFavorites}><InfoIcon/></button>
                <button className={style.card__actionBtn} onClick={() => navigate('/catalog/'+ brendObject.category.slug + '/' + brendObject.slug)}><ShowToMap/></button>
                <button className={style.card__actionBtn} onClick={handleOnClickCreateRoute}><CreateRouteIcon/></button>
                <button className={style.card__closeBtn}
                        onClick={() => setIsHidden(true)}><ArrowUpIcon/>
                </button>
            </div>
        </div>
    )
}