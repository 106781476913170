import { CategoryType } from '../Redux/categoryReducer'
import { instance, url } from './API'

export const categoryAPI = {
    getActiveCategoryes() {
        return instance.get('api/categories')
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    getAllCategories() {
        return instance.get('api/categories?page=1&perPage=100')
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    updateCategories(category: CategoryType, id: number) {
        return instance.patch('api/categories/'+id, category)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    createCatygory(category: CategoryType) {
        return instance.post('api/categories', category)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    },

    deleteCategory(id: number) {
        return instance.delete('api/categories/'+id)
        .then(response => {
            return response
        })
        .catch(err => {
            if (err.response) {
                return err.response
            } else if (err.request) {
            } else {
            }
            return null
        })
    }
}