import React, {FC} from "react";

import styled from "styled-components";

import {ButtonLoginPropsType} from "./types";
import {useWindowSize} from "../../../hooks/useWindowWidth";

export const ButtonLogin: FC<ButtonLoginPropsType> = (props) => {
    const {onClick, setOpenNav, className} = props;

    const handleOnClick = () => {
        onClick && onClick(true)
        setOpenNav && setOpenNav(false)
    }

    return (
        <Button className={className} onClick={handleOnClick}>Log in</Button>
    )
}

export const Button = styled.button`
  background-color: #ffffff;
  border: 2px solid #797DF9;
  border-radius: 45px;
  min-width: 119px;
  max-width: 170px;
  width: 100%;
  height: 32px;  
  outline: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background-color: #797DF9;
    color: #ffffff;
  }
  @media(max-width: 810px){
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    margin: 0 auto;
  }
`
