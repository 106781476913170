import React, {FC} from "react";

import styled from "styled-components";

import {ModalWindowPropsType} from "./types";

export const ModalWindow: FC<ModalWindowPropsType> = (props) => {
    const {children, open, setOpen, isBottomContent = false} = props;

    const handleOnClickDiv = () => {
      setOpen(false)
    }

    return (
        <Container role="presentation" open={open} onClick={handleOnClickDiv} isBottomContent={isBottomContent}>
            {children}
        </Container>

    )
}

const Container = styled.div<{ open: boolean, isBottomContent: boolean }>`
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  position: fixed;
  transition: all .3s;  
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({open}) => open ? '1' : '-1'};
  z-index:  ${({open}) => open ? '900' : '-10'};  
  @media(max-width: 414px){
    align-items: ${({isBottomContent})=> isBottomContent ? 'flex-end' : 'center'};
  }
`
