import styles from "../BlogBlock.module.scss";
import { url } from "../../../../Api/API";
import cn from "classnames";
import React, { FC } from "react";
import { LargePostProps } from "./LargePost.props";
import { format, isValid } from "date-fns";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export const LargePost: FC<LargePostProps> = (props) => {
    const {
        post: {image, publicationAt, title, bodyShort, slug},
        className,
        ...restProps
    } = props;
    return (
        <div className={cn(styles.largePost, className)} {...restProps}>
            <div className={styles.largeImage}>
                <Link to={'/blog/' + slug}>
                    <img className={styles.largeImg} src={url + image} alt=""/>
                </Link>
            </div>
            <div className={styles.date}>
                {publicationAt && isValid(new Date(publicationAt)) && format(new Date(publicationAt), 'MMMM dd, yyyy')}
            </div>
            <Link to={'/blog/' + slug}>
                <h3 className={styles.title}>
                    {title}
                </h3>
            </Link>
            <div className={styles.contentBox}>
                <div className={cn(styles.content, styles.contentLargePost)}>
                    {parse(bodyShort)}
                </div>
                <Link className={styles.link} to={'/blog/' + slug}>read more</Link>
            </div>
        </div>
    )
}