import { BreadCrumbsProps } from "./BreadCrumbs.props";
import { FC } from "react";
import styles from './BreadCrumbs.module.scss'
import cn from "classnames";
import { Link } from "react-router-dom";


export const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
    const {className, breadCrumbs, ...restProps} = props;

    return (<div className={cn(styles.container, className)} {...restProps}>
        {breadCrumbs.map((item, index) => {
            return (
                <Link className={styles.item} to={item.key}
                      key={item.label + '-' + index}>
                    {item.label}
                </Link>
            )
        })}
    </div>)
}


