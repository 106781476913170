import { Dispatch } from 'redux'
import { clusterAPI } from '../Api/clusterApi'
import { QueryParamsType } from '../Utils/UniversalTableView/UniversalTableView'
import { ClusterType } from './brandItemReducer'
import { BaseThunkType, InferActionsTypes } from './store'

export type InitialStateType ={
    clusterList?: ClusterType[]
    totalCount?: number
}

let initialState:InitialStateType = {
    
}

type ActionsType = InferActionsTypes<typeof actions>

const clusterReducer = (state = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'CLUSTER/SET_CLUSTER_LIST':
            return {
                    ...state,
                    clusterList: action.clusterList,
                    totalCount: action.totalCount
            }
        default:
            return state;
    }
}

export const actions = {
    setClusterList: (clusterList: ClusterType[], totalCount: number) => ({type: 'CLUSTER/SET_CLUSTER_LIST', clusterList, totalCount} as const),
}

export const getClusterListByFilter = (params: QueryParamsType): ThunkType => {
    return async (dispatch, getState) => {
        const response = await clusterAPI.getClusterListByFilter(params)
        if (response.status === 200) {
            dispatch( actions.setClusterList(response.data.clusters, response.data.totalCount) )
        }
    }
}

export default clusterReducer

type ActionsTypes = InferActionsTypes<typeof actions>
type ThunkType = BaseThunkType<ActionsTypes>
export type DispatchType = Dispatch<ActionsTypes>