import { BaseThunkType } from "./store";
import { setAuth, setUserData } from "./authReducer/authReduser";
import { authAPI } from "../Api/authApi";

const APP_SET_STATUS_INITIALIZED = 'APP/SET_STATUS'

export type StatusType = 'failed' | 'success' | 'loading' | 'idle'

export type AppInitialStateType = {
    isInitialized: boolean;
    status: StatusType;
}

const initialState: AppInitialStateType = {
    isInitialized: false,
    status: 'idle',
}

export const appReducer = (state: AppInitialStateType = initialState, action: ActionType): AppInitialStateType => {

    switch (action.type) {
        case APP_SET_STATUS_INITIALIZED:
            return {...state, isInitialized: true, status: action.status};
        default:
            return state;
    }
}


export const setStatusApp = (status: StatusType) => ({type: APP_SET_STATUS_INITIALIZED, status } as const)

export const getStatusApp = ():ThunkType => {
    return async (dispatch) => {
        const response = await authAPI.checkAuth()
        if (response?.status === 200) {
            dispatch( setUserData(response.data.userData) )
            dispatch( setAuth(true))

        } else {
            dispatch( setAuth(false) )
            localStorage.removeItem('apikey')
        }
        dispatch(setStatusApp('success'))
    }
}

type ActionType = ReturnType<typeof setStatusApp>

type ThunkType = BaseThunkType<ActionType>



