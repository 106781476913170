import React, {FC} from "react";
import styled from "styled-components";
import {ButtonForZooming} from "../buttons";

type ButtonGroupForZoomingPropsType = {
    setZoomValue: (isNextZoomValue: boolean) => void
}

export const ButtonGroupForZooming: FC<ButtonGroupForZoomingPropsType> = (props) => {
const { setZoomValue } = props;

    const handleClickPlus = (): void => {
      setZoomValue(true)
    }

    const handleClickMinus = (): void => setZoomValue(false)

    return (
        <Container className="ButtonGroupForZooming">
            <Line/>
            <ButtonForZooming view='plus' location='top' onClick={handleClickPlus} />
            <ButtonForZooming view='minus' location='bottom' onClick={handleClickMinus} />
        </Container>

    )
}

const Container = styled.div`
  width: 40px;
  height: 77px;
  position: relative;
`
const Line = styled.span`
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 30px;
  height: 1px;
  background-color: #E6E6E6;
`
