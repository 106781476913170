import styles from "../BlogBlock.module.scss";
import { url } from "../../../../Api/API";
import cn from "classnames";
import React, { FC } from "react";
import { SmallPostProps } from "./SmallPost.props";
import { format, isValid } from "date-fns";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export const SmallPost: FC<SmallPostProps> = (props) => {
    const {
        post: {image, publicationAt, title, bodyShort, slug},
        className,
        ...restProps
    } = props;

    return (
        <div className={cn(styles.smallPost, className)} {...restProps}>
            <div>
                <div className={styles.date}>
                    {publicationAt && isValid(new Date(publicationAt)) && format(new Date(publicationAt), 'MMMM dd, yyyy')}
                </div>
                <Link to={'/blog/' + slug}>
                    <h3 className={styles.title}>
                        {title}
                    </h3>
                </Link>
                <div className={styles.contentBox}>
                    <div className={cn(styles.content, styles.contentSmallPost)}>
                        {parse(bodyShort)}
                    </div>
                    <Link className={styles.link} to={'/blog/' + slug}>read more</Link>
                </div>
            </div>
            <div className={styles.smallImage}>
                <Link className={styles.linkPhoto} to={'/blog/' + slug}>
                    <img className={cn(styles.smallImg)} src={url + image} alt=""/>
                </Link>
            </div>
        </div>
    )
}