import { CreateBrandItemFormData } from "../types";
import { addressAPI } from "../../../Api/addressApi";
import { uploadAPI } from "../../../Api/uploadApi";
import { brandItemAPI } from "../../../Api/brandItemApi";
import { addError, addSuccess } from "../../messageReducer";
import { AppDispatch } from "../../store";
import { phoneApi } from "../../../Api/phoneApi";
import { AxiosError } from "axios";
import { actions } from "../authReduser";

export const createUserBrandItem = (payload: CreateBrandItemFormData) => {
    return async (dispatch: AppDispatch) => {
        const {
            address,
            fileListLogo,
            fileListAdvertising,
            fileListMainImg,
            phone,
            link,
            latitude: latitudeString,
            longitude: longitudeString,
            ...lastParams
        } = payload;
        let logoFileId: number
        let mainFilesId: number[] = []
        let advertisingFilesId: number[] = []
        try {
            //создание address
            const addressId = await addressAPI.addAddress({
                country: address.country,
                state: address.state ? Number(address.state) : null,
                cityName: address.city,
                street: address?.street,
                apartment: address?.apartment,
                building: address?.building,
            }).then(res => res.data.id)
            //создание phone
            const phoneId = await phoneApi.create({number: phone || ''}).then(res => res.data.id)
            //создание logo
            const logoResponse = await uploadAPI.uploadFileNew({
                path: 'brand_item',
                file: fileListLogo[0].originFileObj
            })
            logoFileId = logoResponse.data.id

            //создание mainImages
            if ( fileListMainImg && fileListMainImg.length > 0 ) {
                for (let i = 0; i < fileListMainImg.length; i++) {
                    const res = await uploadAPI.uploadFileNew({
                        path: 'brand_item',
                        file: fileListMainImg[i].originFileObj
                    })
                    mainFilesId.push(res.data.id)
                }
            }
            //создание advertisingImages
            if ( fileListAdvertising && fileListAdvertising.length > 0 ) {
                for (let i = 0; i < fileListAdvertising.length; i++) {
                    const res = await uploadAPI.uploadFileNew({
                        path: 'brand_item',
                        file: fileListAdvertising[i].originFileObj
                    })
                    advertisingFilesId.push(res.data.id)
                }
            }

            const createdBrandItem = await brandItemAPI.createUserBrandItem({
                ...lastParams,
                address: addressId,
                link: link && (!/^https?:\/\//i.test(link)) ? `https://${link}` : link,
                phoneNumbers: [phoneId],
                logoFile: logoFileId,
                additionalImageFiles: advertisingFilesId,
                imageFiles: mainFilesId,
                latitude: parseFloat(latitudeString),
                longitude: parseFloat(longitudeString),
            })
            dispatch(addSuccess('Object created'))
            dispatch(actions.setStatus('success'))
        } catch (e: unknown) {
            if ( e instanceof AxiosError ) {
                dispatch(addError(e.response?.data.detail))
                dispatch(actions.setStatus('failed'))
            }
        }
    }
}