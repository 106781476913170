import React, { FC } from "react";
import { MobileNavMenuProps } from "./MobileNavMenu.props";
import styles from "./MobileNavMenu.module.scss";
import { ButtonCloseMenu, Logo } from "../../../newComponent";
import { NavMenu } from "../NavMenu/NavMenu";
import cn from "classnames";

export const MobileNavMenu: FC<MobileNavMenuProps> = ({closeMenu, className, ...restProps}) => {
    return (
        <div className={cn(styles['mobile-menu'], className)} {...restProps}>
        <ButtonCloseMenu
            onClick={closeMenu}
            className={styles['close-btn']}/>
        <Logo navigateTo='/'
              onClick={closeMenu}
              className={styles.logo}/>
        <NavMenu direction='vertical'
                 onClickItem={closeMenu}/>
    </div>)
}