import { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AddressType } from "../../../Redux/brendObjectReducer";
import { DataForShareLinkType } from "../../../Components/MapPage/MapPage";
import {
    BrandItemType,
    BrendItemShortType,
    InfoWindowDataType
} from "../../../Redux/brandItemReducer";
import Spin from "antd/lib/spin";
import {
    ReactComponent as ShareIcon
} from "../../../Elements/testComponents/ClientCard/iconComponents/figma_share.svg";
import {
    ReactComponent as ShowToMap
} from "../../../Elements/testComponents/ClientCard/iconComponents/i_nofill_f 2.svg";
import {
    ReactComponent as CreateRouteIcon
} from "../../../Elements/testComponents/ClientCard/iconComponents/to_navigator_f.svg";
import { useNavigate } from "react-router-dom";

type ProductInfoPropsType = {
    createRoute: (start: google.maps.LatLngLiteral, stop: google.maps.LatLngLiteral) => void
    openWindowForShareLink: (dataDorShareLink: DataForShareLinkType) => void
    addToFavorites: () => void
    brendObject?: BrandItemType | BrendItemShortType
    infoWindowData?: InfoWindowDataType
    myCoordinates: google.maps.LatLngLiteral
}

type DataType = {
    id: number
    address?: AddressType
    logoFileName: string
    category: string
    slug: string
}

export const ProductInfo: FC<ProductInfoPropsType> = (props) => {
    const navigate = useNavigate()
    console.log('ProductInfo', props)
    console.log('fullDataProdInfo', props.infoWindowData)

    const handleGetData = useCallback(() => {
        const newData: DataType = {
            id: props.infoWindowData ? props.infoWindowData.id : props.brendObject && props.brendObject.id ? props.brendObject.id : 0,
            address: props.infoWindowData ?
                props.infoWindowData.address !== null ? props.infoWindowData.address : undefined
                : undefined,
            logoFileName: props.infoWindowData ? props.infoWindowData.logoFileName : '',
            category: props.infoWindowData ? props.infoWindowData.category.title : 'other',
            slug: props.infoWindowData ? props.infoWindowData.slug : props.brendObject && props.brendObject.slug ? props.brendObject.slug : 'NoSlug',
        }
        return newData
    }, [props.brendObject, props.infoWindowData])

    const [data, setData] = useState<DataType>(handleGetData())

    useEffect(() => {
        setData(handleGetData())
    }, [props.infoWindowData, handleGetData]);

    const handleOnClickShareSocialNetwork = (): void => {
        if ( props.brendObject && props.brendObject.id ) {
            const objectData = {
                address: data.address as AddressType,
                objectId: data.id
            }
            console.log('Data for share', objectData)
            props.openWindowForShareLink(objectData)
        }
    }

    const handleOnClickCreateRoute = (): void => {
        data.address && props.createRoute(props.myCoordinates, {
            lat: parseFloat(data.address.latitude),
            lng: parseFloat(data.address.longitude)
        })
    }

    return (
        <Container className="ProductInfo">
            <TopInner>
                <Title>
                    {props.brendObject && props.brendObject.title}
                </Title>
                <CategoryName>{props.infoWindowData?.category.title}</CategoryName>
            </TopInner>
            <MiddleContainer>
                <LocationContainer>
                    {data.address ?
                        <LocationItemText>
                            {/* {data.address && data.address.data} */}
                            {data.address && data.address.country && data.address.country.title + ', '}
                            {data.address && data.address.building ? data.address.building + ' ' : ''}
                            {data.address && data.address.street ? data.address.street + ', ' : ''}
                            {/*{data.address && data.address.city ? data.address.city.title + '.' : ''}*/}
                            {data.address && data.address.cityName ? data.address.cityName + '.' : ''}
                        </LocationItemText>
                        :
                        <Spin size="large"/>
                    }
                    {data.address &&
                        <LocationItemText>
                            {data.address && data.address.state ? data.address.state.title + ', ' : null}
                            {data.address && data.address.country && data.address.country.title}
                        </LocationItemText>
                    }
                </LocationContainer>
            </MiddleContainer>
            <BottomContainer>
                <Button onClick={handleOnClickShareSocialNetwork}><ShareIcon/></Button>
                <Button
                    onClick={() => 
                        // navigate('/catalog/' + data.id)
                        navigate('/catalog/'+data.category.toLocaleLowerCase().replaceAll(' ', '_') + '/' + data.slug)
                    }><ShowToMap/></Button>
                <Button onClick={handleOnClickCreateRoute}><CreateRouteIcon/></Button>
            </BottomContainer>
        </Container>
    )

}

const Container = styled.div`
  max-width: 245px;
  //min-width: 220px;
`

const TopInner = styled.div`
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  margin-bottom: 10px;
`

const Title = styled.h6`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  overflow: hidden;
`

const CategoryName = styled.div`
font-size: 12px;
  color: #b8b9d0;
`

const MiddleContainer = styled.div`
  margin-bottom: 25px;
  
`

const LocationContainer = styled.div`
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #666666;
`

const LocationItemText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  margin-bottom: 8px;

  @media (max-width: 810px) {
    font-size: 9px;
    line-height: 10px;
  }
`

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 2px 5px;
  border-radius: 3px;
  //border: 1px solid #9ec3f9;
  text-align: center;
`

const Button = styled.button`
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #FFFFFF;
  margin-right: 10px;
  background-color: #9ec3f9;
  padding: 3px 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #7395C7;
  }

  svg {
    width: 25px;
    height: 25px;
  }


  &:nth-child(3) {
    margin-right: 0;
  }
`


