import { instance } from "./API";
import { PhoneType } from "../Redux/brandItemReducer";

type RequestPhoneType = Omit<PhoneType, 'id'>

export const phoneApi = {
    create({number}: RequestPhoneType) {
        return instance.post<PhoneType>('/api/phone_numbers', {number})
    },
    delete({id}: Omit<PhoneType, 'number'>) {
        return instance.delete('/api/phone_numbers/' + id)
    },
    update({id, number}: PhoneType) {
        return instance.patch<PhoneType>('/api/phone_numbers/' + id, {number})
    }
}