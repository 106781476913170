import { UpdateBrandItemFormData } from "../types";
import { addressAPI } from "../../../Api/addressApi";
import { uploadAPI } from "../../../Api/uploadApi";
import { brandItemAPI } from "../../../Api/brandItemApi";
import { addError, addSuccess } from "../../messageReducer";
import { AppDispatch } from "../../store";
import { phoneApi } from "../../../Api/phoneApi";
import { AxiosError } from "axios";

export const updateUserBrandItem = (payload: UpdateBrandItemFormData) => {
    return async (dispatch: AppDispatch) => {
        const {
            address,
            addressId,
            phone,
            phoneId,
            link,
            fileListLogo,
            fileListAdvertising,
            fileListMainImg,
            latitude: latitudeString,
            longitude: longitudeString,
            ...lastParams
        } = payload;
        let logoFileId: number
        let mainFilesId: number[] = []
        let advertisingFilesId: number[] = []
        let updatedPhoneId: number | null = null
        try {

            const addressIdResponse = await addressAPI.updateAddress({
                id: Number(addressId),
                country: Number(address.country),
                state: address.state ? Number(address.state) : null,
                cityName: address.city,
                street: address?.street,
                apartment: address?.apartment,
                building: address?.building,
            }).then(res => res.data.id)

            if(phone){
                if ( phoneId ) {
                    updatedPhoneId = await phoneApi.update({id: phoneId, number: phone})
                        .then(res => res.data.id)
                } else {
                    updatedPhoneId = await phoneApi.create({number: phone})
                        .then(res => res.data.id)
                }
            }


            if ( fileListLogo[0]?.originFileObj ) {
                const logoResponse = await uploadAPI.uploadFileNew({
                    path: 'brand_item',
                    file: fileListLogo[0].originFileObj
                })
                logoFileId = logoResponse.data.id
            } else {
                logoFileId = parseInt(fileListLogo[0].uid)
            }


            if ( fileListMainImg && fileListMainImg.length > 0 ) {
                for (let i = 0; i < fileListMainImg.length; i++) {
                    if ( fileListMainImg[i]?.originFileObj ) {
                        const res = await uploadAPI.uploadFileNew({
                            path: 'brand_item',
                            file: fileListMainImg[i].originFileObj
                        })
                        mainFilesId.push(res.data.id)
                    } else {
                        mainFilesId.push(parseInt(fileListMainImg[i].uid))
                    }
                }
            }

            if ( fileListAdvertising && fileListAdvertising.length > 0 ) {
                for (let i = 0; i < fileListAdvertising.length; i++) {
                    if ( fileListAdvertising[i]?.originFileObj ) {
                        const res = await uploadAPI.uploadFileNew({
                            path: 'brand_item',
                            file: fileListAdvertising[i].originFileObj
                        })
                        advertisingFilesId.push(res.data.id)
                    } else {
                        advertisingFilesId.push(parseInt(fileListAdvertising[i].uid))
                    }
                }
            }

            const updatedBrandItem = await brandItemAPI.updateUserBrandItem({
                ...lastParams,
                category: Number(lastParams.category),
                address: addressIdResponse,
                phoneNumbers: [updatedPhoneId],
                link: link && (!/^https?:\/\//i.test(link)) ? `https://${link}` : link,
                logoFile: logoFileId,
                additionalImageFiles: advertisingFilesId,
                imageFiles: mainFilesId,
                latitude: parseFloat(latitudeString),
                longitude: parseFloat(longitudeString),
            })
            dispatch(addSuccess('Object updated'))
        } catch (e: unknown) {
            if ( e instanceof AxiosError ) {
                dispatch(addError(e.response?.data.detail))
            }
        }
    }
}