import { FC, PropsWithChildren } from "react";
import { SectionTitleProps } from "./SectionTitle.props";
import styles from './SectionTitle.module.scss'
import cn from "classnames";
import { HTag } from "../common/HTag/HTag";

export const SectionTitle: FC<PropsWithChildren<SectionTitleProps>> = (props) => {
    const {children, className, tag, ...restProps} = props;


    return(<div className={cn( styles.inner, className)} {...restProps}>
        <HTag tag={tag} className={styles.title}>
            {children}
        </HTag>
        <div className={styles.border}/>
    </div>)
}