import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { EditableDivProps } from "./EditableDiv.props";
import styles from './EditableDiv.module.scss'
import cn from "classnames";

export const EditableDiv: FC<EditableDivProps> = (props) => {
    const {
        className,
        value,
        valueIfEmpty,
        isEditable = true,
        inputProps,
        type = 'text',
        isLoading = false,
        onSave,
        name,
        ...restProps
    } = props;

    const [edit, setEdit] = useState<boolean>(false)

    const handleOnClick = () => {
        if (edit && name){
            onSave?.(name)
        }
        !isLoading && setEdit(!edit)
    }


    return (
        <div
            className={cn(styles.item, {
                [styles.editable]: edit,
                [styles.loading]: isLoading,
            }, className)}
            {...restProps}
        >
            {
                isEditable && edit ? <input
                        className={cn(styles.input, inputProps?.className)}
                        type={type}
                        autoFocus
                        value={value}
                        {...inputProps}
                    />
                    : <span>{value || valueIfEmpty}</span>
            }
            {
                isEditable && <button
                    className={styles['btn-edit']}
                    onClick={handleOnClick}>{edit ? 'Save' : 'Change'}</button>
            }
        </div>
    )
}