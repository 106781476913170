import React, {FC} from "react";

import styled from "styled-components";

import {ButtonDefaultPropsType} from "./types";

export const ButtonDefault: FC<ButtonDefaultPropsType> = (props) => {
    const {title, onClick} = props;

    const handleOnClick = () => {
        onClick && onClick()
    }

    return (
        <Button onClick={handleOnClick}>
            {title}
        </Button>
    )
}

const Button = styled.button`
  padding: 5px 20px;
  border: none;  
  outline: none;
  background-color: #ffffff;
  border-radius: 10px;  
  color: #797DF9;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  transition: all .3s;
  cursor: pointer;
  &:hover{
    background-color: #ECECEC;
  }  
`
