import React, { useCallback, useEffect, useState } from 'react'
import styles from './GoogleMapStyles.module.scss';
import {
    DirectionsRenderer,
    GoogleMap,
    InfoWindow,
    Marker,
    OverlayView,
    StreetViewPanorama,
    useJsApiLoader
} from '@react-google-maps/api';
import { url } from '../../../Api/API';
import { ProductInfo } from '../../../newComponent/popups/ProductInfo/ProductInfo';
import {
    RouteComponent
} from '../../../newComponent/popups/RouteComponent/RouteComponent';
import { GoogleMapComponentPropsType } from './GoogleMapContainer';
import useDebounce from "../../../hooks/useDebounce";
import { Modal, Spin } from 'antd';
import { mapTypeStyle } from './mapStyles';
import { Backdrop } from '@mui/material';
import { ScaleLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import {
    selectClustersList,
    selectInfoWindowData
} from '../../../Redux/selectors/brandItemSelector';
import {
    BrandItemType,
    BrendItemShortType,
    ClusterType,
    getBrendItems,
    getItemData
} from '../../../Redux/brandItemReducer';
import { MarkerInfo } from "./MarkerInfo/MarkerInfo";
import { useWindowSizeOld as useWindowSize } from "../../../hooks/useWindowWidthOld";
import { useAppDispatch } from "../../../Redux/store";

// const containerStyle = {
//     width: '100%',
//     // height: `calc( - 50px)`,
// }

export type MarkerType = {
    onLoad?: (marker: google.maps.Marker) => void
    position: google.maps.LatLng | google.maps.LatLngLiteral
}

export type CoordinatesType = {
    lngMin: number,
    lngMax: number,
    latMin: number,
    latMax: number,
}

const GoogleMapComponent: React.FC<GoogleMapComponentPropsType> = (props) => {
    const [width, height] = useWindowSize()
    const containerStyle = {
        width: '100%',
        height: `calc(${height}px - 50px)`,
    }

    const [showMarkerInfo, setShowMarkerInfo] =
        useState<{ isShow: boolean, coordinates: { lat: number, lng: number }, value: string }>({
            isShow: false,
            coordinates: { lat: 0, lng: 0 },
            value: ''
        })
    const [map, setMap] = useState<google.maps.Map>()
    const [markers, setMarkers] = useState<BrandItemType[]>([])
    const [isShowingInfoWindow, setIsShowingInfoWindow] = useState<boolean>(false)
    const [activeBrendObject, setActiveBrendObject] = useState<BrendItemShortType | BrandItemType>()
    const [routes, setRoutes] = useState<google.maps.DirectionsResult>()
    const [newRoute, setNewRoute] = useState<google.maps.DirectionsResult>()
    const [zoom, setZoom] = useState<number>(props.zoom ? props.zoom : 14)
    const [center, setCenter] = useState<google.maps.LatLngLiteral>(props.center)
    const [lastZoom, setLastZoom] = useState<number | undefined>(14)
    const [lastCenter, setLastCenter] = useState<google.maps.LatLngLiteral>(props.center)
    const [newBounds, setNewBounds] = useState<CoordinatesType>({
        lngMin: 0,
        lngMax: 0,
        latMin: 0,
        latMax: 0,
    })
    const [lastBounds, setLastBounds] = useState<CoordinatesType>()
    const boundsValueForRequest = useDebounce(newBounds, 800)
    const zoomValueForRequest = useDebounce(zoom, 100)
    const [isRerout, setIsRerout] = useState<boolean>(false)

    const [isLoad, setIsLoad] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const clustersList = useSelector(selectClustersList)
    const infoWindowData = useSelector(selectInfoWindowData)
    // infoWindowData && 
    // const isDataLoad = dispatch(selectIsInfoWindowDataLoad)

    const [selectedMarker, setSelectedMarker] = useState<any>()
    const [paths, setPaths] = useState<any>()
    const [isPolygonShow, setIsPolygonShow] = useState<boolean>(false)
    const [lastCategories, setLastCategories] = useState<number[]>(props.selectedCategory)

    const handleGetBrendItems = useCallback((data?: any) => {
        console.group('handleGetBrendItems for ', data)
        if (data === 'selectedCategory') console.log('props.selectedCategory ', props.selectedCategory)
        if (data === 'selectedCategory') console.log('lastCategories ', lastCategories)

        let isDispatch = true

        if (data && data === 'selectedCategory' && lastCategories) {
            if (lastCategories.length === props.selectedCategory.length) {
                isDispatch = false
            } else {
                // isDispatch = true
            }
        }

        console.log('isDispatch: ', isDispatch)
        isDispatch && dispatch(getBrendItems({
            ...boundsValueForRequest,
            zoom: zoom,
            category: Array.isArray(props.selectedCategory) ? props.selectedCategory : [props.selectedCategory]
        }))

        console.groupEnd()

    }, [boundsValueForRequest, dispatch, props.selectedCategory, zoom, lastCategories])



    useEffect(() => {
        setIsLoad(false)
    }, [markers]);

    useEffect(() => {
        setIsLoad(false)
    }, [clustersList]);

    useEffect(() => {
        dispatch(getBrendItems({
            ...boundsValueForRequest,
            zoom: zoom,
            category: Array.isArray(props.selectedCategory) ? props.selectedCategory : [props.selectedCategory]
        }))

        console.groupEnd()

    }, [boundsValueForRequest, dispatch, props.selectedCategory, zoom, lastCategories])

    useEffect(() => {
        handleGetBrendItems('selectedCategory')
        setLastCategories(props.selectedCategory)
    }, [props.selectedCategory, handleGetBrendItems]);

    useEffect(() => {
        if (
            lastBounds?.latMax !== boundsValueForRequest.latMax ||
            lastBounds?.latMin !== boundsValueForRequest.latMin ||
            lastBounds?.lngMin !== boundsValueForRequest.lngMin ||
            lastBounds?.lngMax !== boundsValueForRequest.lngMax
        ) {
            if (boundsValueForRequest.latMax !== 0) {
                dispatch(getBrendItems({
                    ...boundsValueForRequest,
                    zoom: zoom,
                    category: Array.isArray(props.selectedCategory) ? props.selectedCategory : [props.selectedCategory]
                }))
            }
        }
        setLastBounds(boundsValueForRequest)
    }, [boundsValueForRequest, lastBounds, handleGetBrendItems])

    const handleCangeBrendObject = useCallback((data?: any) => {
        if (props.openObjectId) {
            const target = props.brendItemList ? props.brendItemList.filter((item: any) => item.id === props.openObjectId) : []
            if (target[0]) {
                setActiveBrendObject(target[0])
                setIsShowingInfoWindow(true)
            }
        }
    }, [props.openObjectId, props.brendItemList]);

    useEffect(() => {
        if (props.brendItemList && props.brendItemList.length > 0) {
            setMarkers(props.brendItemList)
        }
        handleCangeBrendObject()
    }, [props.brendItemList, props.clustersList, handleCangeBrendObject]);


    useEffect(() => {
        if (!routes) {
            setRoutes(props.routes)
        } else {
            if (
                // @ts-ignore
                routes.request.destination.location.lat() !== props.routes.request.destination.location.lat()
                // @ts-ignore
                && routes.request.destination.location.lng() !== props.routes.request.destination.location.lng()
            ) {
                setIsRerout(true)
                setNewRoute(props.routes)
            } else if (props.routes?.routes[0]?.legs[0]?.duration?.text === routes?.routes[0]?.legs[0]?.duration?.text) {
                setRoutes(props.routes)
            } else {
                setRoutes(props.routes)
            }
        }
    }, [props.routes]);

    useEffect(() => {
        map && props.mapType && map.setMapTypeId(props.mapType)
    }, [props.mapType, map]);

    useEffect(() => {
        setCenter(props.center)
    }, [props.center]);

    useEffect(() => {
        props.zoom && setZoom(props.zoom)
    }, [props.zoom]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAfnLiXnH7ZdYNuuD6hLBAZHtiXEklnPio"
    })


    const onLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map)
    }, [])

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(undefined)
    }, [])

    const onMarkerClick_V_4 = (brandItem: BrendItemShortType, e?: google.maps.MapMouseEvent) => {
        brandItem.id && dispatch(getItemData(brandItem.id))
        setActiveBrendObject(brandItem)

        setIsShowingInfoWindow(true)
    }

    const onInfoWindowClose = () => {
        setIsShowingInfoWindow(false)
        setActiveBrendObject(undefined)
        props.openObjectId && props.setOpenObjectId(undefined)
    }

    const onCenterChange = (newCenter: google.maps.LatLngLiteral) => {
        setCenter(newCenter)
    }

    const onBoundsChanged = () => {
        const bounds = map ? map.getBounds() : new window.google.maps.LatLngBounds(center)
        setNewBounds({
            lngMin: bounds ? bounds.getSouthWest().lng() : 0,
            lngMax: bounds ? bounds.getNorthEast().lng() : 0,
            latMin: bounds ? bounds.getSouthWest().lat() : 0,
            latMax: bounds ? bounds.getNorthEast().lat() : 0,
        })
    }

    const onCreateRoute = (start: google.maps.LatLngLiteral, stop: google.maps.LatLngLiteral, routeTargetName?: string | undefined) => {
        props.calculateRoute(start, stop)
        setIsShowingInfoWindow(false)
    }

    const onZoomChanged = () => {
        map && props.setZoom(map.getZoom())
    }

    const onDirectionsChanged = () => {
        if (map) {
            const lastCenter = map.getCenter()
            lastCenter && setLastCenter({
                lat: lastCenter.lat(),
                lng: lastCenter.lng()
            })
            setLastZoom(map.getZoom())
        }
    }

    let downTime = 0

    const onMouseDown = (e: google.maps.MapMouseEvent) => {
        // @ts-ignore
        downTime = e.domEvent.timeStamp
    }

    const onMouseUp = (e: google.maps.MapMouseEvent) => {
        // @ts-ignore
        const res = e.domEvent.timeStamp - downTime
        if (res > 400 && downTime !== 0) {
            // @ts-ignore
            props.onContextMenu(e.domEvent, e.latLng);
        }
        downTime = 0
    }

    const onStreetViewPanoramaVisibleChanged = () => {
        props.onHideMenu()
    }

    const onClusterClick = (cluster: ClusterType) => {
        setZoom(zoom + 3)
        setCenter({
            lat: cluster.latitudeCenter,
            lng: cluster.longitudeCenter
        })

    }

    const closeMarkerInfoWindow = () => setShowMarkerInfo({
        isShow: false,
        value: '',
        coordinates: {
            lat: 0,
            lng: 0
        }
    })

    const getPixelPositionOffset = (width: number, height: number) => ({
        x: (-width / 2),
        y: (-height / 2 - 65),
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoomValueForRequest}
            onLoad={onLoad}
            onBoundsChanged={onBoundsChanged}
            onUnmount={onUnmount}
            onZoomChanged={onZoomChanged}
            mapContainerClassName="mapContainerClassName"
            onMouseUp={onMouseUp}
            onMouseDown={onMouseDown}

            options={{
                zoomControl: false,
                keyboardShortcuts: false,
                mapTypeControl: false,
                styles: mapTypeStyle,
                gestureHandling: 'greedy',
                minZoom: 2,
                fullscreenControl: false,
                restriction: {
                    latLngBounds: {
                        north: 85,
                        south: -50,
                        east: 180,
                        west: -180,
                    }
                },
            }}
        >
            <>
                {isLoad &&
                    <Backdrop
                        style={{
                            backgroundColor: 'rgba(250, 250, 250, 0.76)',
                            color: '#000',
                            zIndex: 999
                        }}
                        open={true}
                    >
                        <ScaleLoader
                            color={'rgb(121, 125, 249)'}
                            loading={true}
                            height={100}
                            width={20}
                        />
                    </Backdrop>
                }

                <Marker
                    onClick={() => {
                    }}
                    label={{ text: 'You\'re here' }}
                    position={props.myCoords}
                />

                {clustersList.map(cluster => {
                    console.log('!!!???!!!', cluster.brandItems !== null ? cluster.brandItems.length : cluster.brandItemsCount)
                    if (cluster.brandItemsCount < 5) {
                        const markers: any[] = []
                        const arr = cluster.brandItems != null ? cluster.brandItems : []
                        arr.map((brandItems: BrendItemShortType) => {
                            const pastUrl = brandItems.markerFileName ? brandItems.markerFileName : '/ATM_marker.svg'
                            markers.push(
                                <Marker
                                    key={brandItems.id + 'googleMarker'}
                                    onClick={(e: google.maps.MapMouseEvent) => {
                                        onMarkerClick_V_4(brandItems, e)
                                    }}
                                    onMouseOut={closeMarkerInfoWindow}
                                    onMouseOver={(e) => {
                                        if (e.latLng) {
                                            setShowMarkerInfo({
                                                isShow: true,
                                                value: brandItems.title,
                                                coordinates: {
                                                    lat: e.latLng.lat(),
                                                    lng: e.latLng.lng()
                                                }
                                            })
                                        }

                                    }}
                                    position={{
                                        lat: brandItems.latitude,
                                        lng: brandItems.longitude,
                                    }}
                                    icon={{
                                        url: url + pastUrl,
                                        size: new google.maps.Size(45, 50)
                                        // scaledSize: new google.maps.Size(45, 50)
                                    }}
                                />
                            )
                        })
                        return markers
                    } else {
                        const clusterLength = cluster.brandItemsCount.toString().length
                        const scaledSize = clusterLength === 1 ? 30 :
                            clusterLength === 2 ? 35 : clusterLength === 3 ? 43 : 47
                        const fontSize = clusterLength === 1 ? '18px' :
                            clusterLength === 2 ? '17px' : '16px'
                        return (
                                <Marker
                                    onClick={() => {
                                        onClusterClick(cluster)
                                    }}
                                    label={{
                                        // text: cluster.brandItemsCount < 10 ? cluster.brandItemsCount.toString() : '>9',
                                        text: cluster.brandItemsCount.toString(),
                                        fontSize: fontSize,
                                        fontFamily: "‰›monospace, monospace",
                                        // fontWeight: 'bold',
                                        color: '#0878AC',
                                        className: 'iconLabel'
                                        
                                    }}
                                    position={{
                                        lat: cluster.latitudeCenter,
                                        lng: cluster.longitudeCenter,
                                    }}
                                    // icon='none'
                                    icon={{
                                        url: url + 'big_blue+fill.svg',
                                        scaledSize: new google.maps.Size(scaledSize, scaledSize)
                                    }}
                                />
                        )
                    }

                })}

                {
                    showMarkerInfo.isShow && !isShowingInfoWindow &&
                    <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={getPixelPositionOffset}
                        position={{
                            lat: showMarkerInfo.coordinates.lat,
                            lng: showMarkerInfo.coordinates.lng
                        }} >
                        <MarkerInfo brandObjectName={showMarkerInfo.value} />
                    </OverlayView>

                }

                {isShowingInfoWindow && activeBrendObject &&
                    <InfoWindow
                        options={{ minWidth: 240 }}
                        position={{
                            lat: activeBrendObject.latitude,
                            lng: activeBrendObject.longitude
                        }}
                        onCloseClick={onInfoWindowClose}
                    >
                        <ProductInfo
                            addToFavorites={() => { }}
                            createRoute={onCreateRoute}
                            openWindowForShareLink={props.openWindowForShareLink}
                            infoWindowData={infoWindowData}
                            brendObject={activeBrendObject}
                            myCoordinates={props.myCoords}
                        />
                    </InfoWindow>
                }
                {routes && !isRerout &&
                    <DirectionsRenderer onDirectionsChanged={onDirectionsChanged}
                        directions={routes} />}

                <RouteComponent
                    directions={routes}
                    setCenter={(data) => {
                        onCenterChange(data)
                    }}
                    setRoutes={setRoutes}
                    setZoom={props.setZoom as () => void}
                    changeTravelMode={props.changeTravelMode}
                    onHideMenu={props.onHideMenu}
                    lastZoom={lastZoom as number}
                    lastCenter={lastCenter}
                    isRerout={isRerout}
                    setIsNavigateMode={props.setIsNavigateMode}
                    isNavigateMode={props.isNavigateMode}
                />

                <Modal
                    visible={isRerout}
                    onOk={() => {
                        setRoutes(newRoute)
                        setIsRerout(false)
                    }}
                    onCancel={() => {
                        setIsRerout(false)
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <p>Do you want to change the route?</p>
                </Modal>

                <StreetViewPanorama
                    onVisibleChanged={onStreetViewPanoramaVisibleChanged}
                />

            </>
        </GoogleMap>
    ) : (
        <div
            style={{
                marginTop: 150,
                marginLeft: '48%'
            }}
        >
            <Spin size='large' />
        </div>
    )
}

export default GoogleMapComponent
