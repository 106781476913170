import { checkPermissions } from "../../routers/checkPermissions";
import {
    userNavMenu,
    adminNavMenu,
    defaultMenu,
    UserNavMenuItem
} from '../Header/menu.constants'
export const getUserMenu = (userRole: any, isAuth: boolean): UserNavMenuItem[] => {

    if(!isAuth) {
        return defaultMenu
    }

    if (checkPermissions(userRole, 'admin')){
        return [...adminNavMenu, ...userNavMenu ];
    } else {
        return userNavMenu;
    }
}