import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { useAutocomplete } from '@mui/material'
import { Input } from '../Input/Input'
import { GetBrendObjectByFilrterFieldsType } from '../../../Api/brendObjectApi'
import { useAppDispatch } from '../../../Redux/store'
import { findBrandItemsByQuery } from '../../../Redux/brandItemReducer'
import { useSelector } from 'react-redux'
import { selectBrandItemsByQuery } from '../../../Redux/selectors/brandItemSelector'
import useDebounce from '../../../hooks/useDebounce'

export type AutocompleteOptionsType = {
    title: string
    value: number
}

type AutocompletePropsType = {
    isActive?: boolean;
    appearance?: 'grey-bg' | 'transparent' | 'default';
    type?: "text" | 'password' | 'number'
    isFocus: boolean
    setIsFocus: (isFocus: boolean) => void

    placeholder: string
    value?: string
    handleChangeValue: (value: string, name: GetBrendObjectByFilrterFieldsType) => void
    onBlur: () => void
    // onFocus: React.FocusEventHandler<HTMLInputElement>
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onSubmit: (data: any) => void
}

const Autocomplete: React.FC<AutocompletePropsType> = (props) => {
    const dispatch = useAppDispatch()
    const brandItemsByQuery = useSelector(selectBrandItemsByQuery)
    const [options, setOptions] = useState<AutocompleteOptionsType[]>([])
    const boundsValue = useDebounce(props.value, 400)
    const [isSelect, setIsSelect] = useState<boolean>(false)

    const {
        getListboxProps,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: brandItemsByQuery,
        getOptionLabel: (option) => option.title,
    })

    useEffect(() => {
        setOptions(brandItemsByQuery)
    }, [brandItemsByQuery])

    useEffect(() => {
        if (!isSelect && boundsValue.length >= 3 ) {
            dispatch(findBrandItemsByQuery(boundsValue))
            setIsSelect(false)
        } else {
            setOptions([])
            setIsSelect(false)
        }
    }, [boundsValue])

    const onChange = (value: string, isSelected?: boolean) => {
        props.handleChangeValue(value, 'value')
    }

    const onOptionClick = (value: string) => {
        setOptions([])
        setIsSelect(true)
        onChange(value, true)
        props.onSubmit({
            value: value
        })
    }

    return (
        <div style={{ maxHeight: 30 }}>
            <div>
                <Input
                    placeholder={props.placeholder}
                    value={props.value || ''}
                    isActive={props.isFocus}
                    onBlur={(e) => {
                        props.setIsFocus(false)
                    }}
                    onFocus={() => {
                        props.setIsFocus(true);
                    }}
                    onChange={(e) => onChange(e.target.value)}
                />

            </div>
            { props.isFocus && options && options.length > 0 ? (
                <Listbox
                    {...getListboxProps()}
                >
                    {options && options.map((option, index) => (
                        <ListItem
                            onClick={() => onOptionClick(option.title)}
                        >
                            {option.title}
                        </ListItem>
                    ))}
                </Listbox>
            ) : null}
        </div>
    )
}

export default Autocomplete

const ListItem = styled('li')(({ theme }) => ({
    padding: 10,

    '&:hover': {
        background: "gray",
    },
}))

const Listbox = styled('ul')(({ theme }) => ({
    cursor: 'pointer',
    zIndex: 1000,
    position: 'relative',
    left: '3%',
    width: '94%',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    border: '1px solid rgba(0,0,0,.25)',
}))
